<template>
    <div class="box  is-hero-bar is-main-hero" v-if="lista != null && lista.length > 0">        
        <div>
            <div class="panel">
                <div v-for="(guia, index) in lista" :key="index">
                    <div class="panel-block is-block portal-mes" 
                        v-if="index == 0 || new Date(guia.dataDeEmissao).getMonth() != new Date(lista[index - 1].dataDeEmissao).getMonth()">
                        <nav class="level">
                            <div class="level-left">
                                <div class="level-item">
                                    <h3 class="label">
                                        {{monthNames[new Date(guia.dataDeEmissao).getMonth()]}} / {{new Date(guia.dataDeEmissao).getFullYear()}}
                                    </h3>
                                </div>
                            </div>
                            <div class="level-right" v-if="(tipo != 'Paciente')">
                                <div class="level-item">
                                    <div class="control">
                                        <b-field>
                                            <b-select
                                                placeholder="Ordenar por"
                                                size="is-small"
                                                :value="ordenarPor" 
                                                @input="ordenarGuias"
                                                expanded>
                                                <option value="emissao" checked > Data de Emissão</option>
                                                <option value="guia" > Número da Guia</option>
                                                <option value="paciente" > Nome do Paciente</option>
                                            </b-select>
                                        </b-field>
                                    </div>
                                </div>
                                <div class="level-item">
                                    <b-button size="is-small" @click="filtrar()">Filtros</b-button>
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div v-if="guia.guiaId != 0" class="panel-block columns is-multiline is-mobile">                        
                        <div class="column is-10-desktop">
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-6-desktop is-full-mobile">
                                    <small>Guia:</small>
                                    <br />
                                    <b>{{ guia.id }}</b>
                                </div>
                                <div class="column is-2-desktop is-half-mobile">
                                    <small>Data de emissão:</small>
                                    <br />
                                    <b>{{ guia.dataDeEmissao | moment("DD/MM/YYYY") }}</b>
                                </div>
                                <div class="column is-4-desktop is-half-mobile">
                                    <small>Data de retorno:</small>
                                    <br />
                                    <b>{{ guia.dataDeRetorno | moment("DD/MM/YYYY") }}</b>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6" 
                                     v-if="(tipo == 'Paciente' && guia.pacienteId != usuarioPacienteId) || (tipo!='Paciente')">
                                    <small>Paciente:</small>
                                    <br />
                                    <b>{{ guia.pacienteNome }}</b>
                                </div>
                                <div class="column is-6">
                                    <small>Médico:</small>
                                    <br />
                                    <b>{{ guia.medicoNome }}</b>
                                </div>
                            </div>
                            <div class="columns is-mobile is-multiline">
                                <div class="column is-2-desktop"
                                     :class="exame.dataDeEntrega ? 'is-full-mobile' : 'is-4-mobile'"
                                     v-for="(exame, index) in guia.exames.filter(x => !x.cancelado && (!x.exameDeFaturamento || x.materialEntregue))"
                                     v-bind:key="index">
                                    <b-tooltip :label="defineTitulo(exame)" multilined>    
                                        <b-taglist attached>
                                            <b-tag
                                                @click.native="selecionarExame(exame)"
                                                :class="defineClasses(exame)">
                                                {{ exame.exameApelido }}
                                            </b-tag>
                                            <b-tag ellipsis>
                                                {{ exame.materialNome }}
                                            </b-tag>
                                        </b-taglist>
                                    </b-tooltip>
                                    <div v-show="exame.dataDeEntrega">
                                        <b-tag type="is-info">
                                            {{ exame.exameNome }}
                                        </b-tag> 
                                        <b-tag  title="Data de solicitação">
                                            {{ exame.dataDeSolicitacao | moment("DD/MM/YYYY HH:mm") }}
                                        </b-tag>                                        
                                        <b-tag v-if="exame.materialEntregue" type="is-info" title="Data de entrega">                                            
                                            {{ exame.dataDeEntrega | moment("DD/MM/YYYY HH:mm") }}

                                        </b-tag>
                                        <b-tag v-else type="is-warning" title="Data prevista">                                            
                                            {{ exame.dataDeEntrega | moment("DD/MM/YYYY HH:mm") }}

                                        </b-tag>                                        
                                        <br>
                                        <b-tag type="is-default" title="Preço" v-if="!usuario.escondePrecos">
                                            {{ exame.preco | currency  }}
                                        </b-tag>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column is-full-mobile has-text-centered">
                            <div class="columns is-multiline is-mobile">
                                <div v-if="config.naoAbreSemAutorizacao && !guia.convenioTipoParticular && !guia.autorizacaoGuia">
                                    <b-tag type="is-danger" size="is-medium">Sem autorização do convênio!</b-tag>
                                    <p>
                                        <small>
                                            <i>Por favor, entre em contato com o laboratório.</i>
                                        </small>
                                    </p>
                                </div>

                                <b-button v-else-if="guia.exames.filter(x => !x.cancelado && (!x.exameDeFaturamento || x.materialEntregue) && 
                                    ((x.assinado || (x.exameResultadoParcial && x.digitado)) && !x.naoPublicaResultadosNaInternet)).length > 0"
                                        icon-left="file-document-outline"
                                        class="column is-12"
                                        @click="visualizarPdf(guia)"
                                        label="Abrir resultados"></b-button>

                                <p v-if="tipo == 'Convenio'" class="column is-12">
                                    
                                    <b-tooltip v-if="usuario.convenioPodeIncluirGuia" label="Editar Guia" multilined>            
                                        <router-link :to="{ name: 'portalconvenioguia', params: { id: guia.id }}" class="button portal-botoes-convenio-guia">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>
                                    </b-tooltip>


                                    <b-tooltip label="Imprimir etiqueta" multilined>    
                                        <a class="button portal-botoes-convenio-guia"
                                        @click="imprimirEtiquetaAmostraSelecionada(guia)">
                                            <b-icon icon="barcode" size="is-small"></b-icon>
                                        </a>
                                    </b-tooltip>

                                    <b-tooltip label="Imprimir comprovante" multilined>    
                                        <a class="button portal-botoes-convenio-guia" 
                                        @click="imprimirComprovanteGuiaSelecionada(guia)">
                                            <b-icon icon="printer" size="is-small"></b-icon>
                                        </a>
                                    </b-tooltip>

                                    <b-tooltip v-if="guia.exames.filter(x => !x.cancelado && (!x.exameDeFaturamento || x.materialEntregue) && 
                                    ((x.assinado || (x.exameResultadoParcial && x.digitado)) && !x.naoPublicaResultadosNaInternet)).length > 0" label="Enviar por email" multilined>    
                                        <a class="button portal-botoes-convenio-guia"
                                        @click="enviarPorEmail(guia)">
                                            <b-icon icon="email" size="is-small"></b-icon>
                                        </a>
                                    </b-tooltip>
                                    <b-tooltip label="Clique para ver mais informações de cada exame" multilined>    
                                        <b-button class="portal-botoes-convenio-guia"
                                                icon-left="information"   
                                                :loading="consultandoDados"                                                                                             
                                                @click="calculoRetornoPorExame(guia)"></b-button>
                                    </b-tooltip>
                                </p>
                            </div>

                            <b-progress size="is-large"
                                        type="is-primary"
                                        :value="(guia.exames.filter(x => !x.cancelado && (!x.exameDeFaturamento || x.materialEntregue))
                                                            .filter(exame => exame.assinado || (exame.exameResultadoParcial && exame.digitado)).length / 
                                                    guia.exames.filter(x => !x.cancelado && (!x.exameDeFaturamento || x.materialEntregue)).length) * 100"
                                        show-value>
                                Exames prontos: {{guia.exames.filter(x => !x.cancelado && (!x.exameDeFaturamento || x.materialEntregue))
                                                            .filter(exame => exame.assinado || (exame.exameResultadoParcial && exame.digitado)).length}} / 
                                                {{guia.exames.filter(x => !x.cancelado && (!x.exameDeFaturamento || x.materialEntregue)).length}}
                            </b-progress>
                        </div>
                    </div>
                    <div v-else class="panel-block columns is-multiline is-mobile">                        
                        <div class="column is-10-desktop">
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-12-desktop is-full-mobile">
                                    <h2>Não foram encontrado exames para os filtros selecionados!</h2>
                                </div>

                            </div>
                        </div>             
                    </div>                    
                </div>
                <div v-if="lista != null" >
                    <hr>
                    <b-pagination
                        :total="total"
                        :current="pag"
                        :range-before="3"
                        :range-after="1"
                        :order="''"
                        :size="''"
                        :simple="false"
                        :rounded="false"
                        :per-page="'10'"
                        :icon-prev="'chevron-left'"
                        :icon-next="'chevron-right'"
                        @change="onPageChange">
                    </b-pagination>
                </div>
            </div>
        </div>
    </div>

</template>

<style>
    .retorno {
        cursor: pointer;
    }

    .guiaExame {
        cursor: pointer;
    }

    .selecionado {
        border: 2px solid #808080;
    }
</style>

<script>
    import { mapState } from 'vuex';    

    export default {
        props: {
            lista: Array,
            tipo: String,
            page: Number,
            pageCount: Number,
            usuarioPacienteId: Number,
            ordenar: String
        },
        data() {
            return {
                total: null,
                pag: null,
                exameSelecionado: [],
                monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
                    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
                ],
                guiasComDetalhes: [],
                ordenarPor: 'emissao',
                consultandoDados: false
            };
        },
        computed: {
            ...mapState([
                'config',
                'usuario'
            ])
        },
        created(){
            this.total = this.pageCount * 10;
            this.pag = this.page;
            this.ordenarPor = this.ordenar;

            if(this.lista == null || this.lista.length == 0){
                this.lista.push({dataDeEmissao: new Date(),guiaId:0})
            }
        },
        methods: {      
            onPageChange(page){
                this.pag = page
                this.$emit('pageChange', page);
            },      
            filtrar(){
                this.$emit('filtrar');
            },   
            ordenarGuias(value){
                this.ordenarPor = value;

                this.$emit('ordenar', this.ordenarPor);
            }, 
            defineClasses(exame) {
                return "guiaExame is-capitalized " +
                    (exame.visualizadoConvenio
                    ? "portal-exame-visualizado-convenio"
                    : exame.bloqueado
                        ? "portal-exame-bloqueado"
                        : (exame.assinado || (exame.exameResultadoParcial && exame.digitado))
                            ? exame.naoPublicaResultadosNaInternet
                                ? "portal-exame-nao-publica-internet"
                                : "portal-exame-disponivel"
                            : !exame.materialEntregue
                                ? exame.recoleta
                                    ? "portal-exame-recoleta"
                                    : "portal-exame-aguardando-material"
                                : "portal-exame-em-processamento") +
                    (this.exameSelecionado.filter(item => item.guiaId == exame.guiaId && item.item == exame.item).length > 0
                        ? " selecionado"
                        : "")
            },
            defineTitulo(exame) {
                const titulo = exame.visualizadoConvenio 
                    ? "Resultado visualizado/impresso"
                    : exame.bloqueado
                        ? "Exame bloqueado"
                        : (exame.assinado || (exame.exameResultadoParcial && exame.digitado))
                            ? exame.naoPublicaResultadosNaInternet 
                                ? "Resultado disponível mas não pode ser visualizado pela internet"
                                : "Resultado disponível"
                            : !exame.materialEntregue
                                ? exame.recoleta 
                                    ? "Nova amostra solicitada"
                                    : "Exame aguardando material" 
                                : "Exame em processamento";

                return exame.exameNome + ": " + titulo
            },
            selecionarExame(exame) {
                if (exame.visualizadoConvenio || exame.assinado || (exame.exameResultadoParcial && exame.digitado)) {
                    if (this.exameSelecionado == null) {
                        this.exameSelecionado = [exame]
                    } else {
                        if (this.exameSelecionado.includes(exame)) {
                            this.exameSelecionado = this.exameSelecionado.filter(function (x) { return x != exame })
                        } else {
                            this.exameSelecionado.push(exame)
                        }
                    }
                }
            },
            visualizarPdf(guia){
                if(this.exameSelecionado != null && this.exameSelecionado.length > 0)
                {
                    const itensExamesSelecionados = [];
                    this.exameSelecionado.forEach(exame => {
                        if(exame.guiaId == guia.id){
                            itensExamesSelecionados.push(exame.item);
                        }
                    });
                    const itens = '&sel=' + itensExamesSelecionados.join(',');
                    window.open('/api/Portal/Resultado?id=' + guia.id + itens);
                }
                else
                {
                    window.open('/api/Portal/Resultado?id=' + guia.id);
                }
            },
            calculoRetornoPorExame(guia) {                                
                if(guia.mostraDetalhes){
                    guia.exames.forEach(exame => {
                        exame.dataDeEntrega = null;
                    }); 
                    guia.mostraDetalhes = false;
                }else{
                    
                    this.consultandoDados = true;
                    this.$http.get('/api/portal/CalculoRetornoPorExame?guiaId=' + guia.id)
                        .then(res => {
                            guia.exames.forEach(exame => {
                                
                                if(res.data.filter(item => item.item == exame.item)[0]){
                                    exame.dataDeEntrega = new Date(res.data.filter(item => item.item == exame.item)[0].dataDeEntrega);
                                }
                                

                            }); 
                            
                            guia.mostraDetalhes=true;
                            this.consultandoDados = false;
                        });                    

                }

            },
            enviarPorEmail(guia){
                this.$buefy.dialog.prompt({
                    title: 'E-mail',
                    message: 'Enviar resultado por email',
                    inputAttrs: {
                        type: 'text',
                        placeholder: 'Email',
                        value: guia.pacienteEmail
                    }, 
                    confirmText: 'Confirmar',
                    cancelText: 'Cancelar',
                    trapFocus: true,
                    onConfirm: (res) => {
                        this.$http.post('/api/portal/PdfEmail?id=' + guia.id + '&email=' + res)
                        .then((res) => {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.body,
                                type: 'is-success'
                            })
                        }, error => {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: error.body,
                                type: 'is-danger'
                            })
                        }
                    )}
                });
            },
            imprimirEtiquetaAmostraSelecionada(guia){
                this.$notificacaoHub.invoke("EtiquetaAmostraGuia",
                    [guia.id],
                    null)
                    .then(() => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: 'Impressão de etiqueta solicitado para guia: ' + guia.id,
                            type: 'is-success',
                            position: 'is-top-right',
                            queue: false
                        })
                    });
            },
            imprimirComprovanteGuiaSelecionada(guia){
                this.$notificacaoHub.invoke("ComprovanteGuia",
                    guia.id,
                    'Externo',
                    [])
                    .then(() => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: 'Impressão de comprovante solicitado para guia: ' + guia.id,
                            type: 'is-success',
                            position: 'is-top-right',
                            queue: false
                        })
                    });
            }
        }
    }

</script>