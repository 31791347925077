<template>
    <section>
        <titlebar :title-stack="titleStack">

        </titlebar>
        <div class="box" ref="lista">

                <div class="columns is-flex">
                    <div class="column">
                        <b-button size="is-small" icon-left="magnify" @click="procurarGuia()">Buscar Guia</b-button>
                    </div>                    
                    <div class="column">
                        <b-button class="is-pulled-right" size="is-small" :icon-left="todosSelecionados ? 'close-circle-multiple' : 'check-all'" @click="selecionarTodos">{{ todosSelecionados ? 'Desmarcar' : 'Selecionar' }} todos</b-button>
                    </div>

                </div>            
            <div v-if="isLoading">
                <div class="columns">
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                </div>
            </div>
            <div v-for="(item, index) in model.lista" v-bind:key="index" class="panel">
                <div class="panel-heading" :id="'guia_' + item.lisOrderID">
                    <div class="level">
                        <div class="level-left">
                          
                            <div class="level-item">
                                <div class="field has-addons">
                                 
                                    <p class="control">
       
                                        <b-button type="is-link" size="is-small" tag="router-link" 
                                                :to="{ name: 'guia', params: { id: item.lisOrderID }}" 
                                                icon-left="clipboard-plus-outline" title="Guia">
                                                {{ item.lisOrderID }}
                                        </b-button>                                        
                                    </p>

                                </div>
                            </div>

                            <div class="level-item">
                                <p class="control">
                                    <b-button type="is-link" size="is-small" tag="router-link" 
                                              :to="{ name: 'guia', params: { id: item.lisOrderID }}" 
                                              icon-left="flask-outline" title="Amostra">
                                            {{ item.sampleID }}
                                    </b-button>
                            
                                </p>
                            </div>    

                            <div v-if="item.guiaLocal" class="level-item">
                                {{ item.guiaLocal }}
                            </div>                                                 
                            
                            <div class="level-item">
                                {{ item.patientInfo }}
                            </div>                              

                        </div>
                        <div class="level-right">

                            <div class="level-item">        
                                <p class="control">
                                    <label class="b-checkbox checkbox is-large is-marginless">
                                        <input type="checkbox" true-value="true" :value="item.confirmGuia" @change="confirmGuiaChange(item.lisOrderID)">
                                        <span class="check"></span>
                                    </label>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="item.medicamentos" class="panel-block">
                    <small><b>Medicamentos: </b> {{ item.medicamentos }}</small>
                </div>
                <div v-for="(result, indexResult) in item.results" v-bind:key="indexResult">
                    <div class="columns panel-list-item">
                        <div class="column">
                            <b-icon
                                v-if="mediaUltimosResultados(item.patientId, result.lisTestCode, result.lisResultCode) != null && 
                                loadLastResults(item.patientId, result.lisTestCode, result.lisResultCode)[0].deltaCheck && 
                                isFloat(result.value) &&
                                ((result.value < (mediaUltimosResultados(item.patientId, result.lisTestCode, result.lisResultCode) * (1 - (loadLastResults(item.patientId, result.lisTestCode, result.lisResultCode)[0].deltaCheck / 100)))) || 
                                (result.value > (mediaUltimosResultados(item.patientId, result.lisTestCode, result.lisResultCode) * (1 + (loadLastResults(item.patientId, result.lisTestCode, result.lisResultCode)[0].deltaCheck / 100)))))"
                                type="is-danger" icon="alert-rhombus" size="is-medium" 
                                :title="'Média = ' + mediaUltimosResultados(item.patientId, result.lisTestCode, result.lisResultCode)  + 
                                ' - Delta = ' + loadLastResults(item.patientId, result.lisTestCode, result.lisResultCode)[0].deltaCheck + ''"></b-icon>
                                
                            <span class="tag">{{ result.lisTestID }}</span>
                            <b>{{ result.lisTestName }}</b>
                        </div>
                        <div class="column">
                            <p>
                                {{ result.resultName }} {{ result.resultCode }}
                                &#8660;
                                {{ result.lisResultName }}
                            </p>
                            <p v-for="(comment, indexComment) in result.comments" v-bind:key="indexComment" class="has-background-light">
                                <span>{{ comment.commentText }} </span>
                                <small>({{ comment.commentSource }}/{{ comment.commentType }})</small>
                            </p>
                        </div>
                        <div class="column has-text-right">
                            <h4 class="is-size-4">
                                <small v-show="result.referencesRange" class="tag is-warning">{{ result.referencesRange }}</small>
                                <span v-show="result.interpretationCode" class="tag is-danger">{{ result.interpretationCode }}</span>                                
                                <span> {{ result.value }}  </span>
                                <span>{{ result.units }} </span>
                            </h4>
                        </div>
                        <div class="column is-1">
                            <b-button size="is-small" type="is-link" icon-left="pencil" @click="editResult(result)"></b-button>                                                                
                            <b-button size="is-small" type="is-danger" :loading="deletando" icon-left="delete" @click="confirmDelete(result, item)"></b-button>                                                                                            
                            <b-checkbox class="is-pulled-right center" style="margin: 0;" size="is-large" @change.native="result.confirm = !result.confirm" :value="result.confirm"></b-checkbox>                                
                        </div>
                    </div>
                    <div class="whitebox lastresults"  v-if="loadLastResults(item.patientId, result.lisTestCode, result.lisResultCode)">
                        <div>
                            <small><b>resultados anteriores</b></small>
                            <div class="is-pulled-right">
                                <div class="field">
                                    <b-switch size="is-small" v-model="ultimosResultadosGrafico">Gráfico</b-switch>
                                </div>
                            </div>
                        </div>
                        <div v-if="!ultimosResultadosGrafico">
                            <div class="columns">
                                <div class="column" v-for="(ur, indexUr) in loadLastResults(item.patientId, result.lisTestCode, result.lisResultCode)" :key="indexUr">
                                    <p class="tag is-info">{{ $moment(ur.dataDeSolicitacao).format("DD/MM/YYYY HH:mm") }}</p>
                                    <p>{{ ur.titulo }}: <b>{{ ur.valorFormatado }}</b></p>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div>
                                <div class="card">
                                    <ultimos-resultados :chart-data="loadLastResults(item.patientId, result.lisTestCode, result.lisResultCode)" height=100></ultimos-resultados>
                                </div>
                            </div>
                        </div>
                    </div>     
                </div>
            </div>
            <b-pagination v-if="model.lista != null && model.lista.length > 0"
                :total="total"
                :current.sync="current"
                :range-before="rangeBefore"
                :range-after="rangeAfter"
                :per-page="perPage"
                :icon-prev="prevIcon"
                :icon-next="nextIcon"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page">
            </b-pagination>
        </div>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                              :loading="salvandoDados"
                              native-type="submit"
                              icon-left="check-circle"
                              @click="submit()">
                        Salvar
                    </b-button>
                    <b-button type="is-danger"
                              native-type="button"
                              icon-left="close-circle"
                              @click="$router.back()">
                        Cancelar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>

<style scoped>
.lastresults {    
    border: 1px solid #dbdbdb; 
    padding: 10px;
}
</style>

<script>
    import titlebar from '@/components/titlebar.vue'
    import UltimosResultados from '@/components/Charts/UltimosResultados'
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
                model: {},
                ultimosResultados: [],
                ultimosResultadosGrafico: false,
                todosSelecionados: false,
                isLoading: true,
                salvandoDados: false,
                deletando: false,
                total: 1,
                current: 1,
                perPage: 20,
                rangeBefore: 3,
                rangeAfter: 1,
                prevIcon: 'chevron-left',
                nextIcon: 'chevron-right'
            }
        },
        components: {
            titlebar,
            UltimosResultados
        },
        watch: {
            current: function(val){
                this.model = {};
                this.isLoading = true;
                this.carregarLista();                
            }
        },
        computed: {
            titleStack() {
                return [
                    'Interface',
                    'Confirmação',
                    this.$route.params.id
                ]
            },
            loadLastResults() {
                return (patientid, listestcode, lisresultcode) => {
                    const x = this.ultimosResultados
                        .filter(x => x.params[0] == `pacienteId=${patientid}` && x.params[1] === `exameId=${listestcode}`)
                        .map(w => w.data.ultimosResultados
                            .filter(z => z.campoId == lisresultcode))

                    return x[0]
                }
            },
            mediaUltimosResultados(){
                return (patientid, listestcode, lisresultcode) => {
                    if ((this.loadLastResults(patientid, listestcode, lisresultcode) == null) || (this.loadLastResults(patientid, listestcode, lisresultcode).length == 0))
                        return null;

                    let qtd = 0;
                    let soma = 0;

                    this.loadLastResults(patientid, listestcode, lisresultcode).filter(item => item.campoId == lisresultcode && this.isFloat(item.valor)).forEach(campo => {
                        
                        soma += campo.valor;
                        qtd++;
                    });

                    return soma / qtd;
                }                
            },
            ...mapState([
                'config',
            ])
        },
        mounted() {
            this.carregarLista();
        },
        methods: {
            isFloat(n){
                return Number(n) == n;
            },
            carregarLista() {
                this.$http.get('/api/interface/confirm?id=' + this.$route.params.id + '&page=' + this.current)
                    .then(res => {
                        this.model = res.data
                        this.total = this.perPage * res.data.pageCount
                        this.isLoading = false
                        this.loadUltimosResultados()
                    })
            },
            loadUltimosResultados() {
                this.model.lista.forEach(x => {
                    x.results.forEach(res => {
                        this.ultimosResultadosResult(x, res);
                    })
                })
            },
            ultimosResultadosResult(x, res){
                const params = [
                    `pacienteId=${x.patientId}`,
                    `exameId=${res.lisTestCode}`,
                    `dataReferencia=${res.equipmentReceivedDatetime}`,
                ]

                if (!this.ultimosResultados.some(w => w.params[0] === params[0] && w.params[1] === params[1])) {
                    this.$http.get(`/api/interface/ultimosresultados?${params.join('&')}`)
                        .then(x => {                                    
                            if (x.data.ultimosResultados.length > 0) {
                                this.ultimosResultados.push({
                                    params: params,
                                    data: x.data
                                })
                            }
                        })
                }
            },
            selecionarTodos() {
                this.todosSelecionados = !this.todosSelecionados
                this.model.lista.forEach(x => {
                    x.confirmGuia = this.todosSelecionados
                    x.results.forEach(y => {
                        y.confirm = this.todosSelecionados
                    })
                })
            },
            confirmGuiaChange(lisOrderID) {
                this.todosSelecionados = !this.todosSelecionados
                this.todosSelecionados = !this.todosSelecionados
                this.model.lista.forEach(x => {
                    if(x.lisOrderID == lisOrderID){
                        if(x.confirmGuia == null) {
                            x.confirmGuia = false;
                        }
                        x.confirmGuia = !x.confirmGuia;
                        x.results.forEach(y => {
                            y.confirm = x.confirmGuia
                        })
                    }
                })
            },
            procurarGuia() {
                this.$buefy.dialog.prompt({
                    message: 'Procurar guia:',
                    inputAttrs: {
                        type: 'number'
                    },
                    confirmText: 'Confirmar',
                    cancelText: 'Cancelar',                    
                    onConfirm: (value) => {
                        const elementId = 'guia_' + value
                        if (document.getElementById(elementId) == null) {

                            //buscar e incluir no final da lista
                            this.$http.get('/api/interface/confirm?id=' + this.$route.params.id + '&guiaId=' + value)
                                .then(res => {
                                    if(res.data == null || res.data.lista == null || res.data.lista.length == 0){
                                        this.$buefy.toast.open({
                                            message: 'Guia não encontrada: ' + value,
                                            type: 'is-danger'
                                        })
                                    }
                                    else {
                                        this.model.lista = this.model.lista.concat(res.data.lista);

                                        res.data.lista.forEach(x => {
                                            x.results.forEach(res => {
                                                this.ultimosResultadosResult(x, res);
                                            })
                                        })
                                        
                                        setTimeout(() => this.$scrollTo('#' + elementId), 10);                                        
                                    }
                                })
                        } else {
                            this.$scrollTo('#' + elementId)
                        }
                    }
                })
            },
            confirmDelete(result, testorder) {
                if (this.config.confirmarExclusaoNaConfirmacaoInterface) {
                    this.$buefy.dialog.confirm({
                        title: 'Excluir resultado',
                        message: 'Tem certeza que deseja <b>excluir</b> este resultado? Esta ação não pode ser desfeita.<br/><b>' + result.lisTestName + '</b> (' + result.lisResultName + '): ' + result.value + ' ' + result.units,
                        confirmText: 'Excluir',
                        cancelText: 'Cancelar',
                        type: 'is-danger',
                        hasIcon: true,
                        onConfirm: () => {
                            this.deleteResult(result, testorder)
                        }
                    })
                } else {
                    this.deleteResult(result, testorder)
                }
            },
            deleteResult(result, testorder) {
                this.deletando = true
                this.$http.post('/api/interface/delete', result.resultId)
                    .then(res => {
                        testorder.results = testorder.results.filter(x => x.resultId != res.data)
                        this.$buefy.toast.open(`Resultado ${res.data} excluído`)
                        this.deletando = false
                    }, error => {
                        this.$buefy.toast.open({
                            message: 'Não foi possível excluir o resultado. Verifique o console para mais informações.',
                            type: 'is-danger'
                        })
                        this.deletando = false
                    })
            },
            editResult(result) {
                this.$buefy.dialog.prompt({
                    title: 'Editar resultado',
                    message: result.resultCode + '&#8660;' + result.lisResultName,
                    inputAttrs: {
                        value: result.value
                    },
                    confirmText: 'Confirmar',
                    cancelText: 'Cancelar',                    
                    onConfirm: (value) => {
                        this.$http.post('/api/interface/edit', {
                            id: result.resultId,
                            value: value
                        })
                            .then(res => {
                                if (res.status == 204) {
                                    this.$buefy.toast.open({
                                        message: 'Não foi possível alterar o resultado: nenhum valor foi informado.',
                                        type: 'is-danger'
                                    })
                                } else {
                                    result.value = res.data.value
                                    this.$buefy.toast.open({
                                        message: `Resultado ${res.data.id} alterado para: ${res.data.value}`,
                                        type: 'is-success'
                                    })
                                }
                            })
                    }
                })
            },
            submit() {
                this.salvandoDados = true
                let confirmed = []
                this.model.lista.forEach(x => {
                    confirmed = confirmed.concat(x.results
                        .filter(y => y.confirm)
                        .map(w => {
                            return {
                                resultID: w.resultId,
                                lisOrderID: x.lisOrderID,
                                lisTestID: w.lisTestID,
                                lisResultCode: w.lisResultCode,
                                lisResultVersion: w.lisResultVersion,
                                value: w.value,
                                sendSigned: w.sendSigned,
                                confirm: true
                            }
                        })
                    )
                })
                this.$http.post('/api/interface/confirm', confirmed)
                    .then((res) => {
                        this.salvandoDados = false
                    })
                    .catch((error) => {
                        this.salvandoDados = false
                        throw error
                    });

                this.$buefy.toast.open({
                    message: 'Os resultados estão sendo confirmados, este processo pode levar alguns minutos.',
                    type: 'is-success'
                })
                this.$router.push({ name: 'equipamentos' })
            }
        }
    }
</script>