<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column">
                            <b-switch v-model="atendimentoInterno" @input="filtrosAlterados=true">Atendimento Interno</b-switch>
                        </div>
                        <div class="column">
                            <b-switch v-model="coletaDomiciliar"  @input="filtrosAlterados=true">Coleta Domiciliar</b-switch>
                        </div>                        
                    </div>
                     <div class="columns">
                         <div class="column is-10">
                             <periodoHora
                                :dataInicial.sync="dataInicial"
                                :dataFinal.sync="dataFinal"
                                placeHolder="Selecione o Período"
                                :selecionadas="[dataInicial,dataFinal]"
                             />
                         </div>
                         <div class="column is-2">
                            <button class="button is-primary" type="button" @click="filtrar({dataInicial, dataFinal})">Filtrar</button>
                         </div>
                     </div>

                     <br />

                    <b-table :data="getEventos(model)" striped hoverable>
                        <template slot-scope="props" >
                            <b-table-column field="data" label="Data">
                                {{ props.row.dataHora | datahora}}
                            </b-table-column>
                            <b-table-column field="paciente" label="Paciente">
                                {{ props.row.pacienteNome}}
                            </b-table-column> 
                            <b-table-column field="endereco" label="Endereço">
                                {{ props.row.pacienteEndereco}}
                            </b-table-column>                               
                            <b-table-column field="bairro" label="Bairro">
                                {{ props.row.pacienteBairro}}
                            </b-table-column>                               
                            <b-table-column field="cep" label="CEP">
                                {{ props.row.pacienteCEP}}
                            </b-table-column>                               
                            <b-table-column field="cidade" label="Cidade">
                                {{ props.row.pacienteCidade}}
                            </b-table-column>                               
                            <b-table-column field="fone" label="Fone">
                                {{ props.row.pacienteFone}}
                            </b-table-column>                               
                            <b-table-column field="Observacao" label="Observação">
                                {{ props.row.observacao}}
                            </b-table-column>                                                                                                                                                                                                    

                        </template>
                    </b-table>
                </article>
            </div>
        </div>        
    </section>
</template>

<style lang="scss" scoped>

</style>
<script>
    import titlebar from '@/components/titlebar.vue'
    import periodoHora from '@/components/periodoHora.vue'
    import moment from 'moment';

    export default {
        components: {
            titlebar,
            periodoHora
        },
        computed: {
            titleStack() {
                return [
                    'Atendimento',
                    'Relação de Agendamento'
                ]
            }
        },
        data() {
            return {
                dataInicial: moment().add('month',-1).set('hours',0).set('minutes',0).toDate(),
                dataFinal: moment().set('hours',23).set('minutes',59).toDate(),
                model: [],
                atendimentoInterno: true,
                coletaDomiciliar: true,
                filtrosAlterados: false,
                isLoading: false,
                loadedData: false,
            }
        },
        created(){
            try {
                if (this.$route.query.dataInicial) this.dataInicial = moment(this.$route.query.dataInicial,'DD/MM/YYYY HH:mm').toDate();
                if (this.$route.query.dataFinal) this.dataFinal = moment(this.$route.query.dataFinal,'DD/MM/YYYY HH:mm').toDate();            

                

                this.loadData();
            }catch(ex){
                console.error(ex);
            }
        },      
        filters: {
            datahora:  (valor) => {
            if (!valor) return null;    
            
            return  moment(valor).format('DD/MM/YYYY HH:mm:ss');
            }
        },
        methods: {            
            filtrar(datas) {
                this.dataInicial = datas.dataInicial;
                this.dataFinal = datas.dataFinal;
                this.loadData();
            },
            getEventos(data){
                return data?.filter(e => 
                    {
                        return (
                            (this.coletaDomiciliar || this.atendimentoInterno) //verifica se algum dos filtros está marcado
                            && (((this.filtrosAlterados && this.coletaDomiciliar != null) ? this.coletaDomiciliar == e.atendimentoDomiciliar : true) //verifica se é do tipo marcado
                            || ((this.filtrosAlterados && this.atendimentoInterno != null) ? this.atendimentoInterno == e.atendimentoInterno : true)));
                    });
            },            
            loadData(){
                const params = [];

                if (this.dataInicial != null) params.push(`dataInicial=${moment(this.dataInicial).format()}`);
                if (this.dataFinal != null) params.push(`dataFinal=${moment(this.dataFinal).format()}`);
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);            
                this.isLoading = true;

                try {
                    this.$http.get(`/api/atendimento/RelacaoAgenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {
                                this.model = data?.map(d => {
                                    return {
                                        ...d,
                                        dataHora: moment(d.dataHora).toDate(),
                                        atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                                        atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                                    }
                                });
                            }
                            this.isLoading = false;
                            this.loadedData = true;
                        }).catch(e => {
                            throw e;
                        });
                }catch(e){
                    console.error(e);
                    this.isLoading = false;
                }

            },
           
        }
    }
</script>