<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Informações</p>
        </header>
        <section class="modal-card-body">
            <div v-for="(exame,index) in selecionado" :key="index">
                <div class="columns panel-list-item">
                    <div class="column is-1">
                        <p class="heading">Guia - Item</p>
                        <p class="subtitle">
                            {{ guia.id }} - {{ exame.item }}
                        </p>
                    </div>
                    <div class="column is-4">
                        <p class="heading">Exame</p>
                        <p class="subtitle">
                            <b>{{ exame.exameApelido }}</b> - <b>{{ exame.exameNome }}</b> ({{ exame.exameId }})
                        </p>
                    </div>
                    <div class="column">
                        <p class="heading">Setor</p>
                        <p class="subtitle">
                            <b>{{ exame.exameSetorNome }}</b> ({{ exame.exameSetorId }})
                        </p>
                    </div>
                    <div class="column">
                        <p class="heading">Data de entrega</p>
                        <p class="subtitle">
                            <span>{{ exame.dataDeEntrega | moment('DD/MM/YYYY [às] HH:mm') }}</span>
                        </p>
                    </div>
                    <div class="column" v-if="guia.unidadeDeCadastroId">
                        <p class="heading">Unidade</p>
                        <p class="subtitle">
                            <b>{{ guia.unidadeDeCadastroNome }}</b> ({{ guia.unidadeDeCadastroId }})
                        </p>
                    </div>
                    <div class="column" v-if="exame.codigoIntegracao">
                        <p class="heading">Código de integração:</p>
                        <p class="subtitle">
                            <b>{{ exame.codigoIntegracao }}</b>
                        </p>
                    </div>
                    <div class="column">
                        <p class="heading">Última modificação:</p>
                        <p class="subtitle">
                            {{ guia.dataUltimaModificacao | moment('DD/MM/YYYY [às] HH:mm') }}
                        </p>
                    </div>
                </div>
                <div class="columns is-multiline panel-list-item" v-for="(log,index) in itemLog(exame)" :key="index">
                    <div class="column is-5">
                        <h5 class="is-size-5">
                            {{ log.descricao }}
                            <a v-if="log.status == 512 && !log.exclusao" @click="acessaFatura(log.dados.replace('Receber: ', ''))" 
                                class="button is-small is-primary" title="Editar">
                                <b-icon icon="pencil" size="is-small"></b-icon>
                            </a>
                        </h5>
                    </div>
                    <div class="column is-3">
                        {{ dataPorExtenso(log.dataHora) }}
                    </div>
                    <div class="column">
                        <p>
                            por <b>{{ log.usuarioNome }}</b>
                        </p>
                        <p v-if="log.unidadeId">
                            na unidade <b>{{ log.unidadeNome }}</b> ({{ log.unidadeId }})
                        </p>
                    </div>
                    <div v-if="log.info || log.exclusao" class="column is-2 has-text-right">
                        <b-tag v-if="log.info">{{ log.info }}</b-tag>
                        <b-tag v-show="log.exclusao" type="is-danger" rounded>X</b-tag>
                    </div>
                    <div v-if="isInRole('resultado-visualizar') && log.status == 524288 /*ResultadoRetificado*/" class="column is-12">
                        <div class="box">
                            <table class="table table-striped table-hover table-condensed table-bordered is-fullwidth">
                                <thead>
                                    <th colspan="2" class="is-warning">Resultados anteriores à retificação</th>
                                </thead>
                                <tbody>
                                        <tr v-for="(item,j) in log.itens" :key="j">
                                            <td v-if="item.campoTitulo != null" class="has-text-left">{{item.campoTitulo}}</td>
                                            <td v-else class="has-text-left">{{item.campoNome}}</td>
                                            <td class="has-text-right">
                                                <img v-if="item.campoTipoValor == 'G'" :src="'data:image/png;base64,' + item.valor" />
                                                <div v-else-if="item.campoTipoValor == 'D'" class="MascaraCampoDiv">
                                                    <div>{{item.valor}}</div>
                                                </div>
                                                <span v-else>{{item.valorFormatado}}</span>
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$emit('close')">Fechar</button>
        </footer>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'

    export default {
        props: {
            guia: Object,
            selecionado: Array
        },
        data() {
            return {
                lista: []
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ])
        },
        mounted() {
            this.loadLista()
        },
        methods: {
            dataPorExtenso(dataHora){
                dataHora = new Date(dataHora);
                const day = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"][dataHora.getDay()];
                const date = dataHora.getDate();
                const month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][dataHora.getMonth()];
                const year = dataHora.getFullYear();

                return `${day}, ${date} de ${month} de ${year} às ${this.$moment(dataHora).format('HH:mm:ss')}`;
            },
            loadLista() {
                this.$http.get('/api/atendimento/infoExame{?id,item*}', {
                    params: {
                        id: this.guia.id,
                        item: this.selecionado.map(x => x.item)
                    }
                })
                    .then(res => {
                        this.lista = res.data
                    })
            },
            itemLog(guiaexame) {
                return this.lista.filter(x => x.guiaItem === guiaexame.item)
            },
            acessaFatura(id) {
                this.$emit('close');
                this.$router.push({ path: `/financeiro/receber/${id}` })
            }
        }
    }
</script>