<template>
    <section>
        <titlebar :title-stack="titleStack" v-if="!this.portal"></titlebar>     
        <div id="otimizar_imagem" style="display: none;"></div>
        <div class='box' v-if="lista.length > 0">
            <div class="level">
                <div class="level-item">
                    <router-link v-if="this.portal" :to="{ name: 'portalconvenioguia', params: { id: lista[0].guia.id }}" class="button is-primary">
                        {{ lista[0].guia.id }}
                    </router-link>
                    <router-link v-else :to="{ name: 'guia', params: { id: lista[0].guia.id }}" class="button is-primary">
                        {{ lista[0].guia.id }}
                    </router-link>
                </div>
                <div class="level-item">
                    <small>Paciente:</small>
                    <b>{{ lista[0].guia.pacienteNome }}</b>
                </div>
                <div class="level-item">
                    <small>Data de retorno:</small>
                    <b>{{ $moment(lista[0].guia.dataDeRetorno).format("DD/MM/YYYY HH:mm") }}</b>
                </div>
            </div>
        </div>

        <div class='box' v-if="lista.length > 0">
            <div class="panel" v-for="(item, index) in lista" v-bind:key="index">
                <div class="panel-heading background-2">
                    <div class="level">
                        <div class="level-left">
                            <strong>{{item.pesquisa.nome}}</strong>
                        </div>
                        <div class="level-right">
                            <b-tag v-for="(guiaExameId, j) in item.guiaExameIds" v-bind:key="j" type="is-success">{{item.guia.exames.filter(x => x.item == guiaExameId.item)[0].exameApelido}}</b-tag>
                        </div>
                    </div>
                </div>
                <div class="panel-block columns is-multiline is-justified-between">
                    <div class="column is-12 is-fullwidth" v-for="(pergunta, idxperg) in item.pesquisa.perguntas" :key="idxperg">
                        <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth" >
                            <tr v-for="(perguntaItem, idxpergitem) in pergunta.perguntaItens" :key="idxpergitem">
                                <th width="40%">
                                    <span v-if="idxpergitem == 0">{{ pergunta.texto }}</span>
                                </th>
                                <td v-if="perguntaItem.aceitaDescritivo">
                                    <b-input type="text" v-model="perguntaItem.resposta"></b-input>
                                </td>
                                <td v-else-if="perguntaItem.aceitaData">
                                    <b-datepicker v-model="perguntaItem.resposta"></b-datepicker>
                                </td>
                                <td v-else-if="perguntaItem.aceitaArquivo">
                                    <div class="columns">
                                        <b-field class="file">
                                            <b-upload @input="incluirAnexo" v-model="arquivoInserido[pergunta.id]" expanded>
                                                <a class="button is-primary is-fullwidth">
                                                <b-icon icon="upload"></b-icon>
                                                <span>{{ (arquivoInserido[pergunta.id]) ? arquivoInserido[pergunta.id].name : "Carregar Arquivo" }}</span>
                                                </a>
                                            </b-upload>
                                        </b-field>

                                    </div>
                                </td>
                                <td v-else-if="perguntaItem.somenteNumerico">
                                    <b-input type="number" v-model="perguntaItem.resposta"></b-input>
                                </td>
                                <td v-else>
                                    <b-radio v-model="pergunta.resposta"
                                        :name="'lista_' + index + 'pergunta_' + pergunta.id"
                                        :native-value="perguntaItem.id">
                                        {{ perguntaItem.texto }}
                                    </b-radio>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                                native-type="button"
                                :loading="salvandoDados"
                                @click="salvar()"
                                icon-left="check-circle">
                        Salvar
                    </b-button>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                        Voltar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>


<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import utilFile from '../../assets/js/views/atendimento/utilFile.js'    
    
    export default {
        data() {
            return {
                salvandoDados: false,
                id: this.$route.params.id,
                pagamentoGuia: this.$route.params.pagamentoGuia,
                condicaoPagamento: this.$route.params.condicaoPagamento,
                lista: [],
                formData: new FormData(),
                arquivoInserido: []
            }
        },
        props: {
            portal: Boolean
        },
        components: {
            titlebar        
        },
        computed: {
            ...mapState([
                'config'
            ]),            
            titleStack() {
                return [
                    this.portal ? 'Portal' : 'Atendimento',
                    'Pesquisa Clínica',
                    this.id
                ]
            }
        },
        mounted() {
            this.salvandoDados = true
                this.$http.get(`/api/atendimento/PesquisaClinica?id=${this.$route.params.id}`)
                    .then(({ data }) => {
                        this.lista = data;
                    })
                    .catch((error) => {
                        this.lista = []
                        throw error
                    })
                    .finally(() => {
                        this.salvandoDados = false
                    })
        },
        methods:{


            incluirAnexo(file) {
                                
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Arquivo '+ file.name + ' carregado com sucesso!',
                    type: 'is-success'
                })
                    

            },                        
            async salvar() {
                let arquivos = '';
                const arquivosOtimizados = [];
                
                this.arquivoInserido.forEach((arq, index) => {
                    if(arq != null && arq != '' && arq != undefined){                        
                        arquivosOtimizados.push({index: index, arquivo: arq });
                    }
                });
                

                for(let i = 0; i < arquivosOtimizados.length;i++){
                    
                    //Verifica se o arquivo é uma imagem
                    if(utilFile.isFileImage(arquivosOtimizados[i].arquivo)){
                        
                        //Faz a leitura do arquivo e retorna um Blob
                        const newBlob = await utilFile.createFileReader(arquivosOtimizados[i].arquivo); 
                        //Recebe um novo Blob com uma imagem otimizada
                        const newFile = await utilFile.resizeImage(newBlob,arquivosOtimizados[i].arquivo.type,arquivosOtimizados[i].arquivo.name,parseFloat(this.config.percentualOtimizacaoImagem)); 
                        this.formData.append('files', newFile, newFile.name);
                    
                    //Caso contrário segue fluxo normal para salvar o arquivo
                    }else{
                        this.formData.append('files', arquivosOtimizados[i].arquivo, arquivosOtimizados[i].arquivo.name);
                    }
                    arquivos += ',' + arquivosOtimizados[i].index;                    
                    
                }

                this.formData.append('arquivosAlterados', arquivos.substring(1))

                //TODO: deixar dessa forma para não precisar refazer o método de salvar no back-end, por enquanto
                this.lista.forEach((item, index) => {
                    let guiaExameIds = ''
                    item.guiaExameIds.forEach(guiaExameId => {
                        guiaExameIds += ',' + guiaExameId.guiaId + "_" + guiaExameId.item
                    })

                    item.pesquisa.perguntas.forEach((pergunta, i) => {
                        pergunta.perguntaItens.forEach((perguntaItem, j) => {
                            if(perguntaItem.aceitaData){
                                perguntaItem.resposta = this.$moment(perguntaItem.resposta).format()
                            }

                            this.formData.append("lista[" + index + "].Respostas["+ i +"].GuiaExameIds", guiaExameIds.substring(1));
                            this.formData.append("lista[" + index + "].Respostas["+ i +"].Pergunta.Id", pergunta.id);
                            this.formData.append("lista[" + index + "].Respostas["+ i +"].PerguntaItem.AceitaData", perguntaItem.aceitaData);
                            this.formData.append("lista[" + index + "].Respostas["+ i +"].PerguntaItem.AceitaDescritivo", perguntaItem.aceitaDescritivo);
                            this.formData.append("lista[" + index + "].Respostas["+ i +"].PerguntaItem.AceitaArquivo", perguntaItem.aceitaArquivo);

                            if(perguntaItem.resposta != null && perguntaItem.resposta != '' && perguntaItem.resposta != undefined){
                                this.formData.append("lista[" + index + "].Respostas["+ i +"].Descritivo", perguntaItem.resposta);
                            }

                            if(!perguntaItem.aceitaDescritivo && !perguntaItem.aceitaData && !perguntaItem.aceitaArquivo && !perguntaItem.somenteNumerico){
                                this.formData.append("lista[" + index + "].Respostas["+ i +"].PerguntaItem.Id", pergunta.resposta);
                            }
                            else {
                                this.formData.append("lista[" + index + "].Respostas["+ i +"].PerguntaItem.Id", perguntaItem.id);
                            }
                        })
                    })
                });

                this.salvandoDados = true;
                                
                this.$http.post('/api/atendimento/PesquisaClinicaRespostas', this.formData)
                    .then(res => {
                        if(this.portal){
                            this.$router.push({ name: 'portalconvenioworkflow', 
                                params: { 
                                    id: this.id
                                }
                            });
                        }
                        else {
                            if(this.pagamentoGuia && this.condicaoPagamento  != 0){
                                this.$router.push({ name: 'contas-receber-particular', 
                                    params: { 
                                        guiaId: this.id, 
                                        condicaoPagamento: this.condicaoPagamento 
                                    }
                                });
                            }
                            else if (this.id) {
                                this.$router.push({ name: 'workflow', 
                                    params: { 
                                        id: this.id
                                    }
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        this.lista = []
                        throw error
                    })
                    .finally(() => {
                        this.salvandoDados = false
                    })
                    
                    
            }
        }
    }
</script>