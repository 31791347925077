<template>
    <section>        
        <filtros :tipo="'Amostras'" @filtrar="filtrar($event)"></filtros>  
        <div v-if="isLoading">
            <b-loading active></b-loading>
        </div>
        <div v-else>


            <div v-if="model !=null && model.lista.length>0" class=" box columns has-text-centered">
                <div class="column is-11">
                    <p class="title">{{model.sub}}</p>
                </div>

                <div class="column is-1 is-full-mobile has-text-right has-text-centered-mobile">
                    <div class="buttons is-inline">
                        <b-button icon-left="check-all"
                                  title="Selecionar/deselecionar todas amostras"
                                  @click="selecionarTodas()">
                        </b-button>
                        <b-button icon-left="printer"
                                  title="Imprimir amostras"
                                  @click="imprimirEtiquetaAmostras()">
                        </b-button>
                    </div>
                </div>
            </div>  
  
            <div class="columns is-multiline" v-if="model !=null && model.lista.length>0">
                <div class="column is-12" v-for="(item, index) in model.lista" v-bind:key="index" @click="selecionarAmostra(item, item.amostra.id)">
                    <amostra v-bind:key="index" :amostra="item.amostra" :selecionada="selecionadas.includes(item.amostra.id)" :mostraPaciente="true"></amostra> 
                </div>                
            </div>

        </div>
    </section> 
</template>

<script>
    import { mapState } from 'vuex'
    
    import filtros from '@/components/portal/filtros.vue';
    import amostra from '@/components/atendimento/amostra.vue'
            

    export default {
        data() {
            return {
                model: null,                
                isLoading: false,                                         
                selecionadas: [],

            }
        },
        computed: {             
            ...mapState([
                'config',
                'usuario'
            ])
        },
        components: {            
            filtros,
            amostra
        },
        methods: {
            selecionarTodas(){
                this.selecionadas = [];
                this.model.lista.forEach(item => {
                    item.amostra.selecionada = !item.amostra.selecionada;
                    
                    if(item.amostra.selecionada){
                        this.selecionadas.push(item.amostra.id);
                    }else{
                        this.selecionadas = this.selecionadas.filter(x => x != item.amostra.id);
                    }
                    
                });                
            },
            imprimirEtiquetaAmostras() {

                if (this.selecionadas.length == 0) {
                    this.$buefy.toast.open({
                        message: 'Nenhuma amostra selecionada',
                        type: 'is-danger'
                    })
                }
                else {
                    this.loadingImpressaoEtiqueta = true
                    this.$notificacaoHub.invoke("EtiquetaAmostra",
                        this.selecionadas,
                        localStorage.etiquetaAtual)
                        .then(() => {
                            this.loadingImpressaoEtiqueta = false
                        })
                        .catch(e => {
                            this.loadingImpressaoEtiqueta = false
                            this.$buefy.snackbar.open({
                                duration: 2000,
                                message: 'Erro na impressão de etiquetas: ' + e.toString(),
                                type: 'is-danger',
                                position: 'is-top-right',
                                queue: false
                            })
                        })
                }                
            },            

            selecionarAmostra(item, id){                                
                if(this.selecionadas.indexOf(id) >= 0){                                        
                    this.selecionadas = this.selecionadas.filter(item => item != id);
                } else {                      
                    this.selecionadas.push(id); 
                }

                if(item.amostra.selecionada == null){                    
                    item.amostra.selecionada = false;
                }

                item.amostra.selecionada = !item.amostra.selecionada;  
                
            },
 
            filtrar(filtros){                

                this.selecionadas = [];
                let filtrosUrl =  '?dataInicial=' + this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss') + 
                    '&dataFinal=' + this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss')+ '&filtrarPor=' + filtros.filtrarPor+
                    '&ordenarPor=' + filtros.ordenarPor;                    

                if(filtros.paciente != null){
                    filtrosUrl += '&nomePaciente=' + filtros.paciente;
                }

                this.carregarLista(filtrosUrl);
            },            
            carregarLista(filtrosUrl){

                this.isLoading = true;
                this.$http.get('/api/portal/RelatorioDeAmostra' + filtrosUrl)
                    .then(res => {
                        this.model = res.data;   
                        const itens = [];                        
                        this.model.lista.forEach(x => {  
                            x.selecionada = false;    
                            itens.push({amostra: x});                            
                        }); 

                        this.model.lista = itens;
                        this.isLoading = false;                        
                    });
            },
        }       
    }
</script>