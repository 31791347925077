<template>
    <div class="panel">
        <div class="panel-heading">
            Filtros
        </div>
        <div class="panel-block columns is-multiline">
                <div v-if="(tipo!='Integracoes' && tipo!='Resultados')" class="column is-6  is-full-mobile">
                    <label class="label">Paciente</label>
                    <b-input v-model="filtros.paciente" name="paciente" placeholder="Procurar por nome/Data de nascimento/Id da guia"/>
                </div>

                <div v-if="(tipo=='Integracoes')" class="column is-6">
                    <label class="label">Integradores</label>
                    <div class="control" style="width:100%">
                        <span class="select is-empty" style="width:100%">

                            <select placeholder="Integrador" v-model="filtros.integradorId" style="width:100%;">                                
                                <option v-for="option in integradores"
                                        :value="option.id"
                                        :key="option.id">
                                    {{ option.nome }}
                                </option>
                            </select>                            

                        </span>

                    </div>
                </div>                


                <div v-if="( tipo=='Resultados')" class="column is-6 is-full-mobile">
                    <searchIdName :id.sync="filtros.local.id" :item.sync="filtros.local" table="Local" label="Local"/>
                </div>
                <div class="column is-6">
                    <label class="label">Período</label>                    
                    <periodoHora :dataInicial.sync="filtros.datas[0]" :dataFinal.sync="filtros.datas[1]"/>                                            
                </div>                

                <div v-if="(tipo=='Local')" class="column is-6 is-full-mobile">
                    <searchIdName :id.sync="filtros.convenio.id" :item.sync="filtros.convenio" table="Convenio" label="Convenio"/>
                </div>
                <div v-if="(tipo=='Local')" class="column is-6 is-full-mobile">
                    <searchIdName :id.sync="filtros.medico.id" :item.sync="filtros.medico" table="Medico" label="Medico"/>
                </div>
                <div v-if="(tipo=='Convenio' || tipo=='RelacaoExames')" class="column is-6 is-full-mobile">
                    <searchIdName :id.sync="filtros.local.id" :item.sync="filtros.local" table="Local" label="Local"/>
                </div>

                <div class="column is-6 is-full-mobile" v-if="(tipo=='Local') || (tipo=='Convenio')" >
                    <label class="label">Situação</label>
                    <div class="control" style="width:100%">
                        <span class="select is-empty" style="width:100%">

                            <select placeholder="Situação" v-model="filtros.filtrarPor" style="width:100%;">                                
                                <option value="todos" checked > Todos</option>
                                <option value="parciais" > Parciais</option>
                                <option value="prontos" > Prontos</option>
                                <option value="atrasados" > Atrasados</option>
                                <option v-if="(tipo=='Convenio')" value="recoleta" > Recoleta</option>
                            </select>                            

                        </span>

                    </div>
                </div>  

                <div class="column is-4 is-full-mobile" v-if="(tipo=='Resultados')">
                    <label class="label">Filtrar por</label>
                    <div class="control" style="width:100%">
                        <span class="select is-empty" style="width:100%">

                            <select placeholder="Filtrar por" v-model="filtros.filtrarPor" style="width:100%;">                                
                                <option value="solicitacao" checked > Data de solicitação</option>
                                <option value="retorno" >  Data de retorno</option>
                                <option value="assinatura" > Data de assinatura</option>
                            </select>                            

                        </span>

                    </div>
                </div>  

                <div class="column is-4 is-full-mobile"  v-if="(tipo=='Resultados')">   
                    <b-switch v-model="filtros.somenteNaoImpressos">
                        Somente resultados não impressos
                    </b-switch>                    
                </div>                

                <div class="column is-4 is-full-mobile" v-if="(tipo=='Resultados')" >   
                    <b-switch v-model="filtros.somentePortal">
                        Somente exames do portal
                    </b-switch>                    
                </div>                

        
                <div class="column is-6 is-full-mobile"  v-if="( tipo=='Amostras')">
                    <label class="label">Ordenar por</label>
                    <div class="control" style="width:100%">
                        <span class="select is-empty" style="width:100%">

                            <select placeholder="Ordenar por" v-model="filtros.ordenarPor" style="width:100%;">                                
                                <option value="emissao" checked > Data de emissão</option>
                                <option value="guia" > Número da guia</option>
                                <option value="paciente" > Nome do paciente</option>
                                <option v-if="( tipo!='Amostras')" value="alteracao" > Última modificação</option>
                                <option v-if="( tipo!='Amostras')" value="retorno" > Data de retorno</option>
                            </select>                            

                        </span>

                    </div>
                </div> 

                <div class="column is-6 is-full-mobile" v-if="(tipo=='Amostras')">
                    <label class="label">Situação</label>
                    <div class="control" style="width:100%">
                        <span class="select is-empty" style="width:100%">

                            <select placeholder="Situação" v-model="filtros.filtrarPor" style="width:100%;">
                                <option value="todos" checked> Todos</option>
                                <option value="semLote">Pedidos sem lote</option>
                                <option value="enviados"> Lotes enviados</option>
                                <option value="envNaoRecebidos"> Lotes enviados e não recebidos</option>
                                <option value="recebidos"> Lotes recebidos</option>
                                <option value="amostraNaoImpressa"> Amostras não impressas</option>
                            </select>                            

                        </span>

                    </div>
                </div>                                                    
           

        </div>
        <div class="panel-block">
            <b-button icon-left="magnify" type="is-primary" :loading="loading" @click="carregarGuias()">Buscar</b-button>            
            <b-button  v-if="(tipo!='Amostras' && tipo!='Resultados'  && tipo!='RelacaoExames')" type="is-danger" native-type="button" icon-left="close-circle" @click="fecharModal()">Cancelar</b-button>            
                
        </div>
    </div>
</template>


<style scoped>

hr{
    margin: 0.5rem 0  !important;
}


.label-filtros{
    min-height: 36px !important;
    font-size: 15px !important

}


</style>



<script>
    import { mapState } from 'vuex'
    import searchIdName from '@/components/searchIdName.vue'
    import periodoHora from '@/components/periodoHora.vue'

    export default {
        components: {
            searchIdName,
            periodoHora
        },
        props: {
            pesquisas: Array,
            tipo: String,
        },
        data() {
            return {
                filtros: {
                    somentePortal:false,
                    somenteNaoImpressos:false,
                    filtrarPor: (this.tipo == 'Resultados') ? 'solicitacao'  : 'todos',
                    integradorId:null,
                    ordenarPor: 'emissao',
                    medico: {
                        id: null
                    },
                    local: {
                        id: null
                    },
                    convenio: {
                        id: null
                    },
                    paciente: null,
                    datas: [
                        this.$moment().subtract(7, 'days').toDate(),
                        this.$moment().toDate()
                    ]
                },
                loading: false,
            };
        },
        computed: {
            ...mapState([
                'config',
                'usuario',
                'integradores',
                
            ])
        },
        methods: {
            carregarGuias(){
                this.$emit('filtrar', this.filtros);
            },
            fecharModal(){
                this.$emit('fecharModal');
            },             
        }
    }

</script>