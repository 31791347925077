<template>
    <section>
        <titlebar :title-stack="titleStack">
           
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field label="Nome"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>                            
                            </div>                          
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                            v-if="isInRole('centroDeCusto-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                Salvar
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                Cancelar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'


    export default {
        data() {
            return {
                model: {
                    id: 0,
                    nome: null,
                    taxaCredito: 0,
                    taxaDebito: 0
                },

                salvandoDados: false,
            };
        },
        components: {
            titlebar,            
        },
        created() {
            if (this.$route.params.id) {
                this.$http.get('/api/financeiro/centroDeCusto?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;                      
                    })

            }
        },
        computed: {
            titleStack() {
                return [
                    'Financeiro',
                    'Bandeiras',
                    this.model.id ?? 'novo'
                ]
            },          
            ...mapState([
                'unidadeId',
                'config'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {
          
        },
        methods: {
            onSubmit() {
                const newItem = Object.assign({}, this.model);          

                this.$http.post('/api/financeiro/centroDeCusto', newItem)
                    .then(() => {
                        this.$router.push({ name: 'financeiro' })
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao salvar bandeira: ' + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
            }        
        }
    }
</script>