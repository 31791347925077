import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { LaborlisWebsocketManager } from "./laborlisWebsocketManager"
import { RootState } from '../rootState'
import { FilaImpressaoManager } from './filaImpressaoManager'

const state: LaborlisWebsocketManager =
{
	address: localStorage.LaborWebSocketAddress,

	status: "Inativa",
	statusClass: "warning", 

	impressoras: [],

	impressorasDisponiveis: ["Nenhuma"],

	filasImpressoras: []
}

const actions: ActionTree<LaborlisWebsocketManager, RootState> = {
	getFilasImpressoras({ state }) {
		const imp = state.impressoras;
		state.filasImpressoras = []
		for (let i = 0; i < imp.length; i++) {
			const fila: FilaImpressaoManager = {
				nome: imp[i],
				printId: localStorage.getItem("printid_" + imp[i]) || '',
				printAuto: JSON.parse(localStorage.getItem("printauto_" + imp[i]) || 'false'),
				jobs: JSON.parse(localStorage.getItem("printjobs_" + imp[i]) || '[]')
			}
			state.filasImpressoras.push(fila)
		}
	},
	salvarConfiguracoes({ state }) {
		for (let i = 0; i < state.filasImpressoras.length; i++) {
			const impressora = state.filasImpressoras[i];
			console.log(impressora)
			localStorage.setItem("printid_" + impressora.nome, impressora.printId);
			localStorage.setItem("printauto_" + impressora.nome, impressora.printAuto.toString());
		}
	}
}

const mutations: MutationTree<LaborlisWebsocketManager> = {
	SET_ADDRESS(state, address) {
		state.address = address;
		localStorage.setItem("LaborWebSocketAddress", address);
	},
	SET_STATUS(state, { text, css }) {
		state.status = text;
		state.statusClass = css;
	},
	SET_IMPRESSORAS_DISPONIVEIS(state, impressoras) {
		state.impressorasDisponiveis = impressoras;
	},
	CHECK_IMPRESSORA(state, impressora) {
		console.log(impressora)
		if (impressora != null && state.impressoras.indexOf(impressora) < 0) {
			state.impressoras.push(impressora)
			localStorage.setItem("printers", JSON.stringify(state.impressoras))
		}
	},
	ADD_PRINT_JOB(state, { impressora, printJob }) {
		const fila = state.filasImpressoras.filter(x => x.nome === impressora)[0]
		fila.jobs.push(printJob)
		localStorage.setItem("printjobs_" + impressora, JSON.stringify(fila.jobs))
	},
	CLEAN_FILA_IMPRESSAO(state, impressora) {
		const fila = state.filasImpressoras.filter(x => x.nome == impressora)
		if (fila.length > 0) {
			fila[0].jobs = []
		}
		localStorage.setItem("printjobs_" + impressora, "[]");
	},
	REGISTRAR_LOG_IMPRESSAO_AMOSTRA(any, ids) {
		const formData = new FormData()
		formData.append('ids', ids)
		Vue.http.post('/Home/RegistraLogImpressaoAmostra', formData)
			.catch(erro => {
				Vue.prototype.$buefy.toast.open({
					message: erro,
					queue: false
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	actions,
	mutations
}