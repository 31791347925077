<template>
    <section>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('guia-bancada')" class="panel">
                    <p class="panel-heading background-1">
                        Mapa de Trabalho
                    </p>
                    <div class="panel-block">
                        <router-link :to="{ name: 'emissao-bancada' }" class="button is-primary is-fullwidth">
                            Emissão
                        </router-link>
                    </div>
                    <p class="panel-block">
                        Progresso por:
                    </p>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'progresso-bancada' }" class="button is-info is-fullwidth">
                            Bancada
                        </router-link>
                        <router-link :to="{ name: 'progresso-setor' }" class="button is-light is-fullwidth">
                            Setor
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="config.naoCarregarAutomaticoPendencias && !guiasCarregadas" class="panel">
                    <p class="panel-heading has-background-warning">
                        Guias Urgentes / com Indicadores
                    </p>
                    <p class="panel-block">
                        <button class="button is-light is-fullwidth" @click="carregarGuiasPendentes">
                            Listar pendências
                        </button>
                    </p>
                </div>

                <div v-if="guiasUrgentes.length > 0" class="panel">
                    <p class="panel-heading has-background-warning">
                        Guias Urgentes por Local
                    </p>
                    <b-collapse class="card"
                                animation="slide"
                                v-for="(guias, local, index) of guiasUrgentesByLocal"
                                :key="index"
                                :open="isLocalUrgenteOpen == index"
                                @open="isLocalUrgenteOpen = index">
                        <div slot="trigger"
                             slot-scope="props"
                             class="card-header has-background-light"
                             role="button">
                            <p class="card-header-title">
                                {{ local }}
                            </p>
                            <a class="card-header-icon">
                                <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                                <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                            </a>
                        </div>
                        <div class="panel">
                            <router-link v-for="item in guias" v-bind:key="item.id"
                                         :to="{ name: 'guia', params: { id: item.id }}"
                                         class="panel-block is-justified-between">
                                <span class="tag is-pulled-right">
                                    {{item.id}}
                                </span>
                                {{item.pacienteNome}}
                            </router-link>
                        </div>
                    </b-collapse>
                </div>
                <div v-if="guiasIndicadores.length > 0" class="panel">
                    <p class="panel-heading has-background-warning">
                        Guias com Indicadores
                    </p>
                    <router-link v-for="(item, index) of guiasIndicadores"
                            :key="index"
                            :to="{ name: 'guia', params: { id: item.guiaId }}" class="panel-block is-justified-between">
                            <div class="columns is-multiline">
                                <div class="column">
                                    <span class="tag">
                                        {{item.guiaId}}
                                    </span>
                                </div>
                                <div class="column is-8">
                                    <span>
                                        {{item.pacienteNome}}
                                    </span>
                                </div>
                                <div class="column is-12">
                                    <guiaIndicadores :guia="item"></guiaIndicadores>
                                </div>
                            </div>
                    </router-link>                    
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('guia-bancada')" class="panel">
                    <p class="panel-heading background-1">
                        Acompanhamento
                    </p>
                    <p class="panel-block">
                        Exames:
                    </p>
                    <div class="panel-block">
                        <router-link :to="{ name: 'exames-bloqueados', query: { unidadeId: unidadeId } }" class="button is-dark is-fullwidth" :disabled="examesBloqueados != 0 ? false : true">
                            <b style="margin-right:2px;">{{ examesBloqueados }}</b>
                            <span>{{ textoExamesBloqueados }}</span>
                        </router-link>
                        <router-link :to="{ name: 'exames-entregas' }" class="button is-info is-fullwidth">
                            <span>Entregas</span>
                        </router-link>
                    </div>
                    <p class="panel-block">
                        Amostras:
                    </p>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'amostraIncidencia', query: { unidadeId: unidadeId } }" class="button is-light is-fullwidth" :disabled="amostrasIncidencia != 0 ? false : true">
                            <b style="margin-right:2px;">{{ amostrasIncidencia }}</b>
                            <span>{{ textoAmostrasIncidencia }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('resultado-editar')" class="panel">
                    <p class="panel-heading background-2">
                        Digitação de Resultados
                    </p>
                    <p class="panel-tabs">
                        <a :class="{ 'is-active': digitarResultadoFiltro == 'solicitacao' }" @click="digitarResultadoFiltro = 'solicitacao';atualizaPendenciasDigitacao()">Solicitação</a>
                        <a :class="{ 'is-active': digitarResultadoFiltro == 'coleta' }" @click="digitarResultadoFiltro = 'coleta';atualizaPendenciasDigitacao()">Coleta</a>
                        <a :class="{ 'is-active': digitarResultadoFiltro == 'entrega' }" @click="digitarResultadoFiltro = 'entrega';atualizaPendenciasDigitacao()">Entrega</a>
                    </p>
                    <div class="panel-block">
                        <periodo :dataInicial.sync="digitarResultadoDatas[0]" 
                                 :dataFinal.sync="digitarResultadoDatas[1]" 
                                 @changed="atualizaPendenciasDigitacao" />
                    </div>
                    <p class="panel-tabs">
                        <a :class="{ 'is-active': digitarResultadoCampo == 'bancadaNumero' }" @click="digitarResultadoCampo = 'bancadaNumero'">Bancada</a>
                        <a :class="{ 'is-active': digitarResultadoCampo == 'setorId' }" @click="digitarResultadoCampo = 'setorId'">Setor</a>
                        <a :class="{ 'is-active': digitarResultadoCampo == 'guiaId' }" @click="digitarResultadoCampo = 'guiaId'">Guia</a>
                    </p>
                    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(onSubmitDigitarResultados)" class="panel-block">
                            <b-input placeholder="Procurar"
                                    type="number"
                                    v-model="digitarResultadoCampoValor"
                                    icon="magnify" required></b-input>
                            <input type="hidden" v-model="unidadeId" name="unidadeId" />
                        </form>
                    </ValidationObserver>
                    <p class="panel-block">
                        Listar pendências de digitação por:
                    </p>
                    <div class="panel-block is-justified-between">
                        <a class="button is-fullwidth" :class="{ 'is-link' : pendenciasDigitacaoPor == 'local' }" @click="pendenciasDigitacaoPorLocal">
                            Local
                        </a>
                        <a class="button is-fullwidth" :class="{ 'is-link' : pendenciasDigitacaoPor == 'setor' }" @click="pendenciasDigitacaoPorSetor">
                            Setor
                        </a>
                        <a class="button is-fullwidth" :class="{ 'is-link' : pendenciasDigitacaoPor == 'convenio' }" @click="pendenciasDigitacaoPorConvenio">
                            Convênio
                        </a>
                    </div>
                    <div v-for="(item, index) in pendenciasDigitacao" :key="index">
                        <a :href="item.href" class="panel-block is-justified-between">
                            <span>{{item.nome}}</span>
                            <span class="tag is-rounded is-info">{{item.quantidade}}</span>
                        </a>
                    </div>
                    <p class="panel-heading" v-if="pendenciasDigitacaoPor != '' && pendenciasDigitacao.length == 0">
                        <small>Nenhuma pendência para o filtro selecionado</small>
                    </p>

                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('resultado-assinar')" class="panel">
                    <p class="panel-heading background-1">
                        Assinatura de Resultados
                    </p>
                    <div class="panel-block is-justified-between">
                        <b-radio v-model="assinaturaTipo"
                                 size="is-small"
                                 native-value="/Guia/Assinatura"
                                 @change.native="salvarAssinaturaTipo">
                            <span>em Lista</span>
                        </b-radio>
                        <b-radio v-model="assinaturaTipo"
                                 size="is-small"
                                 native-value="/Analise/AssinaturaGuia"
                                 @change.native="salvarAssinaturaTipo">
                            <span>por Guia</span>
                        </b-radio>
                        <b-checkbox v-model="somenteUrgentes"
                                    size="is-small"
                                    type="is-danger"
                                    @change.native="atualizaPendenciasAssinatura">
                            Somente urgentes
                        </b-checkbox>
                    </div>
                    <p class="panel-tabs">
                        <a :class="{ 'is-active': assinarResultadoFiltro == 'solicitacao' }" @click="assinarResultadoFiltro = 'solicitacao';atualizaPendenciasAssinatura()">Solicitação</a>
                        <a :class="{ 'is-active': assinarResultadoFiltro == 'coleta' }" @click="assinarResultadoFiltro = 'coleta';atualizaPendenciasAssinatura()">Coleta</a>
                        <a :class="{ 'is-active': assinarResultadoFiltro == 'entrega' }" @click="assinarResultadoFiltro = 'entrega';atualizaPendenciasAssinatura()">Entrega</a>
                    </p>
                    <div class="panel-block">
                        <periodo :dataInicial.sync="assinarResultadoDatas[0]"
                                 :dataFinal.sync="assinarResultadoDatas[1]"
                                 @changed="atualizaPendenciasAssinatura" />
                    </div>
                    <p class="panel-tabs">
                        <a :class="{ 'is-active': assinarResultadoCampo == 'bancadaNumero' }" @click="assinarResultadoCampo = 'bancadaNumero'">Bancada</a>
                        <a :class="{ 'is-active': assinarResultadoCampo == 'setorId' }" @click="assinarResultadoCampo = 'setorId'">Setor</a>
                        <a :class="{ 'is-active': assinarResultadoCampo == 'guiaId' }" @click="assinarResultadoCampo = 'guiaId'">Guia</a>
                    </p>
                    <form :action="this.assinaturaTipo" method="get" class="panel-block">
                        <b-input placeholder="Procurar"
                                 type="number"
                                 :name="assinarResultadoCampo"
                                 icon="magnify" required></b-input>
                        <input type="hidden" v-model="unidadeId" name="unidadeId" />
                    </form>
                    <p class="panel-block">
                        Listar pendências de assinatura por:
                    </p>
                    <div class="panel-block is-justified-between">
                        <a class="button is-fullwidth" :class="{ 'is-link' : pendenciasAssinaturaPor == 'local' }" @click="pendenciasAssinaturaPorLocal">
                            Local
                        </a>
                        <a class="button is-fullwidth" :class="{ 'is-link' : pendenciasAssinaturaPor == 'setor' }" @click="pendenciasAssinaturaPorSetor">
                            Setor
                        </a>
                        <a class="button is-fullwidth" :class="{ 'is-link' : pendenciasAssinaturaPor == 'convenio' }" @click="pendenciasAssinaturaPorConvenio">
                            Convênio
                        </a>
                    </div>
                    <div v-for="(item, index) in pendenciasAssinatura" :key="index">
                        <a @click="pendenciaAssinatura(item.href);" class="panel-block is-justified-between">
                            <span>{{item.nome}}</span>
                            <span class="tag is-rounded is-info">{{item.quantidade}}</span>
                        </a>
                    </div>
                    <p class="panel-heading" v-if="pendenciasAssinaturaPor != '' && pendenciasAssinatura.length == 0">
                        <small>Nenhuma pendência para o filtro selecionado</small>
                    </p>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('digitacao-lotes')" class="panel">
                    <p class="panel-heading background-2">
                        Digitação de Resultados em Lote
                    </p>
                    <div class="panel-block">
                        <a :href="'/Analise/DigitarPorLotes'" class="button is-link is-outlined is-fullwidth">
                            Digitação em lote
                        </a>
                    </div>
                </div>

                <div v-if="isInRole('resultado-visualizar')" class="panel">
                    <p class="panel-heading background-1">
                        Emissão de Resultados por Lote
                    </p>
                    <div class="panel-block">
                        <router-link :to="{ name: 'lote-resultados' }" class="button is-primary is-outlined is-fullwidth">
                            Emitir por lote
                        </router-link>
                    </div>
                    <form @submit.prevent="abrirLote" method="get" class="panel-block">
                        <b-input v-model="lote" name="lote" placeholder="Buscar Lote" icon="magnify" required/>
                    </form>
                </div>

                <div v-if="isInRole('soroteca')" class="panel">
                    <p class="panel-heading background-2">
                        Soroteca
                    </p>
                    <div class="panel-block is-justified-between">
                        <a class="button is-info is-fullwidth" href="/analise/SorotecaArmazenar">Armazenar amostras</a>
                        <a class="button is-light is-fullwidth" href="/analise/Soroteca">Soroteca</a>                     
                    </div>
                </div>


            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import guiaIndicadores from '@/components/atendimento/guiaIndicadores.vue'
    import periodo from '@/components/periodo.vue'

    export default {
        data() {
            return {
                amostrasIncidencia: 0,
                examesBloqueados: 0,
                guiasIndicadores: [],
                guiasUrgentes: [],
                guiasCarregadas: false,

                isLocalUrgenteOpen: -1,
                isLocalIndicadoresOpen: -1,

                digitarResultadoFiltro: localStorage.digitarResultadoFiltro ?? 'solicitacao',
                digitarResultadoCampo: 'guiaId',
                digitarResultadoCampoValor: null,
                digitarResultadoDatas: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ],
                pendenciasDigitacao: [],
                pendenciasDigitacaoPor: '',

                assinarResultadoFiltro: localStorage.assinarResultadoFiltro ?? 'solicitacao',
                assinarResultadoCampo: 'guiaId',
                assinarResultadoDatas: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ],
                pendenciasAssinatura: [],
                pendenciasAssinaturaPor: '',
                assinaturaTipo: localStorage.assinaturaTipo ?? '/Guia/Assinatura',
                somenteUrgentes: (localStorage.somenteUrgentes == "true")
            }
        },
        components: {
            guiaIndicadores,
            periodo,
        },
        computed: {
            textoExamesBloqueados() {
                return this.examesBloqueados > 1
                    ? "exames bloqueados"
                    : "exame bloqueado"
            },
            textoAmostrasIncidencia() {
                return this.amostrasIncidencia > 1
                    ? "amostras com incidência"
                    : "amostra com incidência"
            },
            ...mapState([
                'unidadeId',
                'config'
            ]),
            guiasUrgentesByLocal() {
                return this.guiasUrgentes.reduce((acc, guia) => {
                    (acc[guia.localDeOrigemNome] = acc[guia.localDeOrigemNome] || []).push(guia);
                    return acc;
                }, {})
            },
            periodoDigitacaoReq() {
                return this.digitarResultadoDatas.length == 2
                    ? '&filtrarPor=' + this.digitarResultadoFiltro +
                        '&dataInicial=' + this.$moment(this.digitarResultadoDatas[0]).format() +
                        '&datafinal=' + this.$moment(this.digitarResultadoDatas[1]).format()
                    : ''
            },
            periodoAssinaturaReq() {
                return (this.somenteUrgentes
                    ? '&urgentes=true'
                    : '') +
                    (this.assinarResultadoDatas.length == 2
                        ? '&filtrarPor=' + this.assinarResultadoFiltro +
                            '&dataInicial=' + this.$moment(this.assinarResultadoDatas[0]).format() +
                            '&datafinal=' + this.$moment(this.assinarResultadoDatas[1]).format()
                        : '')
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            pendenciasDigitacaoPorLocal() {
                this.pendenciasDigitacaoPor = 'local'
                this.$http.get('api/Dashboard/PendenciasDigitacao?agruparPor=local&unidadeId=' + this.unidadeId + this.periodoDigitacaoReq)
                    .then(res => {
                        this.pendenciasDigitacao = res.data.map(x => {
                            return {
                                nome: x.localNome,
                                quantidade: x.quantidade,
                                href: '/Analise/DigitarResultadosPor?' +
                                    'unidadeId=' + this.unidadeId +
                                    '&localId=' + x.localId +
                                    this.periodoDigitacaoReq
                            }
                        })
                    })
            },
            pendenciasDigitacaoPorSetor() {
                this.pendenciasDigitacaoPor = 'setor'
                this.$http.get('api/Dashboard/PendenciasDigitacao?agruparPor=setor&unidadeId=' + this.unidadeId + this.periodoDigitacaoReq)
                    .then(res => {
                        this.pendenciasDigitacao = res.data.map(x => {
                            return {
                                nome: x.setorNome,
                                quantidade: x.quantidade,
                                href: '/Analise/DigitarResultadosPor?' +
                                    'unidadeId=' + this.unidadeId +
                                    '&setorId=' + x.setorId +
                                    this.periodoDigitacaoReq
                            }
                        })
                    })
            },
            pendenciasDigitacaoPorConvenio() {
                this.pendenciasDigitacaoPor = 'convenio'
                this.$http.get('api/Dashboard/PendenciasDigitacao?agruparPor=convenio&unidadeId=' + this.unidadeId + this.periodoDigitacaoReq)
                    .then(res => {
                        this.pendenciasDigitacao = res.data.map(x => {
                            return {
                                nome: x.convenioNome,
                                quantidade: x.quantidade,
                                href: '/Analise/DigitarResultadosPor?' +
                                    'unidadeId=' + this.unidadeId +
                                    '&convenioId=' + x.convenioId +
                                    this.periodoDigitacaoReq
                            }
                        })
                    })
            },
            atualizaPendenciasDigitacao() {
                localStorage.digitarResultadoFiltro = this.digitarResultadoFiltro;

                if (this.pendenciasDigitacaoPor == 'local') {
                    this.pendenciasDigitacaoPorLocal()
                } else if (this.pendenciasDigitacaoPor == 'setor') {
                    this.pendenciasDigitacaoPorSetor()
                } else if (this.pendenciasDigitacaoPor == 'convenio') {
                    this.pendenciasDigitacaoPorConvenio()
                }
            },

            pendenciasAssinaturaPorLocal() {
                this.pendenciasAssinaturaPor = 'local'
                this.$http.get('api/Dashboard/PendenciasAssinatura?agruparPor=local&unidadeId=' + this.unidadeId + this.periodoAssinaturaReq)
                    .then(res => {
                        this.pendenciasAssinatura = res.data.map(x => {
                            return {
                                nome: x.localNome,
                                quantidade: x.quantidade,
                                href: 'unidadeId=' + this.unidadeId +
                                    '&localId=' + x.localId +
                                    this.periodoAssinaturaReq
                            }
                        })
                    })
            },
            pendenciasAssinaturaPorSetor() {
                this.pendenciasAssinaturaPor = 'setor'
                this.$http.get('api/Dashboard/PendenciasAssinatura?agruparPor=setor&unidadeId=' + this.unidadeId + this.periodoAssinaturaReq)
                    .then(res => {
                        this.pendenciasAssinatura = res.data.map(x => {
                            return {
                                nome: x.setorNome,
                                quantidade: x.quantidade,
                                href: 'unidadeId=' + this.unidadeId +
                                    '&setorId=' + x.setorId +
                                    this.periodoAssinaturaReq
                            }
                        })
                    })
            },
            pendenciasAssinaturaPorConvenio() {
                this.pendenciasAssinaturaPor = 'convenio'
                this.$http.get('api/Dashboard/PendenciasAssinatura?agruparPor=convenio&unidadeId=' + this.unidadeId + this.periodoAssinaturaReq)
                    .then(res => {
                        this.pendenciasAssinatura = res.data.map(x => {
                            return {
                                nome: x.convenioNome,
                                quantidade: x.quantidade,
                                href: 'unidadeId=' + this.unidadeId +
                                    '&convenioId=' + x.convenioId +
                                    this.periodoAssinaturaReq
                            }
                        })
                    })
            },
            atualizaPendenciasAssinatura() {
                localStorage.assinarResultadoFiltro = this.assinarResultadoFiltro;
                localStorage.somenteUrgentes = this.somenteUrgentes;

                if (this.pendenciasAssinaturaPor == 'local') {
                    this.pendenciasAssinaturaPorLocal()
                } else if (this.pendenciasAssinaturaPor == 'setor') {
                    this.pendenciasAssinaturaPorSetor()
                } else if (this.pendenciasAssinaturaPor == 'convenio') {
                    this.pendenciasAssinaturaPorConvenio()
                }
            },            
            salvarAssinaturaTipo() {
                localStorage.assinaturaTipo = this.assinaturaTipo;
            },

            carregarGuiasPendentes() {
                this.guiasCarregadas = true;
                this.$http.get('api/Dashboard/GuiasIndicadoresSemResultado?unidadeId=' + this.unidadeId)
                    .then(res => {
                        this.guiasIndicadores = res.data;
                    });

                this.$http.get('api/Dashboard/GuiasUrgentesSemResultado?unidadeId=' + this.unidadeId)
                    .then(res => {
                        this.guiasUrgentes = res.data
                    });
            },
            onSubmitDigitarResultados(){
                let url = "/Analise/DigitarResultadosPor?unidadeId=" + this.unidadeId + this.periodoDigitacaoReq;

                if(this.digitarResultadoCampo == "bancadaNumero"){
                    url += "&bancadaNumero=" + this.digitarResultadoCampoValor;
                }
                else if(this.digitarResultadoCampo == "setorId"){
                    url += "&setorId=" + this.digitarResultadoCampoValor;
                }
                else if(this.digitarResultadoCampo == "guiaId"){
                    url += "&guiaId=" + this.digitarResultadoCampoValor;
                }

                window.location = url;
            },
            pendenciaAssinatura(href){
                if(this.assinaturaTipo == "/Analise/AssinaturaGuia"){
                    window.location.href = this.assinaturaTipo + '?' + href
                }
                else {
                    if(this.pendenciasAssinaturaPor == 'setor'){
                        window.location.href = "/Guia/AssinaturaPorSetor" + '?' + href
                    }
                    else if(this.pendenciasAssinaturaPor == 'convenio'){
                        window.location.href = "/Guia/AssinaturaPorConvenio" + '?' + href
                    }
                    else {
                        window.location.href = this.assinaturaTipo + '?' + href
                    }
                }
            },            
            abrirLote() {
                this.$router.push({ name: 'abrir-lote', params: { id: this.lote } })
            }
        },
        mounted() {
            this.$http.get('api/Dashboard/QuantidadeExamesBloqueados?unidadeId=' + this.unidadeId)
                .then(res => {
                    this.examesBloqueados = res.data
                });

            this.$http.get('api/Dashboard/QuantidadeAmostrasIncidencia?unidadeId=' + this.unidadeId)
                .then(res => {
                    this.amostrasIncidencia = res.data
                });

            if (!this.config.naoCarregarAutomaticoPendencias) {
                this.carregarGuiasPendentes();
            }
        }
    }
</script>