<template>
    <section>
        <titlebar :title-stack="titleStack">

        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">

                            <div class="columns">
                                <div class="column is-10">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field label="Nome"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome" maxlength="30" :has-counter="false"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-2">
                                    <ValidationProvider rules="required" name="destinoPesquisa" v-slot="{ errors, valid }">
                                        <b-field label="Destino Pesquisa"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select placeholder="Selecione o destino" v-model="model.destinoPesquisa">
                                                <option value="Nenhum">Nenhum</option>
                                                <option value="Paciente">Paciente</option>
                                                <option value="Medico">Medico</option>
                                                <option value="Convenio">Convenio</option>
                                                <option value="Local">Local</option>
                                                <option value="Recepção">Recepção</option>
                                                <option value="Coleta">Coleta</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <b-switch v-model="model.obrigatorio">
                                            Obrigatório
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <b-switch v-model="model.compartilharRespostaGuia">
                                            Compartilhar resposta com outras guias
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <b-switch v-model="model.compartilharRespostaExame">
                                            Compartilhar resposta com outros exames
                                        </b-switch>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <article class="tile is-child box">
                            <label class="label">Perguntas</label>
                            <section v-for="(pergunta, i) in model.perguntas" v-bind:key="i">
                                <div class="columns">
                                    <div class="column is-2">
                                        <b-field>
                                            <p class="control">
                                                <button @click="deletaPergunta(i)" class="button is-danger"><i class="mdi mdi-close-box-outline"></i></button>
                                            </p>
                                            <b-input type="text" v-model="pergunta.id" readonly disabled></b-input>
                                        </b-field>
                                    </div>
                                    <div class="column">
                                        <ValidationProvider rules="required" name="texto" v-slot="{ errors, valid }">
                                            <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                     :message="errors">
                                                <b-input type="text" v-model="pergunta.texto" maxlength="30" :has-counter="false"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column is-11 is-offset-1 box">
                                        <label class="label">Respostas</label>
                                        <div class="columns" v-for="(perguntaItem, j) in pergunta.perguntaItens" v-bind:key="j">
                                            <div class="column is-1">
                                                <b-field>
                                                    <p class="control">
                                                        <button @click="deletaPerguntaItem(i, j)" class="button is-danger"><i class="mdi mdi-close-box-outline"></i></button>
                                                    </p>
                                                    <b-input type="text" v-model="perguntaItem.id" readonly disabled></b-input>
                                                </b-field>
                                            </div>
                                            <div class="column is-4">
                                                <ValidationProvider rules="required" name="texto" v-slot="{ errors, valid }">
                                                    <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                             :message="errors">
                                                        <b-input type="text" v-model="perguntaItem.texto" maxlength="30" :has-counter="false"></b-input>
                                                    </b-field>
                                                </ValidationProvider>
                                            </div>
                                            <div class="column">
                                                <div class="field">
                                                    <b-switch v-model="perguntaItem.aceitaDescritivo">
                                                        Livre?
                                                    </b-switch>
                                                </div>
                                            </div>
                                            <div class="column">
                                                <div class="field">
                                                    <b-switch v-model="perguntaItem.aceitaData">
                                                        Data?
                                                    </b-switch>
                                                </div>
                                            </div>
                                            <div class="column">
                                                <div class="field">
                                                    <b-switch v-model="perguntaItem.aceitaArquivo">
                                                        Arquivo?
                                                    </b-switch>
                                                </div>
                                            </div>
                                            <div class="column">
                                                <div class="field">
                                                    <b-switch v-model="perguntaItem.somenteNumerico">
                                                        Numerico?
                                                    </b-switch>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="columns">
                                            <div class="column has-text-centered">
                                                <b-button type="is-primary"
                                                          native-type="button"
                                                          @click="novaPerguntaItem(i)">
                                                    Incluir Resposta
                                                </b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                            </section>
                            <div class="columns">
                                <div class="column has-text-centered">
                                    <b-button type="is-primary"
                                              native-type="button"
                                              @click="novaPergunta()">
                                        Incluir Pergunta
                                    </b-button>
                                </div>
                            </div>
                        </article>
                        <div class="columns">
                            <div class="column is-one-third">
                                <article class="tile is-child box">
                                    <article class="panel">
                                        <div class="panel-heading has-text-centered">
                                            Códigos integrações Pesquisa
                                        </div>
                                        <div class="panel-block">
                                            <table class="table is-bordered is-striped is-fullwidth">
                                                <thead>
                                                    <tr>
                                                        <th>Tipo</th>
                                                        <th>Código</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in model.codigosIntegrador" v-bind:key="index">
                                                        <td>
                                                            <b-select placeholder="Integrador" v-model="item.integradorId">
                                                                <option v-for="option in integradores"
                                                                        :value="option.id"
                                                                        :key="option.id">
                                                                    {{ option.nome }}
                                                                </option>
                                                            </b-select>
                                                        </td>
                                                        <td>
                                                            <input class="input" type="text" v-model="item.codigo">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="panel-block">
                                            <button type="button" class="button is-info is-fullwidth" @click="addCodigo">
                                                Adicionar código
                                            </button>
                                        </div>
                                    </article>
                                </article>
                            </div>
                            <div class="column is-one-third">
                                <article class="tile is-child box">
                                    <article class="panel">
                                        <div class="panel-heading has-text-centered">
                                            Códigos integrações Pergunta
                                        </div>
                                        <div class="panel-block">
                                            <table class="table is-bordered is-striped is-fullwidth">
                                                <thead>
                                                    <tr>
                                                        <th>Tipo</th>
                                                        <th>Pergunta</th>
                                                        <th>Código</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in codigosIntegradorPergunta" v-bind:key="index">
                                                        <td>
                                                            <b-select placeholder="Integrador" v-model="item.integradorId">
                                                                <option v-for="option in integradores"
                                                                        :value="option.id"
                                                                        :key="option.id">
                                                                    {{ option.nome }}
                                                                </option>
                                                            </b-select>
                                                        </td>
                                                        <td>
                                                            <input class="input" type="text" v-model="item.pergunta.id">
                                                        </td>
                                                        <td>
                                                            <input class="input" type="text" v-model="item.codigo">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="panel-block">
                                            <button type="button" class="button is-info is-fullwidth" @click="addCodigoPergunta">
                                                Adicionar código
                                            </button>
                                        </div>
                                    </article>
                                </article>
                            </div>
                            <div class="column is-one-third">
                                <article class="tile is-child box">
                                    <article class="panel">
                                        <div class="panel-heading has-text-centered">
                                            Códigos integrações Respostas
                                        </div>
                                        <div class="panel-block">
                                            <table class="table is-bordered is-striped is-fullwidth">
                                                <thead>
                                                    <tr>
                                                        <th>Tipo</th>
                                                        <th>Pergunta Item</th>
                                                        <th>Código</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in codigosIntegradorPerguntaItem" v-bind:key="index">
                                                        <td>
                                                            <b-select placeholder="Integrador" v-model="item.integradorId">
                                                                <option v-for="option in integradores"
                                                                        :value="option.id"
                                                                        :key="option.id">
                                                                    {{ option.nome }}
                                                                </option>
                                                            </b-select>
                                                        </td>
                                                        <td>
                                                            <input class="input" type="text" v-model="item.perguntaItem.id">
                                                        </td>
                                                        <td>
                                                            <input class="input" type="text" v-model="item.codigo">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="panel-block">
                                            <button type="button" class="button is-info is-fullwidth" @click="addCodigoPerguntaItem">
                                                Adicionar código
                                            </button>
                                        </div>
                                    </article>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                Salvar
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                Cancelar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: {
                    id: 0,
                    nome: null,
                    obrigatorio: false,
                    destinoPesquisa: null,
                    compartilharRespostaExame: false,
                    compartilharRespostaGuia: false,
                    dataCriacao: new Date(),
                    perguntas: []
                },
                codigosIntegradorPergunta: [],
                codigosIntegradorPerguntaItem: [],
                salvandoDados: false
            }
        },
        components: {
            titlebar,
        },
        created() {
            if (this.$route.params.id) {
                this.$http.get('/api/pesquisa/pesquisa?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;

                        for (let i = 0; i < this.model.perguntas.length; i++) {
                            for (let j = 0; j < this.model.perguntas[i].codigosIntegrador.length; j++) {
                                this.codigosIntegradorPergunta.push({
                                    integradorId: this.model.perguntas[i].codigosIntegrador[j].integradorId,
                                    pergunta: { id: this.model.perguntas[i].id },
                                    codigo: this.model.perguntas[i].codigosIntegrador[j].codigo
                                });
                            }

                            for (let j = 0; j < this.model.perguntas[i].perguntaItens.length; j++) {
                                for (let k = 0; k < this.model.perguntas[i].perguntaItens[j].codigosIntegrador.length; k++) {
                                    this.codigosIntegradorPerguntaItem.push({
                                        integradorId: this.model.perguntas[i].perguntaItens[j].codigosIntegrador[k].integradorId,
                                        perguntaItem: { id: this.model.perguntas[i].perguntaItens[j].id },
                                        codigo: this.model.perguntas[i].perguntaItens[j].codigosIntegrador[k].codigo
                                    });
                                }
                            }
                        }

                    })
            }
        },
        computed: {
            titleStack() {
                return [
                    'Manutenção',
                    'Pesquisa',
                    this.model.id ?? 'novo'
                ]
            },
            ...mapState([
                'unidadeId',
                'config',
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {

                this.salvandoDados = true;

                const salvarPesquisa = {
                    pesquisa: this.model,
                    codigosIntegradorPergunta: this.codigosIntegradorPergunta,
                    codigosIntegradorPerguntaItem: this.codigosIntegradorPerguntaItem
                };

                this.$http.post('/api/pesquisa/pesquisa', salvarPesquisa)
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.push({ name: 'pesquisas' })
                    })
                    .catch(err => {
                        this.salvandoDados = false;
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao salvar pesquisa: ' + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
            },
            addCodigo() {
                this.model.codigosIntegrador.push({
                    integradorId: null,
                    codigo: null
                });
            },
            addCodigoPergunta() {
                this.codigosIntegradorPergunta.push({
                    integradorId: null,
                    pergunta: {
                        id: null
                    },
                    codigo: null
                });
            },
            addCodigoPerguntaItem() {
                this.codigosIntegradorPerguntaItem.push({
                    integradorId: null,
                    perguntaItem: {
                        id: null
                    },
                    codigo: null
                });
            },
            novaPerguntaItem(index) {
                this.model.perguntas[index].perguntaItens.push({
                    id: 0,
                    texto: null,
                    aceitaDescricao: false,
                    aceitaData: false,
                    aceitaArquivo: false,
                    somenteNumerico: false,
                    codigosIntegrador: []
                });
            },
            novaPergunta() {
                this.model.perguntas.push({
                    id: 0,
                    texto: null,
                    codigosIntegrador: [],
                    perguntaItens: [{
                        id: 0,
                        texto: null,
                        aceitaDescricao: false,
                        aceitaData: false,
                        aceitaArquivo: false,
                        somenteNumerico: false,
                        codigosIntegrador: []
                    }]
                });
            },
            deletaPergunta(index) {
                this.model.perguntas.splice(index, 1);
            },
            deletaPerguntaItem(indexPergunta, indexItem) {
                this.model.perguntas[indexPergunta].perguntaItens.splice(indexItem, 1);
            }
        }
    }
</script>