<template>
    <div>                      
        <div class="box is-hero-bar is-main-hero column is-12" v-if="integracoes != null && integracoes.length > 0">
            <div class="panel">
                <div v-for="(integracao, index) in integracoes" :key="index">
                    <div class="panel-block columns is-multiline">
                        <div class="column is-3">
                            <small>Tipo:</small>
                            <br />
                            <b>{{integracao.tipo}}</b>
                        </div>
                        <div class="column is-6">
                            <small>Integrador:</small>
                            <br />
                            <b>{{integracao.integradorId}}</b>
                        </div>
                        <div class="column is-3 is-full-mobile">
                            <small>Data:</small>
                            <br />
                            <b>{{$moment(integracao.timestamp).format("DD/MM/YYYY HH:mm")}}</b>
                        </div>
                        <div class="column is-12">
                            <small>Mensagem:</small>
                            <p>{{integracao.mensagem}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex'

    export default {

        data() {
            return {
                integracoes:[]
            };
        },    
        computed: {
            ...mapState([
                'config',
                'usuario'
            ])           
        },
        methods:{
            carregarIntegracao(){
                if(this.integracaoCount != 0){
                    this.$http.get('/api/portal/Integracao')
                        .then(res => {
                            this.integracoes = res.data;
                        });
                }
            }         
        },

        created(){
            this.carregarIntegracao();
        },         
    }

</script>