<template>
  <section>
    <b-loading :active.sync="isLoading"></b-loading>
    <b-modal
      :active.sync="isOpenModalData"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <modalLocalizarContas :titulo="'Contas a Pagar'"
        :items.sync="localizarModalData"
        :rows.sync="itemsSelecionados"
        @link="abrirPagar"
      ></modalLocalizarContas>
    </b-modal>

    <titlebar :title-stack="titleStack"> </titlebar>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="tile is-ancestor">
          <div
            class="tile is-parent is-vertical"
            :class="{ 'is-9': model.id, 'is-12': !model.id }"
          >
            <article class="tile is-child box">
              <div class="columns">
                <div class="column">
                  <b-field label="Descrição">
                    <b-input v-model="model.descricao" type="text" />
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Conta Corrente">
                    <b-select
                      placeholder=""
                      v-model="model.contaCorrente.id"
                      expanded
                    >
                      <option
                        v-for="option in contas"
                        :value="option.value"
                        :key="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Data Inicial">
                    <b-datepicker
                      v-model="model.dataInicial"
                      placeholder="Selecione uma Data"
                      icon="calendar-today"
                      horizontal-time-picker
                      expanded
                    >
                    </b-datepicker>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Data Final">
                    <b-datepicker
                      v-model="model.dataFinal"
                      placeholder="Selecione uma Data"
                      icon="calendar-today"
                      horizontal-time-picker
                      expanded
                    >
                    </b-datepicker>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Tipo de Baixa">
                    <b-select
                      placeholder=""
                      v-model="model.tipoPagarParcelaLote"
                      expanded
                    >
                      <option
                        v-for="tipoDeBaixa in tiposDeBaixas"
                        :value="tipoDeBaixa.value"
                        :key="tipoDeBaixa.value"
                      >
                        {{ tipoDeBaixa.text }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Observação">
                    <b-input v-model="model.observacao" type="text" />
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <p class="buttons">
                    <b-button
                      type="is-primary"
                      v-if="model.situacaoLoteContasPagar === 1 || !model.id"
                      native-type="button"
                      class="centered"
                      @click.prevent.stop="localizarContas()"
                    >
                      Localizar Contas
                    </b-button>
                  </p>
                </div>
              </div>
            </article>

            <br />
            <article class="tile is-child box" v-if="items.length">
              <div
                class="panel-heading has-text-centered button is-light fullwidth"
              >
                Contas
              </div>
              <div class="panel">
                <b-table :data="items" striped hoverable>
                  <template slot-scope="props">
                    <b-table-column field="documento" label="Documento">
                      <a @click="abrirPagar(props.row)" class="is-primary">
                        {{ props.row.documento }}</a
                      >
                    </b-table-column>

                    <b-table-column
                      field="fornecedor"
                      label="Fornecedor"
                    >
                      {{ props.row.fornecedor }}
                    </b-table-column>

                    <b-table-column field="notaFiscal" label="Nota Fiscal">
                      {{ props.row.notaFiscal }}
                    </b-table-column>
                    <b-table-column
                      field="contaCorrente"
                      label="Conta Corrente"
                    >
                      {{ props.row.nome }}
                    </b-table-column>
                    <b-table-column field="dataEmissao" label="Emissão">
                      {{ props.row.dataPagar | data }}
                    </b-table-column>
                    <b-table-column field="dataDeVencimento" label="Vencimento">
                      {{ props.row.dataDeVencimento | data }}
                    </b-table-column>
                    <b-table-column field="observacoes" label="Observação">
                      {{ props.row.observacao }}
                    </b-table-column>
                    <b-table-column field="valor" label="Valor">
                      {{ props.row.valor | currency }}
                    </b-table-column>
                    <b-table-column field="" label="">
                      <b-button
                        v-if="
                          model.situacaoLoteContasPagar === 1 || !model.id
                        "
                        icon-left="close"
                        type="is-danger"
                        @click.prevent.stop="removerItem(props.row)"
                      ></b-button>
                    </b-table-column>
                  </template>
                  <template slot="footer">
                    <th class="is-hidden-mobile"></th>
                    <th class="is-hidden-mobile"></th>
                    <th class="is-hidden-mobile"></th>
                    <th class="is-hidden-mobile"></th>
                    <th class="is-hidden-mobile"></th>
                    <th class="is-hidden-mobile">TOTAL:</th>
                    <th class="is-hidden-mobile">
                      {{ totalContas | currency }}
                    </th>
                    <th class="is-hidden-mobile"></th>
                  </template>
                </b-table>
              </div>
            </article>
          </div>

          <div class="tile is-parent">
            <article class="tile is-child">
              <article class="panel">
                <div class="panel-heading has-text-centered">
                  Situação
                </div>
                <div class="panel-block has-text-centered">
                  <p
                    v-if="model.situacaoLoteContasPagar == 1"
                    class="tile is-child notification is-light is-12 fix-size"
                  >
                    PENDENTE
                  </p>
                  <p
                    v-if="model.situacaoLoteContasPagar == 2"
                    class="tile is-child notification is-success is-12 fix-size"
                  >
                    PAGO
                  </p>
                  <p
                    v-if="model.situacaoLoteContasPagar == 3"
                    class="tile is-child notification is-danger is-12 fix-size"
                  >
                    CANCELADO
                  </p>
                </div>
                <br />
                <div v-if="model.id && model.situacaoLoteContasPagar == 1">
                  <div class="panel-block" v-if="isInRole('pagar-alterar')">
                    <b-button
                      type="is-success"
                      native-type="button"
                      expanded
                      icon-left="download"
                      @click.prevent.stop="baixa"
                    >
                      Baixar
                    </b-button>
                  </div>
                  <div class="panel-block" v-if="baixar">
                    <b-field label="Espécie" class="fullwidth" expanded>
                      <b-select
                        placeholder=""
                        v-model="baixar.especie.id"
                        expanded
                        class="fullwidth"
                      >
                        <option
                          v-for="option in especies"
                          :value="option.id"
                          :key="option.id"
                        >
                          {{ option.nome }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>

                  <div
                    class="panel-block"
                    v-if="baixar && baixar.especie.id == 3"
                  >
                    <b-field label="Bandeira" class="fullwidth">
                      <b-select
                        placeholder=""
                        v-model="baixar.bandeira.id"
                        class="fullwidth"
                        expanded
                      >
                        <option
                          v-for="option in bandeiras"
                          :value="option.value"
                          :key="option.value"
                        >
                          {{ option.text }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="panel-block" v-if="baixar">
                    <b-button
                      type="is-primary"
                      native-type="button"
                      expanded
                      @click.prevent.stop="confirmarBaixar"
                    >
                      Confirmar
                    </b-button>
                  </div>
                  <br v-if="baixar" />
                </div>
              </article>
            </article>
          </div>
        </div>
        <nav class="level">
          <div class="level-item">
            <p class="buttons">
              <b-button
                type="is-success"
                v-if="
                  (model.situacaoLoteContasPagar === 1 || !model.id) &&
                  isInRole('pagar-parcela-lote-alterar')
                "
                :loading="salvandoDados"
                native-type="submit"
                icon-left="check-circle"
              >
                Salvar
              </b-button>
              <b-button
                type="is-danger"
                native-type="button"
                icon-left="close-circle"
                @click="$router.back()"
              >
                Cancelar
              </b-button>
            </p>
          </div>
        </nav>
      </form>
    </ValidationObserver>
  </section>
</template>

<style scoped>
.fix-size {
  max-height: 1.5rem;
  padding-top: 0rem;
}
.btn-action {
  margin: 0.2rem;
}

.label-parcela-cancelada {
  padding-top: 0.25rem;
}

.collapse-content {
  width: 100% !important;
}

.panel .control {
  max-width: 210px;
}

.centered {
  margin: 0 auto;
}

.fullwidth {
  width: 100%;
  max-width: 100% !important;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import titlebar from "@/components/titlebar.vue";
import modalLocalizarContas from "@/components/financeiro/modalLocalizarContas.vue";
import moment from "moment";

export default {
  data() {
    return {
      model: {
        id: null,
        descricao: null,
        observacoes: null,
        dataInicial: null,
        dataFinal: null,
        especie: {
          id: 0,
        },
        contaCorrente: {
          id: 0,
        },
      },
      contas: [],
      salvandoDados: false,
      isOpenModalData: false,
      localizarModalData: null,
      erroCadastro: [],
      tiposDeBaixas: [],
      especies: [],
      isLoading: false,
      itemsSelecionados: [],
      items: [],
      totalContas: 0,
      baixar: null,
      idsExcluidos: [],
    };
  },
  components: {
    titlebar,
    modalLocalizarContas,
  },
  filters: {
    data: (valor) => {
      if (!valor) return null;

      return moment(valor).format("DD/MM/YYYY");
    },
  },
  created() {
    this.isLoading = true;
    let id = "";
    if (this.$route.params.id) {
      id = "?id=" + this.$route.params.id;
    }
    this.$http
      .get("/api/financeiro/PagarParcelaLote" + id)
      .then((res) => {
        const model = res.data?.item;
        this.contas = res.data?.contasCorrentes;
        this.tiposDeBaixas = res.data?.tiposDeBaixas;
        this.especies = res.data?.especies;
        this.bandeiras = res.data?.bandeira;

        if (!model.especie) {
          model.especie = {
            id: 0,
          };
        }
        model.contaCorrente = !model.contaCorrente ? {
          id: this.contas[0]?.value,
        } : model.contaCorrente;
        
        if (model.itens) {
          this.items = model.itens.map((i) => {
            return {
              ...i,
              dataPagar: i.pagar?.data,
              notaFiscal: i.pagar.notaFiscal,
              nome: i.pagar?.contaCorrente?.nome,
              fornecedor: i.pagar?.fornecedor?.nome,
              documento: i.pagar?.id,
              observacao: i.pagar?.observacoes,
            };
          });
          this.recalcularTotal();
        }
        this.model = model;
        this.isLoading = false;
      })
      .catch((ex) => {
        this.isLoading = false;
      });
  },
  computed: {
    titleStack() {
      return [
        "Financeiro",
        "Lote de Contas a Pagar",
        this.model.id ?? "novo",
      ];
    },
    ...mapState(["unidadeId", "config", "integradores"]),
    ...mapGetters(["isInRole"]),
  },
  watch: {
    itemsSelecionados: function (novoValor) {
      if (novoValor) {
        this.items.push(...novoValor);
        this.recalcularTotal();
      }
    },
  },
  methods: {
    onSubmit() {
      this.$forceUpdate();
      this.isLoading = true;
      const newItem = this.getItem();
      const idsExcluidos = this.idsExcluidos.length
        ? "?idsExcluidos=" + this.idsExcluidos.map(i => i.id).join(",")
        : "";
      this.$http
        .post(`/api/financeiro/PagarParcelaLote`+idsExcluidos, newItem)
        .then(() => {
          this.isLoading = false;
          this.$router.push({ name: "financeiro" });
        })
        .catch((err) => {
          this.isLoading = false;
          console.error(err);
          if (err.body?.errors) {
            Object.keys(err.body.errors).forEach((x) => {
              this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
            });
          } else {
            const mensagem =
              err.status === 500
                ? "Foi encontrado um erro no servidor ao salvar o registro"
                : "Erro ao salvar registro";
            this.$buefy.toast.open({
              duration: 5000,
              message: mensagem,
              type: "is-danger",
              queue: false,
            });
          }
        });
    },
    getItem() {
      const novoItem = {};
      novoItem.id = this.model.id;
      novoItem.descricao = this.model.descricao;
      novoItem.contaCorrente = this.contas?.find(
        (c) => c.value == this.model.contaCorrente?.id
      );
      novoItem.dataInicial = this.model.dataInicial;
      novoItem.dataFinal = this.model.dataFinal;
      novoItem.dataCancelamento = this.model.dataCancelamento;
      novoItem.observacao = this.model.observacao;
      novoItem.itens = this.items?.map((i) => {
        const valor = {
          ...i,
        };

        return valor;
      });

      if (this.model.especie) {
        novoItem.especie = this.model.especie;
      }

      return novoItem;
    },
    localizarContas() {
      const index = this.items.length ? this.items.length : 1;
      this.localizarModalData = null;
      let dataInicial = this.model.dataInicial
        ? moment(this.model.dataInicial).format()
        : null;
      let dataFinal = this.model.dataFinal
        ? moment(this.model.dataFinal).format()
        : null;
      if (dataInicial) {
        dataInicial = `dataInicial=${dataInicial}`;
      } else {
        dataInicial = "";
      }
      if (dataFinal) {
        dataFinal = `&dataFinal=${dataFinal}`;
      } else {
        dataFinal = "";
      }
      this.isLoading = true;
      this.$http
        .get(
          `/api/financeiro/ListarParcelasLotePagar?${dataInicial}${dataFinal}&index=${index}&_=${new Date().getTime()}`
        )
        .then((res) => {
          //filtrando os dados quejá existem
          const idsSelecionados = this.items?.map((i) => i.id);
          this.localizarModalData = res.data?.filter(
            (i) => idsSelecionados.indexOf(i.id) < 0
          );

            console.log(this.localizarModalData);

          this.isOpenModalData = true;
          this.isLoading = false;
        })
        .catch((e) => {
          console.error(e);
          this.isLoading = false;
        });
    },
    abrirPagar(item) {
      this.$router.push({
        name: "contaspagar",
        params: { id: item.documento },
      });
    },
    removerItem(item) {
      if (this.model.id) {
        this.idsExcluidos.push(this.items?.find((i) => i.id == item.id));
      }
      this.items = this.items?.filter((i) => i.id !== item.id);
      this.recalcularTotal();
    },
    recalcularTotal() {
      this.totalContas = 0;
      this.items.forEach((v) => {
        this.totalContas += parseFloat(v.valor?.toString());
      });
      this.totalContas = this.totalContas.toFixed(2);
    },
    baixa() {
      this.baixar = {
        especie: {
          id: this.especies[1]?.id,
        }
      };
      this.$forceUpdate();
    },
    confirmarBaixar() {
      this.isLoading = true;
      try {
        const especie = this.baixar.especie?.id
            ? `&especieDePagamento=${this.baixar.especie.id}`
            : "&especieDePagamento=";
        this.$http
            .post(
            `/api/financeiro/PagarParcelaLoteBaixar?id=${this.model.id}${especie}`
            )
            .then((res) => {
            this.isLoading = false;
            this.$router.push({ name: "financeiro" });
            })
            .catch((e) => {
            this.isLoading = false;
            console.error(e);
            this.$buefy.toast.open({
                duration: 5000,
                message: "Erro ao baixar",
                type: "is-danger",
                queue: false,
            });
            });
      }catch(e){
          this.isLoading = false;
      }
    },
  },
};
</script>
