<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <b-modal :active.sync="isModalAgendamento" has-modal-card trap-focus aria-role="dialog" aria-modal full-screen>
            <modalNovoAgendamento :id="novo.id" :observacao="novo.observacao" :data.sync="novo.data" :idPaciente.sync="novo.idPaciente" :paciente.sync="novo.paciente" @novo="adicionarAgendamento"></modalNovoAgendamento>
        </b-modal>
        <b-modal :active.sync="isModalImpressao" has-modal-card trap-focus aria-role="dialog" aria-modal>
            <modalImpressaoAgendamento :id="imprimir.id" :modelos="model.comprovanteModelos" @imprimir="imprimirModelo"></modalImpressaoAgendamento>
        </b-modal>

        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column">
                            <b-switch v-model="atendimentoInterno" @input="filtrosAlterados=true">Atendimento Interno</b-switch>
                        </div>
                        <div class="column">
                            <b-switch v-model="coletaDomiciliar" @input="filtrosAlterados=true">Coleta Domiciliar</b-switch>
                        </div>
                    </div>
                    <br />

                    <div class="columns">
                        <calendar :dates="date" is-expanded :locale="{ id: 'pt-BR', firstDayOfWeek: 1, masks: { weekdays: 'WWWW' } }" ref="calendar" @update:from-page="mesAnoAlterado">
                            <!-- <template slot="header" slot-scope="slot">
                                {{slot.month}}
                            </template> -->

                            <template slot="day-content" slot-scope="slot">
                                <!-- {{slot.day}} -->
                                <div class="day-content" :class="{[slot.day.classes.join(' ')]: true, 'hoje': slot.day.isToday }">
                                    <span :tabindex="slot.dayProps.tabIndex" :aria-label="slot.dayProps['aria-label']" class="day-label vc-day-content vc-focusable vc-font-medium vc-text-sm vc-cursor-pointer focus:vc-font-bold vc-rounded-full">{{slot.day.label}}</span>
                                    <div class="events">
                                        <div class="event tile is-child notification" v-if="getEventos(slot.day).length">
                                            <table>
                                                <tr v-for="evento in getEventos(slot.day)" :key="evento.id">
                                                    <td class="notification td-hora" :style="evento.presente ? 'background-color: rgba(126, 206, 126, 0.43)' : 'background-color: rgba(203, 93, 93, 0.39)'"><span class="small help hora">{{evento.hour}}</span></td>
                                                    <td>
                                                        <b-dropdown append-to-body aria-role="list" @change="selecionarAcao($event,evento, slot.day)">
                                                            <button class="button btn-drop" slot="trigger" slot-scope="{ active }">
                                                                <span class="small help">{{evento.pacienteNome}}</span>
                                                                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                                            </button>

                                                            <div v-for="acao in evento.acoes" :key="acao.id">
                                                                <hr class="dropdown-divider" aria-role="menuitem" v-if="acao.separator">
                                                                <b-dropdown-item aria-role="listitem" :value="acao">
                                                                    <b-icon :icon="acao.icon" v-if="acao.icon"></b-icon>
                                                                    {{acao.label}}
                                                                </b-dropdown-item>
                                                            </div>
                                                        </b-dropdown>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="botoes" v-if="habilitaAdicionar(slot)">
                                            <b-button icon-left="plus" class="icone-adicionar" title="Adicionar Evento" :class="{'icone-adicionar-hoje': isHoje(slot.day)}" @click.prevent.stop="abrirNovoEvento(slot.day)" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </calendar>
                    </div>
                </article>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
    @import "@/assets/scss/_variables.scss";

    .desc {
        margin-left: 0.5rem;
    }

    .day-content {
        min-height: 10rem;
        border: solid 1px rgba(203, 213, 224,0.5);
        border-radius: 4px;
        box-sizing: border-box;
        max-height: 10rem;
        overflow-y: auto;
    }

    .weekday-1, .weekday-7 {
        background-color: rgba(203, 213, 224,0.5);
    }

    .hoje {
        background-color: rgba($cyan,0.5);
        .day-label

    {
        font-weight: bolder;
        font-size: 1.5rem;
        padding-left: 0.3rem;
        padding-top: 0.3rem;
    }

    }

    .events {
        margin: 0.2rem;
        margin-top: 2.3rem;
        .td-hora

    {
        margin: 0;
        padding: 0;
    }

    .hora {
        font-weight: bold;
    }

    .btn-drop {
        background-color: transparent;
        border: none;
        max-width: 10rem;
        span

    {
        overflow: hidden;
        white-space: normal; /* Don't forget this one */
        text-overflow: ellipsis;
    }

    }

    .botoes {
        position: absolute;
        right: 0;
        top: 0;
    }

    .event {
        margin: 0;
        padding: 0.1rem;
    }

    }

    .icone-adicionar {
        background-color: rgba($cyan,0.2);
        color: #000;
        &-hoje

    {
        background-color: rgba($cyan,0.5);
        font-weight: bolder;
    }

    }

    .day-label {
        float: left;
    }
</style>
<script>
    import titlebar from '@/components/titlebar.vue'
    import modalNovoAgendamento from '@/components/atendimento/modalNovoAgendamento.vue'
    import modalImpressaoAgendamento from '@/components/atendimento/modalImpressaoAgendamento.vue'
    import Calendar from 'v-calendar/lib/components/calendar.umd'
    import moment from 'moment';

    export default {
        components: {
            titlebar,
            Calendar,
            modalNovoAgendamento,
            modalImpressaoAgendamento
        },
        computed: {
            titleStack() {
                return [
                    'Atendimento',
                    'Agenda'
                ]
            }
        },
        data() {
            return {
                date: null,
                model: {},
                eventos: [],
                ano: null,
                mes: null,
                atendimentoInterno: true,
                coletaDomiciliar: true,
                filtrosAlterados: false,
                isLoading: false,
                isModalAgendamento: false,
                isModalImpressao: false,
                loadedData: false,
                imprimir: { id: null },
                novo: {
                    data: null,
                    observacao: null,
                    idPaciente: null,
                    paciente: {}
                }
            }
        },
        created() {
            try {
                if (this.$route.query.ano) this.ano = parseInt(this.$route.query.ano);
                if (this.$route.query.mes) this.mes = parseInt(this.$route.query.mes);

                if (this.ano && this.mes) {
                    this.date = moment().set('month', this.mes).set('year', this.ano).toDate();
                }


            } catch (ex) {
                console.error(ex);
            }
        },
        mounted() {
            this.loadData();
        },
        methods: {
            getEvento(d) {
                return {
                    ...d,
                    atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                    atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                    day: moment(d.dataHora).format('YYYY-MM-DD'),
                    hour: moment(d.dataHora).format('HH:mm'),
                    data: moment(d.dataHora),
                    acoes: [
                        {
                            id: 1, label: 'Criar guia', click: (item) => {
                                this.$router.push({
                                    name: 'novaguia', params: {
                                        agendamento: item.id,
                                        pacienteId: item.pacienteId
                                    }
                                })
                            }
                        },
                        {
                            id: 2, label: 'Ver guias do paciente', click: (item) => {
                                this.$router.push({ name: 'guia-list', query: { pacienteId: item.pacienteId } })
                            }
                        },
                        {
                            id: 3, separator: true, label: 'Editar', click: (item, dia) => {
                                const novo = {
                                    id: item.id,
                                    paciente: {
                                        nome: item.pacienteNome,
                                        id: item.pacienteId,
                                    },
                                    idPaciente: item.pacienteId,
                                    observacao: item.observacao,
                                    data: moment(item.dataHora).toDate()

                                }

                                this.abrirNovoEvento(dia, novo);
                            }
                        },
                        {
                            id: 4, label: 'Cancelar', click: (item, day) => {
                                this.$buefy.dialog.confirm({
                                    title: 'Cancelar',
                                    message: 'Confirma o cancelamento desse agendamento?',
                                    type: 'is-danger',
                                    hasIcon: true,
                                    cancelText: 'Não',
                                    confirmText: 'Sim',
                                    onConfirm: () => {
                                        this.cancelaAgendamento(item, day)
                                    }
                                })
                            }
                        },
                        {
                            id: 5, separator: true, label: 'Agenda', icon: 'printer', click: (item) => {
                                this.abrirImprimir(item);
                            }
                        }



                    ]
                }
            },
            loadData() {
                const params = [];


                if (this.ano != null) params.push(`ano=${this.ano}`);
                if (this.mes != null) params.push(`mes=${this.mes}`);
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                this.isLoading = true;

                try {
                    this.$http.get(`/api/atendimento/agenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {
                                this.model = data;
                                this.eventos = data?.lista
                                    ?.filter(d => d.id > 0)
                                    .map(d => {
                                        return this.getEvento(d);
                                    });
                            }
                            this.isLoading = false;
                            this.loadedData = true;
                        }).catch(e => {
                            throw e;
                        });
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }

            },
            habilitaAdicionar(slot) {
                if (this.isHoje(slot.day)) return true;

                return moment(slot.day.id).isSameOrAfter(moment());
            },
            isHoje(day) {
                return day.id === (moment().format('YYYY-MM-DD'));
            },
            getEventos(day) {
                return this.eventos?.filter(e => {
                    return day.id === e.day //verifica se é mesmo dia
                        && (this.coletaDomiciliar || this.atendimentoInterno) //verifica se algum dos filtros está marcado
                        && (((this.filtrosAlterados && this.coletaDomiciliar != null) ? this.coletaDomiciliar == e.atendimentoDomiciliar : true) //verifica se é do tipo marcado
                            || ((this.filtrosAlterados && this.atendimentoInterno != null) ? this.atendimentoInterno == e.atendimentoInterno : true))
                });
            },
            abrirNovoEvento(day, novo = null) {
                let dataAgendamento = moment(day.id).toDate();
                //caso seja hoje, empurrar o horário do agendamento para uma hora à frente (Evitar problema com a data menor que a data atual)
                if (moment(dataAgendamento).isSameOrBefore(moment())) {
                    dataAgendamento = moment().add('hour', 1).set('m', 0).toDate();
                }

                this.novo = novo ? novo : {
                    data: dataAgendamento,
                    observacao: null,
                    idPaciente: null,
                    paciente: {}
                }
                this.isModalAgendamento = true;
            },
            async adicionarAgendamento(item) {
                const params = [];
                if (item.id) params.push(`id=${item.id}`);
                params.push(`pacienteId=${item.paciente.id}`);
                params.push(`datahora=${moment(item.data).format('YYYY-MM-DDTHH:mm:ss')}`);
                params.push(`observacao=${item.observacao ? item.observacao : ''}`);
                if (this.$route.query.unidadeId != null)
                    params.push(`unidadeId=${this.$route.query.unidadeId}`)
                else
                    params.push('unidadeid=');

                try {
                    let url = `/api/atendimento/agendaincluir?${params.join('&')}`;

                    if (item.id) {
                        url = `/api/atendimento/agendaEditar?${params.join('&')}`;
                    }

                    this.isLoading = true;
                    const res = await this.$http.post(url);
                    //this.isLoading = false;
                    this.loadData();
                } catch (e) {
                    if (e.status === 404) {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao salvar agendamento, por favor, verifique o horário e tente novamente',
                            type: 'is-danger',
                            queue: false
                        })
                    }
                    console.error(e);
                }

            },
            async cancelaAgendamento(item, day) {
                const params = [];
                if (item.id) params.push(`id=${item.id}`);

                try {
                    const url = `/api/atendimento/AgendaCancelar?${params.join('&')}`;
                    this.isLoading = true;
                    const res = await this.$http.get(url);
                    //this.isLoading = false;
                    this.loadData();
                } catch (e) {
                    console.error(e);
                }

            },
            mesAnoAlterado(event) {
                if (this.loadedData && (event.month != this.mes || event.year != this.ano)) {
                    this.mes = event.month;
                    this.ano = event.year;
                    this.$router.replace({ query: { mes: this.mes, ano: this.ano, unidadeId: this.$route.query.unidadeId } })
                    this.loadData();
                }
            },
            selecionarAcao(item, data, dia) {
                item.click(data, dia);
            },
            abrirImprimir(item) {
                this.imprimir = { id: null }
                this.isModalImpressao = true;
                this.imprimir.id = item.id;
            },
            imprimirModelo(data) {
                const form = document.createElement("form");
                const inputId = document.createElement("input");
                inputId.name = 'id';
                const inputModelo = document.createElement("input");
                inputModelo.name = 'modelo';

                form.method = "POST";
                form.action = "/api/atendimento/Agendamento";

                inputId.value = this.imprimir.id;
                inputModelo.value = data.nome;

                form.appendChild(inputId);
                form.appendChild(inputModelo);

                data.variaveis?.forEach(v => {
                    const input = document.createElement("input");
                    input.name = v.nome;
                    input.value = v.valor;
                    form.appendChild(input);
                });


                document.body.appendChild(form);
                form.submit();
            }
        }
    }
</script>