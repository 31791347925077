<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Configuração do Usuário</p>
        </header>
        <section class="modal-card-body is-paddingless is-marginless">
            <div class="panel">
                <div class="panel-block">
                    <b-field grouped>
                        <b-field label="Guichê" expanded>
                            <b-field>
                                <b-input placeholder="Identificação do guichê" expanded v-model="guicheAtual"></b-input>
                            </b-field>
                        </b-field>
                        <b-field label="Etiqueta padrão" expanded>
                            <b-input placeholder="Modelo de etiqueta" v-model="etiquetaAtual"></b-input>
                        </b-field>
                    </b-field>
                </div>
                <div class="panel-block">
                    <b-button tag="a"
                              href="/Account/Manage"
                              icon-left="key"
                              expanded>
                        Alterar senha
                    </b-button>
                </div>
                <div class="panel-heading">
                    <small>Links úteis:</small>
                </div>
                <div class="panel-block">
                    <div class="buttons is-justified-between">
                        <b-button tag="a"
                                  href="https://get.teamviewer.com/sysplan"
                                  target="_blank"
                                  icon-left="headset">
                            Suporte remoto
                        </b-button>
                        <b-button tag="a"
                                  href="https://www.youtube.com/channel/UCQUoFpwZeiX9hKRnEnO6YWw"
                                  target="_blank"
                                  icon-left="youtube">
                            Vídeos de treinamento
                        </b-button>
                        <b-button tag="a"
                                  href="http://sysplan.com.br/laborliswebsocketsetup.msi"
                                  target="_blank"
                                  icon-left="printer">
                            Agente de impressão
                        </b-button>
                    </div>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button is-pulled-right" type="button" @click="$parent.close()">Fechar</button>
            <button class="button is-primary" type="button" @click="salvarConfiguracoes();$parent.close();">Salvar</button>
        </footer>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                guicheAtual: localStorage.guicheAtual,
                etiquetaAtual: localStorage.etiquetaAtual
            }
        },
        methods: {
            salvarConfiguracoes() {
                localStorage.guicheAtual = this.guicheAtual
                localStorage.etiquetaAtual = this.etiquetaAtual
                this.$emit('close')
            }
        }
    }
</script>