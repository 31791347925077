<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="panel panel-primary">
            <b-table v-if="amostras.length > 0" :data="amostras"
                 :loading="loading"
                 striped
                 hoverable
                :checked-rows.sync="checkedRows"
                checkable
                :checkbox-position="checkboxPosition">
                <template slot-scope="props">
                    <b-table-column field="Contador" label="">
                        <span class="tag is-pulled-right is-dark">{{ (props.index + 1) }}</span>
                    </b-table-column>

                    <b-table-column field="Amostra" label="Amostra">
                        <div class="columns">
                            <div class="column">
                                {{ props.row.amostraCodigoDeBarras }}
                            </div>
                            <div v-if="props.row.recebimentoConfirmado" v-show="config.utilizaValidacaoHimms" class="column">  
                                <b-select required placeholder="Selecione o Motivo" class="is-fullwidth" v-model="props.row.motivoRecebimento">
                                    <option v-for="item in motivosRecebimento" :key="item.id" :value="item.id">{{item.descricaoInterno}}</option>
                                </b-select>
                            </div>
                        </div>
                    </b-table-column>

                    <b-table-column field="Guia" label="Guia">
                        {{ props.row.guia }}
                    </b-table-column>

                    <b-table-column field="DataDeEmissao" label="Data de Emissão">
                        {{ props.row.dataDeEmissao | data}}
                    </b-table-column>

                    <b-table-column field="Paciente" label="Paciente">
                        {{ props.row.pacienteNome }}
                    </b-table-column>

                    <b-table-column field="Material" label="Material">
                        {{ props.row.materialNome }}
                    </b-table-column>

                    <b-table-column field="Conservante" label="Conservante">
                        {{ props.row.conservanteNome }}
                    </b-table-column>

                    <b-table-column field="Setor" label="Setor">
                        {{ props.row.setorNome }}
                        <span class="tag is-pulled-right" :style="'background-color: #' + props.row.setorCor + '; font-size: 10pt;'">&nbsp;</span>
                    </b-table-column>

                    <b-table-column field="Exames" label="Exames">
                        <span v-for="(item, index) in props.row.examesApelidos" v-bind:key="index" class="tag is-default">
                            {{item}}<span> </span>
                        </span>
                    </b-table-column>

                    <b-table-column field="Acondicionamento" label=" ">
                        <span v-if="props.row.amostraAcondicionamento == 3" class="tag is-pulled-right is-light" title="Acondicionamento: Congelada">C</span>
                        <span v-if="props.row.amostraAcondicionamento == 2" class="tag is-pulled-right is-link" title="Acondicionamento: Refrigerada">R</span>
                        <span v-if="props.row.amostraAcondicionamento == 1" class="tag is-pulled-right is-primary" title="Acondicionamento: Ambiente">A</span>
                    </b-table-column>

                    <b-table-column field="Incidencia" label="Incidência">
                        <span class="tag pull-right is-success is-light button" v-if="props.row.incidencia == 'A'" title="Aceitar amostra">A</span>
                        <span class="tag pull-right is-warning is-light button" v-if="props.row.incidencia == 'R'" title="Aceitar amostra (Com restrição)">R</span>
                        <span class="tag pull-right is-danger is-light button" v-if="props.row.incidencia == 'N'" title="Não aceitar (Rejeição)">N</span>
                    </b-table-column>
                </template>
            </b-table>
        </div>
        
        <div v-if="notificationSemAmostras" class="notification is-warning">
            <h4>Não existem amostras que atendem ao filtro selecionado !</h4>
            Somente serão exibidos os exames que não tiveram o material entregue. Verifique o conteúdo pesquisado e tente novamente.
        </div>

        <div v-if="notificationAjaxError" class="notification is-danger">
            {{notificationAjaxErrorMessage}}
        </div>

        <div v-if="notificationEnviandoAmostras" class="notification is-success">
            As amostras estão sendo recebidas.
        </div>


        <div class="box">
            <article class="level">
                <div class="level-left">
                    <div class="is-light">
                        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(incluirAmostra)">
                                <label v-if="permiteDigitarAmostra"><strong>Código de Barras</strong></label>
                                <div class="field has-addons">
                                    <div class="control">                                 
                                        <input type="text" v-if="permiteDigitarAmostra" v-model="amostraCodigoDeBarras" class="input" ref="amostraCodigoDeBarras"  id="amostraCodigoDeBarras"/>
                                        <input type="text" v-else v-model="amostraCodigoDeBarras" style="background-color: hsl(0, 0%, 96%);background-shade:hsl(0, 0%, 96%);color: hsl(0, 0%, 96%);border:0;" 
                                            class="input" ref="amostraCodigoDeBarras"  id="amostraCodigoDeBarras" />
                                    </div>
                                    <div class="control">
                                        <b-button v-if="permiteDigitarAmostra"
                                                native-type="submit">
                                            Incluir
                                        </b-button>
                                        <b-button type="is-default is-hidden"
                                                native-type="submit">
                                            Incluir
                                        </b-button>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
                <div class="level-item">
                    <article class="level">
                        <div class="level-item">
                            <div v-show="permiteDigitarAmostra" class="is-light">
                                <label><strong>Referência</strong></label>
                                <b-select v-model="convenioId" placeholder="Selecione o convênio">
                                    <template v-for="(item, index) in this.convenios">
                                        <option v-bind:key="index" :value="item.id">
                                            {{item.nome}}
                                        </option>
                                    </template>
                                </b-select>
                                <div class="field has-addons">
                                    <div class="control">
                                        <input type="text" v-model="amostraReferencia" class="input" ref="amostraReferencia"  id="amostraReferencia"/>
                                    </div>
                                    <div class="control">
                                        <a class="button" @click="incluirReferencia();">
                                        Incluir
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
                <div class="level-right">
                    <div class="is-light" v-if="permiteDigitarAmostra">
                        <label><strong>Lote de Amostras</strong></label>
                        <div class="field has-addons">
                            <div class="control">
                                <input type="text" v-model="amostraLoteId" class="input" ref="amostraLoteId" id="amostraLoteId" />
                            </div>
                            <div class="control">
                                <a class="button" @click="incluirLote();">
                                Incluir
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
        
        <div class='box'>
            <div class="columns">
                <div class="column is-half" v-if="this.$route.params.referencia == null">
                    <div class="columns">
                        <div class="column is-4">
                            Funcionário responsável:
                            <b-select v-model="model.funcionario.id">
                                <template v-for="(item, index) in this.funcionariosAtivos">
                                    <option v-if="model.funcionario.id == item.id" selected v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-2" v-if="this.funcionariosAtivos.length > 0">
                            <br>
                            <div class="control">
                                <input type="text" placeholder="Identificador" v-model="funcionarioIdentificador" class="input"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-half" v-else>
                </div>
                <div class="column">
                    <label><strong>Temperatura (C°)</strong></label>
                    <input type="number" v-model="temperatura" class="input" />
                </div>
                <div class="column">
                    <label><strong>Latitude</strong></label>
                    <input type="text" v-model="latitude" class="input" readonly/>
                </div>
                <div class="column">
                    <label><strong>Longitude</strong></label>
                    <input type="text" v-model="longitude" class="input" readonly/>
                </div>
            </div>
        </div>

        <nav class="notification is-light level">
            <div class="level-item">
                <p class="buttons">
                    <b-button v-if="podeEnviar" type="is-success" icon-left="check-circle" @click="receberAmostras();">
                        Enviar
                    </b-button>
                    <b-button type="is-primary" @click="imprimir();">
                        Imprimir
                    </b-button>
                    <b-button v-if="this.$route.params.referencia == null" type="is-warning" @click="limparListagem();">
                        Limpar
                    </b-button>
                    <b-button type="is-danger" icon-left="close-circle" @click="$router.back();">
                        Cancelar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>
<script>
    import titlebar from '@/components/titlebar.vue'
    import { Model } from 'vue-property-decorator';
    import { mapGetters, mapState } from 'vuex'
    import modalRecebimentoIncidencia from '@/components/atendimento/modalRecebimentoIncidencia.vue'

    export default {
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    'Atendimento',
                    'Recebimento de Amostras'
                ]
            },
            ...mapState([
                'unidadeId',
                'config'
            ])
        },
        watch: {
            funcionarioIdentificador: function(val){
                if(this.funcionariosAtivos.filter(func => func.identificador == val).length > 0){
                    this.model.funcionario.id = this.funcionariosAtivos.filter(func => func.identificador == val)[0].id
                }
            },
            amostraCodigoDeBarras: function (val) {
                //verifica se existe na lista
                if(this.amostras.filter(am => am.amostraCodigoDeBarras == val).length > 0){                    
                    this.amostras.filter(am => am.amostraCodigoDeBarras == val).forEach(item => {
                            item.amostraBipada = true;
                            this.checkedRows.push(item);
                        });

                    if (this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) {
                        this.$buefy.dialog.alert({
                            title: 'Amostra já selecionada',
                            message: 'A amostra ' + this.amostraCodigoDeBarras + ' já incluída na lista.',
                            type: 'is-warning',
                            hasIcon: true,
                            onConfirm: () => this.$refs.amostraCodigoDeBarras.focus()
                        });
                    }

                    this.amostraCodigoDeBarras = null;

                }
            },
            checkedRows: function(val) {
                this.checkedRows.forEach(item => {
                    if(item.amostraBipada == null || !item.amostraBipada){
                        item.recebimentoConfirmado = true;
                    }
                });
            }
        },
        data() {
            return {
                model: {
                    funcionario: {
                        id: null
                    }
                },
                funcionariosAtivos: [],
                motivosRecebimento: [],
                convenios: [],
                amostras: [],
                todosSelecionado: false,
                funcionarioIdentificador: "",
                amostraCodigoDeBarras: "",
                amostraLoteId: "",
                amostraReferencia: "",
                temperatura: "",
                latitude: "",
                longitude: "",
                notificationLoteAmostras: true,
                notificationSemAmostras: false,
                notificationAjaxError: false,
                notificationEnviandoAmostras: false,
                notificationAjaxErrorMessage: "Erro ao buscar informações",
                convenioId: null,
                identificacaoDoFuncionario: null,
                identificacaoDoPaciente: null,
                podeEnviar: true,
                permiteDigitarAmostra: true,
                checkedRows: [],
                loading: false,
                checkboxPosition: 'left'
            }
        },
        mounted(){
            this.$http.get('/api/atendimento/recebimentodeamostras')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.model = data;
                    }
                });
                
            this.$http.get('/api/search/FuncionariosAtivos')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.funcionariosAtivos = data;
                    }
                });
                
            this.$http.get('/api/search/Convenios')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.convenios = data;
                    }
                });
                
            this.$http.get('/api/search/MotivosRecebimento').then(res => {
                    this.motivosRecebimento = res.data;
                });            

            if(this.$route.params.tipo){
                if(this.$route.params.tipo == 'coleta'){
                    //ocultar o box
                    this.permiteDigitarAmostra = false;
                    this.$refs.amostraCodigoDeBarras.focus();
                    
                    this.focusAmostra();
                    
                    if(this.$route.params.referencia){
                        //se tiver a referencia no parametro, obrigar o campo de identificação do funcionario
                        this.identificacaoDoPaciente = this.$route.params.referencia;
                        this.amostraReferencia = this.$route.params.referencia;
                        this.incluirReferencia();
                    }
                }
                else if(this.$route.params.tipo == 'impressao'){
                    //oculta o botão de enviar
                    this.podeEnviar = false;

                    if(this.$route.params.referencia){
                        //se tiver a referencia no parametro, obrigar o campo de identificação do funcionario
                        this.identificacaoDoPaciente = this.$route.params.referencia;
                        this.amostraReferencia = this.$route.params.referencia;
                        this.incluirReferencia();
                    }
                }
            }
            else {
                this.$refs.amostraCodigoDeBarras.focus();
            }

            if(this.$route.params.funcionario){
                this.identificacaoDoFuncionario = this.$route.params.funcionario;
            }

            if(this.$route.query.codigoDeBarras){
                this.amostraCodigoDeBarras = this.$route.query.codigoDeBarras;
                this.incluirAmostra();
            }

            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    if(position != null && position.coords != null) {
                        this.latitude = position.coords.latitude;
                        this.longitude = position.coords.longitude;
                    }
                });
            }
        },        
        methods: {
            focusAmostra(){
                this.$refs.amostraCodigoDeBarras.focus();
            },
            limparListagem(){
                this.amostras = [];
                this.checkedRows = [];
            },
            selecionarTodos(){
                for (const i in this.amostras) {
                    this.amostras[i].confirmar = this.todosSelecionado;
                }
            },
            incluirAmostra(){
                this.notificationLoteAmostras = false;
                this.notificationSemAmostras = false;
                this.notificationAjaxError = false;

                if (this.amostraCodigoDeBarras != null && this.amostraCodigoDeBarras != "") {
                    this.$http.get('/api/atendimento/RecebimentoDeAmostrasIncluir?amostra=' + this.amostraCodigoDeBarras)
                    .then(res => res.data)
                    .then(data => {
                        if (data != null && data.length != 0) {
                            for (const i in data) {

                                if (this.config.utilizaValidacaoHimms != null && this.config.utilizaValidacaoHimms == true
                                    && (data[i].guia == null || data[i].guia.indexOf(this.identificacaoDoPaciente) != 0)) {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: 'Essa amostra não pertence ao paciente referenciado',
                                        type: 'is-warning',
                                        queue: false
                                    });
                                    continue;
                                }
                                else if (this.amostras.filter(item => item.amostraId == data[i].amostraId).length == 0) {
                                    
                                    if(data[i].temIncidencia){
                                        this.incidencia(data[i]);
                                    }
                                    else {
                                        this.amostras.push(data[i]);
                                        if (this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) {
                                            this.checkedRows.push(data[i]);
                                        }
                                    }
                                }
                            }
                        } else {
                            this.notificationSemAmostras = true;
                        }
                        this.$refs.amostraCodigoDeBarras.focus();
                    })
                    .catch((error) => {
                        this.notificationAjaxError = true;
                    }); 
                }
                this.amostraCodigoDeBarras = "";
            },
            incluirLote(){
                this.notificationLoteAmostras = false;
                this.notificationSemAmostras = false;
                this.notificationAjaxError = false;

                this.$http.get('/api/atendimento/RecebimentoDeAmostrasIncluir?amostraLoteId=' + this.amostraLoteId)
                .then(res => res.data)
                .then(data => {
                    if (data != null && data.length != 0) {
                        for (const i in data) {
                            if(this.amostras.filter(item => item.amostraId == data[i].amostraId).length == 0){
                                this.amostras.push(data[i]);
                                if (this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) {
                                    this.checkedRows.push(data[i]);
                                }
                            }
                        }
                    } else {
                        this.notificationSemAmostras = true;
                    }
                    this.$refs.amostraLoteId.focus();
                })
                .catch((error) => {
                    this.notificationAjaxError = true;
                }); 

                this.amostraLoteId = "";
            },
            incluirReferencia(){
                this.notificationLoteAmostras = false;
                this.notificationSemAmostras = false;
                this.notificationAjaxError = false;

                let url = '';
                if(this.convenioId != null){
                    url = '&convenioId=' + this.convenioId;
                }

                if (this.config.utilizaValidacaoHimms) {
                    this.identificacaoDoPaciente = this.amostraReferencia;
                    this.identificacaoDoFuncionario = this.model.funcionario.nome;
                }

                this.$http.get('/api/atendimento/RecebimentoDeAmostrasIncluir?referencia=' + this.amostraReferencia + url)
                .then(res => res.data)
                .then(data => {
                    if (data != null && data.length != 0) {
                        for (const i in data) {
                            if(this.amostras.filter(item => item.amostraId == data[i].amostraId).length == 0){
                                this.amostras.push(data[i]);
                                if (this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) {
                                    this.checkedRows.push(data[i]);
                                }
                            }
                        }
                    } else {
                        this.notificationSemAmostras = true;
                    }
                    this.$refs.amostraReferencia.focus();
                })
                .catch((error) => {
                    this.notificationAjaxError = true;
                }); 

                this.amostraReferencia = "";
            },
            imprimir(){
                const modelo = window.localStorage.getItem("etiquetaAtual");

                if (this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) {
                    this.$notificacaoHub.invoke("EtiquetaAmostra", 
                        this.checkedRows?.map(item => {
                            return item.amostraId;
                        })
                    , modelo);
                }
                else {
                    this.$notificacaoHub.invoke("EtiquetaAmostra", 
                        this.amostras?.map(item => {
                            return item.amostraId;
                        })
                    , modelo);
                }

                this.$refs.amostraCodigoDeBarras.focus();
            },
            receberAmostras(){

                this.notificationAjaxError = false;

                let erroSemMotivo = false;
                this.checkedRows.forEach(amostra => {
                    if (amostra.recebimentoConfirmado && this.config.utilizaValidacaoHimms) {
                        if(amostra.motivoRecebimento == null){
                            this.notificationAjaxErrorMessage = "Motivo de recebimento obrigatório.";
                            this.notificationAjaxError = true;
                            erroSemMotivo = true;
                        }
                    }
                    amostra.confirmar = true;
                });

                if(erroSemMotivo){
                    return;
                }

                const recebimentoDeAmostras = {
                    amostras: this.checkedRows, 
                    funcionarioId: this.model.funcionario.id, 
                    temperatura: this.temperatura, 
                    latitude: this.latitude, 
                    longitude: this.longitude
                };

                if (this.$route.params.referencia || this.config.utilizaValidacaoHimms)
                {
                    if(this.identificacaoDoFuncionario){
                        recebimentoDeAmostras.identificacaoDoFuncionario = this.identificacaoDoFuncionario;
                    }
                    else {                        
                        this.notificationAjaxErrorMessage = "Identificação do Funcionário obrigatória!";
                        this.notificationAjaxError = true;
                        return;
                    }

                    if(this.identificacaoDoPaciente){
                        recebimentoDeAmostras.identificacaoDoPaciente = this.identificacaoDoPaciente;
                    }
                    else {                        
                        this.notificationAjaxErrorMessage = "Identificação do Paciente obrigatória!";
                        this.notificationAjaxError = true;
                        return;
                    }
                }

                this.$refs.amostraCodigoDeBarras.focus();
                this.notificationEnviandoAmostras = true;
                this.$http.post(`/api/atendimento/ReceberAmostras`, recebimentoDeAmostras )
                    .then(({ data }) => {
                        this.notificationEnviandoAmostras = false;
                    })
                    .catch((error) => {
                        this.notificationEnviandoAmostras = false;
                        this.notificationAjaxErrorMessage = error.body;
                        this.notificationAjaxError = true;
                    })
                    .finally(() => {
                        this.$refs.amostraCodigoDeBarras.focus();
                    }) 

                this.amostras = [];
                this.checkedRows = [];
            },
            confirmarIncidencia(amostra){
                this.amostras.push(amostra);
                if (this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) {
                    this.checkedRows.push(amostra);
                }
            },
            incidencia(amostra){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalRecebimentoIncidencia,
                    props: {
                        amostra: amostra,
                    },
                    events: {
                        confirmarIncidencia: this.confirmarIncidencia
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            }
        }
    }
</script>