<template>
    <section>
        <titlebar :title-stack="titleStack">
           
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field label="Nome"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome" maxlength="40" :has-counter="false"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>                            
                                <div class="column">
                                    <b-field label="Modelo de Resultado">
                                        <b-select placeholder="Selecione um Modelo" v-model="model.modeloResultado" expanded>
                                            <option
                                                v-for="option in guiaLocalInfo.modelos"
                                                :value="option"
                                                :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>                            
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="email" name="e-mail" v-slot="{ errors, valid }">
                                        <b-field label="Email"
                                                 ref="email"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="email" v-model="model.email" @blur="verificarEmail" :readonly="model.possuiUsuario"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>          
                                <div class="column">
                                    <b-field label="Tipo de Local">
                                        <b-select placeholder="Selecione um tipo de Local" v-model="model.tipoDeLocalId" expanded>
                                            <option
                                                v-for="option in guiaLocalInfo.tipoLocal"
                                                :value="option.value"
                                                :key="option.value">
                                                {{ option.text }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>                                                  
                            </div>
                            <div class="columns">
                                <div class="column is-10">
                                    <b-field label="Endereço">
                                        <b-input v-model="model.endereco" maxlength="150" :has-counter="false"></b-input>
                                    </b-field>
                                </div>         
                                <div class="column is-2">
                                    <b-field label="Urgencia minutos">
                                        <b-input v-model="model.tempoUrgencia" type="number"></b-input>
                                    </b-field>
                                </div>                                                                                          
                            </div>              
                            <search-id-name :id.sync="model.convenioId" :item.sync="model.convenio" label="Convênio" table="Convenio"></search-id-name>
              
                        </article>
                    </div>

                    <div class="tile is-parent">
                        <article class="tile is-child">      
                            <article class="panel">
                                <div class="panel-heading">                                   
                                     <b-field>
                                        <b-switch v-model="model.publicaResultadosNaInternet" type="is-success">
                                            Publica resultados na internet?
                                        </b-switch>
                                    </b-field>                             
                                     <b-field>
                                        <b-switch v-model="model.pacienteNaoVeResultadoLocal" type="is-success">
                                            Pacientes não tem acesso aos resultados
                                        </b-switch>
                                    </b-field>                                      
                                    <b-field>
                                        <b-switch v-model="model.urgente" type="is-success">
                                            Urgente
                                        </b-switch>
                                    </b-field>                                    
                                </div>
                            </article>       
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    Usuário
                                </div>
                                <div class="panel-block">
                                    <div v-if="isInRole('usuarioweb-criar') && model.possuiUsuario" class="field fullwidth">
                                        <b-button type="is-danger" class="fullwidth" @click="desativarUsuario" expanded>Cancelar usuário Web</b-button>
                                    </div>                                         
                                    <b-field>
                                        <b-switch @input="verificarEmail()" v-model="criarUsuario" type="is-success" v-if="isInRole('usuarioweb-criar') && !model.possuiUsuario">
                                            Usuário Web
                                        </b-switch>
                                    </b-field>  
                                </div>
                            </article>                                                                       
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    Códigos integrações
                                </div>
                                <div class="panel-block">
                                    <table class="table is-bordered is-striped is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th>Tipo</th>
                                                <th>Código</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in model.codigosIntegrador" v-bind:key="index">
                                                <td>
                                                    <b-select placeholder="Integrador" v-model="item.integradorId">
                                                        <option v-for="option in integradores"
                                                                :value="option.id"
                                                                :key="option.id">
                                                            {{ option.nome }}
                                                        </option>
                                                    </b-select>
                                                </td>
                                                <td>
                                                    <input class="input" type="text" v-model="item.codigo">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="panel-block">
                                    <button type="button" class="button is-info is-fullwidth" @click="addCodigo">
                                        Adicionar código
                                    </button>
                                </div>
                            </article>
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('guiaLocal-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                Salvar
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                Cancelar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<style>
    .fullwidth{
        width: 100%;
    }
</style>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    codigoIntegracao: null,
                    codigosIntegrador: [],
                    convenioId: null,
                    email: null,
                    endereco: null,
                    modeloResultado: null,
                    nome: null,
                    pacienteNaoVeResultadoLocal: false,
                    possuiUsuario: false,
                    publicaResultadosNaInternet: false,
                    tipoDeLocalId: 1,
                    acidenteOcupacional: false,
                    coletaDomiciliar: false,
                    urgente: false,
                    tempoUrgencia: null
                },
                guiaLocalInfo: {
                    modelos: [],
                    tipoLocal: []
                },
                salvandoDados: false,
                erroEmail: null,
                criarUsuario: false,
                existeUsuarioWeb: true,
            };
        },
        components: {
            titlebar,
            searchIdName
        },
        created() {
             this.$http.get('/api/manutencao/guiaLocalInfo')
                .then(res => {
                    this.guiaLocalInfo = res.data;                      
                    if (!this.$route.params.id) {
                        this.model.tipoLocal = {
                            id: res.data[0]?.value,
                            nome: res.data[0]?.text,
                        }
                    }
                })

            if (this.$route.params.id) {
                this.$http.get('/api/manutencao/guiaLocal?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;                        
                    })

            }
        },
        computed: {
            titleStack() {
                return [
                    'Manutenção',
                    'Locais',
                    this.model.id ?? 'novo'
                ]
            },          
            ...mapState([
                'unidadeId',
                'config',
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {
          
        },
        methods: {
            onSubmit() {
                const newItem = Object.assign({}, this.model);
                this.erroEmail = null;
                if (!this.$route.params.id){
                    delete newItem.id;
                }       

                if (this.criarUsuario && !newItem.email?.length){
                    this.erroEmail = 'O campo e-mail precisa ser preenchido';
                    return;
                }
                
                this.salvandoDados = true;
                this.$http.post(`/api/manutencao/guiaLocal?criarUsuario=${this.criarUsuario}`, newItem)
                    .then(() => {
                        this.$router.back()
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao salvar guia Local',
                            type: 'is-danger',
                            queue: false
                        })
                    })
                    .finally(() => {
                        this.salvandoDados = false;
                    });
            },
            addCodigo() {
                this.model.codigosIntegrador.push({
                    integradorId: null,
                    codigo: null
                });
            },
            verificarEmail() {
                this.$http.get(`/api/manutencao/VerificarUsuarioEmail?email=${this.model.email}`)
                    .then(res => {
                        if (res.data?.existe && !(res.data.tipo == 'local' && res.data.id == this.model.id)) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: 'O e-mail ' + res.data.email + ' já está sendo usado pelo usuario do ' + res.data.tipo + ' código ' + res.data.id,
                                type: 'is-danger',
                                queue: false
                            })
                            this.model.email = null
                        }
                    });
            },
            desativarUsuario(){
                this.$buefy.dialog.confirm({
                    title: 'Desativar usuário Web',
                    message: 'Desativar usuário Web',
                    confirmText: 'Confirmar',
                    cancelText: 'Cancelar',                                        
                    hasIcon: true,
                    onConfirm: () => {
                        this.$http.get(`/api/manutencao/DeletarUsuarioLocal?localId=${this.model.id}`)
                            .then(res => res.data)
                            .then(data => {
                                if(data != null && !data.ok){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: data.mensagem,
                                        type: 'is-warning',
                                        queue: false
                                    })
                                }
                                
                                this.$router.back()
                            })
                            .catch(err => {
                                console.error(err)
                                Object.keys(err.body.errors).forEach(x => {
                                    this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                                })
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: 'Erro ao excluir usuario: ' + err.body.title,
                                    type: 'is-danger',
                                    queue: false
                                })
                            })
                    }
                })
            }
        }
    }
</script>