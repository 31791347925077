<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Alterar Prazo</p>
        </header>
            <b-message
            v-for="(erro, index) in erros"
            v-bind:key="index"
            title="Erro"
            type="is-danger"
            aria-close-label="Fechar mensagem"
            >
        {{ erro }}
        </b-message>
        <section class="modal-card-body list is-paddingless is-marginless almost-full">
          <article class="tile is-child box">
            <div class="columns">
                <div class="column">
                    <div class="panel-block is-justified-between" v-for="(item, index) in data" v-bind:key="index">
                        {{item.guiaId}}: <span class="tag is-primary">{{item.exameApelido}}</span>
                    </div>
                </div>
                <div class="column">
                    <label class="label">Atualizar: </label>
                        <div class="columns">
                            <div class="column is-7">
                                <b-radio v-model="radio"
                                    name="atualizar"
                                    native-value="dia">
                                    Determinar Dias de Atraso
                                </b-radio>
                            </div>
                            <div class="column is-5">
                                <b-input type="number" v-model="valorDia"></b-input>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-7">
                                <b-radio v-model="radio"
                                    name="atualizar"
                                    native-value="hora">
                                    Determinar Horas de Atraso
                                </b-radio>
                            </div>
                            <div class="column is-5">
                                <b-input type="number" v-model="valorHora"></b-input>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-7">
                                <b-radio v-model="radio"
                                    name="atualizar"
                                    native-value="data">
                                    Determinar uma Data
                                </b-radio>
                            </div>
                            <div class="column is-5">
                                <b-datepicker :append-to-body="true" v-model="valorData" trap-focus></b-datepicker>
                            </div>
                        </div>
                </div>
            </div>   
            <div class="columns">
                <div class="column">
                    <b-field label="Motivo">
                        <b-select placeholder="Selecione o Motivo" v-model="motivo">
                            <option v-for="mot in motivos"
                                    :key="mot.id"
                                    :value="mot.id">
                                {{ mot.descricaoInterno }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
            </div>           
        </article>
        <footer class="modal-card-foot">
          <button
            class="button is-pulled-right is-success"
            type="button" @click="alterarPrazo()">
            Confirmar
          </button>

          <button class="button is-pulled-right" type="button" @click="sair()">
            Fechar
          </button>
        </footer>
    </section>
    </div>
</template>

<script>
    export default {
        props: {
            data: [],
            motivos: []
        },  
        data() {
            return {
                motivo: null,
                erros: [],
                radio: 'dia',
                valorDia: null,
                valorHora: null,
                valorData: new Date()
            };
        },
        methods: {
            sair() {
                this.$parent.close();
            },
            alterarPrazo(){
                this.erros = [];

                if (!this.motivo){
                    this.erros.push("O Motivo precisa ser informado");
                }
                
                if (this.erros.length) return;

                let guiaExames = '';

                this.data.forEach((item) => {
                    guiaExames += item.guiaId + "_" + item.item + ",";
                });

                const atualizaPrazoExames = {
                    guiaExames: guiaExames.substring(0, guiaExames.length - 1), 
                    motivoId: this.motivo
                };

                if(this.radio == 'dia'){
                    atualizaPrazoExames.prazoDias = this.valorDia;
                }
                
                if(this.radio == 'hora'){
                    atualizaPrazoExames.prazoHoras = this.valorHora;
                }

                if(this.radio == 'data'){
                    atualizaPrazoExames.prazoData = this.valorData;
                }

                this.loading = true;
                this.$http.post(`/api/atendimento/atualizaprazoexames`, atualizaPrazoExames)
                    .then(({ data }) => {
                        this.$parent.close();
                    })
                    .catch((error) => {
                        this.erros.push(error);
                    })
            }
        }
    }
</script>