<template>

    <b-notification
        type="is-light"
        :closable="false">
        <h1 class="subtitle">Olá, <b>{{usuario.nomeCadastro}}</b> !</h1>
        <p>Por esta página você poderá acompanhar todos os exames solicitados neste laboratório.</p>   
    </b-notification>            

</template>


<script>
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
            };
        },
        computed: {
            ...mapState([
                'config',
                'usuario'
            ])
        }
    }

</script>