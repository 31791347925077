<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Configuração do Usuário</p>
        </header>
        <section class="modal-card-body is-paddingless is-marginless">
            <div class="panel">
                <div class="panel-block">
                    <small><b>{{usuario.nome}}</b></small>
                </div>
                <div class="panel-block">
                    <div class="buttons is-justified-between">
                        <b-button tag="a"
                                  href="https://www.youtube.com/channel/UCQUoFpwZeiX9hKRnEnO6YWw"
                                  target="_blank"
                                  icon-left="youtube">
                            Vídeos de treinamento
                        </b-button>
                        <b-button tag="a"
                                  href="http://sysplan.com.br/laborliswebsocketsetup.msi"
                                  target="_blank"
                                  icon-left="printer">
                            Agente de impressão
                        </b-button>
                    </div>
                </div>
                <div class="panel-block">
                    <b-button icon-left="key"
                              @click="alterarSenha()"
                              expanded>
                        Alterar senha
                    </b-button>
                </div>
                <div class="panel-block">
                    <b-button icon-left="exit-to-app"
                              @click="logout(); $parent.close();"
                              expanded>
                        Sair do sistema
                    </b-button>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Fechar</button>
        </footer>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        data() {
            return {
            }
        },
        computed: {            
            ...mapState([
                'config',
                'usuario'
            ])
        },
        methods: {   
            ...mapActions([
                'logout'
            ]),
            alterarSenha(){
                this.$router.push({ name: 'portalgerenciar' });
                this.$parent.close();
            }
        }
    }
</script>