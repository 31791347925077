<template>
    <div class="modal-card">
        <header class="modal-card-head is-danger">
            <p class="modal-card-title">Recoleta</p>
        </header>
        <section class="modal-card-body">
            <div class="columns">
                <div class="column is-6">
                    <label class="label">Amostra base:</label>
                    <p>{{this.selecionada[0].codigoDeBarras}}</p>
                </div>
                <div class="column is-6">
                    <b-switch v-model="cancelarAmostraBase">Cancelar amostra base</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column is-6">
                    <b-field label="Motivo">
                        <b-select placeholder="Selecione o Motivo" v-model="motivo">
                            <option v-for="mot in motivos"
                                    :key="mot.descricaoInterno"
                                    :value="mot.descricaoInterno">
                                {{ mot.descricaoInterno }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-6">
                    <label class="label">Exames:</label>
                    <b-field>
                        <b-taginput
                            v-model="examesSelecionados"
                            :data="exames"
                            autocomplete
                            :allow-new="false"
                            :open-on-focus="true"
                            field="exames"
                            icon="label"
                            placeholder="Selecione os exames">
                        </b-taginput>
                    </b-field>
                </div>     
            </div>
            <hr>
            <label class="label">Observação:</label>
            <div class="control">
                <textarea class="textarea" v-model="observacao"></textarea>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button is-success" v-if="!salvandoDados" type="button" @click="recoleta()">Confirmar</button>
            <button class="button is-success is-loading" v-else type="button">Confirmar</button>
            <button class="button" type="button" @click="$emit('close')">Fechar</button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            guia: Object,
            selecionada: Array
        },
        data() {
            return {
                motivos: [],
                cancelarAmostraBase: false,
                motivo: null,
                observacao: null,
                exames: this.selecionada[0].itens.filter(x => !x.bloqueado && !x.cancelado && !x.resultadoDigitado && !x.faturado).map(x => x.exame.apelido),
                examesSelecionados: [],
                salvandoDados: false
            }
        },
        mounted() {
            this.examesSelecionados = this.exames;
            this.loadMotivos()
        },
        methods: {
            loadMotivos() {
                this.$http.get('/api/search/MotivosRecoleta')
                    .then(res => {
                        this.motivos = res.data
                    })
            },
            recoleta(){
                this.salvandoDados = true;

                const incluirAmostra = {
                    id: this.selecionada[0].id, 
                    guiaId: this.guia.id, 
                    motivoDescricao: this.motivo, 
                    observacao: this.observacao,
                    cancelaAmostraBase: this.cancelarAmostraBase,
                    examesNovaAmostra: this.examesNovaAmostra()
                };
                
                this.$http.post(`/api/atendimento/IncluirAmostra`, incluirAmostra )
                    .then(({ data }) => {
                        this.$buefy.toast.open({
                                duration: 5000,
                                message: 'Amostra incluída com sucesso',
                                type: 'is-success'
                            })

                        this.$emit('loadGuia');
                        this.$emit('close');
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        this.$buefy.toast.open({
                                duration: 5000,
                                message: error,
                                type: 'is-danger'
                            })
                    }); 
                   
            },
            examesNovaAmostra(){
                const examesNova = this.selecionada[0].itens.filter(item => this.examesSelecionados.filter(selecionado => selecionado == item.exame.apelido).length > 0).map(x => x.guiaExame);

                const itensExamesNova = examesNova.map(x => x.item);

                return itensExamesNova;
            }
        }
    }
</script>