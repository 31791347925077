<template>
    <section>
        <div class="columns">
            <div class="column is-one-third">
                <div v-if="isInRole('guia-alterar') || isInRole('guia-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        Consultar Guia
                    </p>
                    <p class="panel-tabs">
                        <a :class="{ 'is-active': guiaProcurarCampo == 'Código' }" @click="guiaProcurarPor('Código')">Código</a>
                        <a :class="{ 'is-active': guiaProcurarCampo == 'Referência' }" @click="guiaProcurarPor('Referência')">Referência</a>
                        <a :class="{ 'is-active': guiaProcurarCampo == 'Amostra' }" @click="guiaProcurarPor('Amostra')">Amostra</a>
                    </p>
                    <form @submit.prevent="abrirGuia" method="get" class="panel-block">
                        <b-input :placeholder="guiaProcurarPlaceholder"
                                 v-model="guiaProcurar"
                                 icon="magnify" ref="guiaProcurar"></b-input>
                        <input type="hidden" v-model="unidadeId" name="unidadeId" />
                    </form>
                    <div class="panel-block">
                        <router-link :to="{ name: 'guia-list', query: { unidadeId: unidadeId } }" class="button is-link is-outlined is-fullwidth is-small">
                            Listar todas as guias
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('guia-incluir')" class="panel">
                    <p class="panel-heading background-2">
                        Incluir guia
                    </p>
                    <p class="panel-block">
                        Digite o nome do paciente
                    </p>
                    <form @submit.prevent="procurarPaciente" method="get" class="panel-block">
                        <b-autocomplete field="nome"
                                        v-model="paciente"
                                        placeholder="Procurar paciente por nome/CPF/inscrição/data de nascimento"
                                        :data="listaPacienteAutocomplete"
                                        :loading="isFetching"
                                        @keydown.native.enter.prevent="procurarPaciente()"
                                        @typing="procurarPacienteAutocomplete"
                                        @select="option => {selected = option; this.procurarPaciente()}">
                            <template slot-scope="props">
                                <div class="media">
                                    <div class="media-left">
                                        <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                    </div>
                                    <div class="media-content">
                                        {{ props.option.nome }}
                                        <br>
                                        <small>
                                            <b>{{ props.option.dataDeNascimentoIdade }}</b>
                                            <span v-if="props.option.inscricao">Inscrição: <b>{{ props.option.inscricao }}</b></span>
                                        </small>
                                    </div>
                                    <div  v-if="props.option.nomeResponsavel" class="media-right">
                                         <b>Responsável: </b> {{ props.option.nomeResponsavel }} 
                                    </div>                                    
                                </div>
                            </template>
                        </b-autocomplete>
                        <b-button native-type="submit" icon-left="magnify"></b-button>
                    </form>
                    <div class="panel-block">
                        <a class="button is-link is-outlined is-fullwidth is-small" @click="abrirSenhasAtendimento">Senhas de atendimento</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('agenda-consultar')" class="panel">
                    <p class="panel-heading background-2">
                        Agenda
                    </p>
                    <div class="panel-block" v-if="config.naoCarregarAutomaticoPendencias && !agendaCarregada">
                        <button class="button is-light is-fullwidth" @click="carregarAgenda">Listar agendamentos para hoje</button>
                    </div>
                    <p v-if="agendaHoje.length > 0" class="panel-block">
                        Pacientes agendados para hoje
                    </p>
                    <p v-if="agendaCarregada && agendaHoje.length == 0" class="panel-block">
                        Nenhum paciente agendado para hoje
                    </p>
                    <a class="panel-block is-justified-between" v-for="(item, index) in agendaHoje" v-bind:key="index" @click="criarGuiaAgendamento(item)">
                        <div>
                            <span class="tag is-dark">{{item.dataHora | moment('HH:mm')}}</span>                          
                        </div>
                        <span>{{item.pacienteNome}}</span>
                    </a>
                    <a class="button is-outlined is-fullwidth" v-if="agendaHoje.length > 0" 
                        :href="'/Guia/RotaColetaDomiciliarHoje?unidadeId=' + unidadeId"
                        title='Clique para ver a rota para coleta domiciliar desses pacientes'>
                        <b-icon icon="road-variant"></b-icon>
                    </a>
                    <div class="panel-block">
                        <router-link :to="{ name: 'agenda', query: { unidadeId: unidadeId } }" class="button is-link is-outlined is-fullwidth">
                            Abrir Calendário
                        </router-link>
                        <a @click="$router.push(`/atendimento/agenda-relacao?unidadeId=${unidadeId}`)" class="button is-info is-outlined" title="Listar todos os agendamentos">
                            <b-icon icon="format-list-bulleted"></b-icon>
                        </a>                  
                    </div>
                </div>                        
            </div>
            <div class="column">
                <div v-if="isInRole('guia-receber-amostra')"  class="panel">
                    <p class="panel-heading background-1">
                        Coleta de materiais
                    </p>
                    <div class="panel-block is-justified-between">
                        <a @click="$router.push({ name: 'coleta' })" class="button is-light is-small">Coleta por exame</a>
                        <router-link to="/atendimento/recebimentoamostras" class="button is-primary is-small is-pulled-right">
                            Recebimento de amostras
                        </router-link>
                    </div>
                    <div class="panel-block" v-if="config.naoCarregarAutomaticoPendencias && aguardandoColeta.length == 0">
                        <button class="button is-light is-fullwidth" @click="carregarColetas">Listar pendências</button>
                    </div>
                    <p v-if="aguardandoColeta.length > 0" class="panel-heading">
                        <small>Quantidade de pacientes aguardando coleta por local:</small>
                    </p>
                    <p v-if="coletasCarregadas && aguardandoColeta.length == 0" class="panel-heading">
                        <small>Nenhum paciente aguardando coleta</small>
                    </p>
                    <div v-for="(item, index) in aguardandoColeta" v-bind:key="index">
                        <a @click="$router.push({ name: 'coleta', query: { unidadeId: unidadeId, localId: item.localId }})" class="panel-block is-justified-between">
                            <span>{{item.localNome}}</span>
                            <span class="tag is-rounded is-info">{{item.quantidade}}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('ver-ultimasGuias')" class="panel">
                    <p class="panel-heading background-2">
                        Últimas guias alteradas
                    </p>
                    <div class="panel-block" v-if="config.naoCarregarAutomaticoPendencias && ultimasGuias.length == 0">
                        <button class="button is-light is-fullwidth" @click="carregarUltimasGuias">Mostrar últimas 5 guias</button>
                    </div>
                    <div v-for="item in ultimasGuias" v-bind:key="item.id">
                        <router-link :to="{ name: 'guia', params: { id: item.id }}" class="panel-block is-justified-between">
                            <span class="tag">
                                {{item.id}}
                            </span>
                            {{item.nome}}
                        </router-link>
                    </div>
                </div>
                <div class="panel">
                    <p class="panel-heading background-1">
                        Procura de exames
                    </p>
                    <div class="panel-block">
                        <router-link to="/atendimento/procuradeexames" class="button is-link is-outlined is-fullwidth is-small">
                            Procura de exames
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('orcamento-consultar')"  class="panel">
                    <p class="panel-heading background-2">
                        Orçamentos
                    </p>
                    <form @submit.stop.prevent="$router.push({ name: 'orcamentos', query: { procurar: procurar }})" method="get" class="panel-block">
                        <b-input placeholder="Procurar"
                                    name="procurar" 
                                     v-model="procurar"
                                    icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'orcamentos' }" class="button is-light is-fullwidth">
                            Listar todos
                        </router-link>
                        <router-link v-if="isInRole('orcamento-alterar')" :to="{ name: 'orcamento' }" class="button is-link is-fullwidth">
                            Incluir
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="panel">
                    <p class="panel-heading background-1">
                        Lote de amostras
                    </p>
                    <div class="panel-block is-justified-between columns">
                        <router-link :to="{ name: 'amostralotelistar', query: { unidadeId: unidadeId } }" class="column is-4 button is-light is-fullwidth">
                            Listar
                        </router-link>
                        <router-link :to="{ name: 'amostralotecheckpoint', query: { unidadeId: unidadeId } }" class="column is-4 button is-warning is-fullwidt">
                            <b-icon icon="flag-variant"></b-icon>
                        </router-link>
                        <router-link :to="{ name: 'amostraloteenviar', query: { unidadeId: unidadeId } }" class="column is-4 button is-primary is-fullwidth">
                            Enviar
                        </router-link>
                    </div>
                </div>                
            </div>
            <div class="column">
                <div v-if="isInRole('entrega-resultados')" class="panel">
                    <p class="panel-heading background-2">
                        Entrega de Resultados
                    </p>
                    <div class="panel-block">
                        <router-link to="/atendimento/entregaresultados" class="button is-info is-outlined is-fullwidth">
                            Registrar entrega
                        </router-link>
                    </div>
                </div>
            </div>
        </div>  
        <div v-if="isInRole('guia-integradores-consultar')"  class="columns is-multiline">
            <div v-for="integrador in integradores.filter(x => x.versao == 13)" v-bind:key="integrador.id" class="column is-4">
                <div  class="panel">
                    <p class="panel-heading background-2">
                        {{integrador.nome}}
                    </p>
                    <div class="panel-block is-justified-between">
                        <periodoHora :dataInicial.sync="integradorData[0]"
                                    :dataFinal.sync="integradorData[1]" /> 
                    </div>
                    <div class="panel-block is-justified-between">
                        <b-input v-model="carteirinha"
                                name="carteirinha" 
                                icon="magnify"></b-input>
                    </div>
                    <div class="panel-block is-justified-between">
                        <b-button type="is-primary"
                                    @click="buscarCarteirinha(integrador)"
                                    :loading="salvandoIntegrador">
                            Busca
                        </b-button>
                    </div>
                </div>
            </div>
        </div>                
    </section>
</template>

<style>
    .coletaDomiciliar {
        border-left: 2px solid #5b9bd1;
        margin-left: -2px;
    }
</style>

<script>
    import { mapState, mapGetters } from 'vuex'
    import debounce from 'lodash/debounce'
    import periodoHora from '@/components/periodoHora.vue'
    import modalFilaAtendimento from '@/components/atendimento/modalFilaAtendimento.vue'

    export default {
        data() {
            return {
                guiaProcurar: "",
                procurar: null,
                guiaProcurarCampo: localStorage.guiaProcurarCampo ?? "Código",
                guiaProcurarPlaceholder: "Procurar pelo código da guia",
                agendaHoje: [],
                aguardandoColeta: [],
                ultimasGuias: [],

                agendaCarregada: false,
                coletasCarregadas: false,

                listaPacienteAutocomplete: [],
                paciente: null,
                selected: null,
                isFetching: false,
                integradorData: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ],
                carteirinha: null,
                salvandoIntegrador: false
            }
        },
        components: {
            periodoHora
        },
        computed: {
            ...mapState([
                'unidadeId',
                'config',
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            buscarCarteirinha(integrador){
                this.salvandoIntegrador = true;
                
                const BuscaCarteirinhaIntegrador = [
                    `carteirinha=${this.carteirinha}`,
                    `integradorId=${integrador.id}`,
                    `dataInicial=${this.$moment(this.integradorData[0]).format('YYYY-MM-DDTHH:mm:ss') }`,
                    `dataFinal=${this.$moment(this.integradorData[1]).format('YYYY-MM-DDTHH:mm:ss')}`
                ]

                this.$http.get(`/integracao/BuscaCarteirinhaIntegrador?${BuscaCarteirinhaIntegrador.join('&')}`, )
                    .then(() => {
                        this.salvandoIntegrador = false;
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Carteirinha solicitada.',
                            type: 'is-success',
                            queue: false
                        })
                    })
                    .catch(err => {
                        this.salvandoIntegrador = false;
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao enviar carteirinha: ' + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
            },
            guiaProcurarPor(campo) {
                this.guiaProcurarCampo = campo
                if (campo == "Código") {
                    this.guiaProcurarPlaceholder = "Procurar pelo código da guia"
                } else if (campo == "Referência") {
                    this.guiaProcurarPlaceholder = "Procurar pela referência da guia"
                } else if (campo == "Amostra") {
                    this.guiaProcurarPlaceholder = "Procurar pelo código da amostra"
                }

                localStorage.guiaProcurarCampo = campo
            },
            abrirGuia() {
                //verifica se existe a guia
                if(this.guiaProcurarCampo == "Código"){
                    this.$http.get('/api/atendimento/edit?id=' + this.guiaProcurar)
                    .then(() => {
                        this.$router.push({
                            name: 'guia', params: {
                                id: this.guiaProcurar
                            }
                        })
                    })
                    .catch(() => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: 'Guia ' + this.guiaProcurar + ' não encontrada',
                            type: 'is-danger',
                            position: 'is-top-left',
                            queue: false
                        })
                    })
                }
                else if (this.guiaProcurarCampo == "Referência")
                {
                    this.$http.get('/api/atendimento/AbrirGuiaReferencia?referencia=' + this.guiaProcurar)
                    .then(res => {
                        if(res.data != null && res.data.length == 1){
                            this.$router.push({
                                name: 'referencia', params: {
                                    referencia: this.guiaProcurar
                                }
                            })
                        } else if (res.data != null && res.data.length > 1){
                            this.$router.push({
                                name: 'guia-list', params: {
                                    referencia: this.guiaProcurar
                                }
                            })
                        }
                    })
                    .catch(() => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: 'Guia com referência ' + this.guiaProcurar + ' não encontrada',
                            type: 'is-danger',
                            position: 'is-top-left',
                            queue: false
                        })
                    })
                }                
                else
                {
                    this.$http.get('/api/atendimento/AbrirGuia?codigoDeBarras=' + this.guiaProcurar)
                    .then(res => {
                        if(res.data != null && res.data.length == 1){
                            this.$router.push({
                                name: 'amostra', params: {
                                    amostra: this.guiaProcurar
                                }
                            })
                        } else if (res.data != null && res.data.length > 1){
                            this.$router.push({
                                name: 'guia-list', params: {
                                    amostra: this.guiaProcurar
                                }
                            })
                        }
                    })
                    .catch(() => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: 'Amostra ' + this.guiaProcurar + ' não encontrada',
                            type: 'is-danger',
                            position: 'is-top-left',
                            queue: false
                        })
                    })
                }
                
            },
            procurarPaciente() {
                if(this.selected && this.selected.id){
                    this.$router.push({ path: `/manutencao/paciente/${this.selected.id}/true` })
                }
                else {
                    const params = { procurar: this.paciente, criarGuia: true };
                    this.$router.push({ name: 'pacientes', params })
                }
            },
            procurarPacienteAutocomplete: debounce(function (name) {
                if (!name.length) {
                    this.listaPacienteAutocomplete = []
                    return
                }
                this.isFetching = true

                const params = [
                    `procurar=${name}`,
                    `page=1`                                      
                ].join('&')

                this.$http.get(`/api/manutencao/pacientes?${params}`)
                    .then(({ data }) => {
                        this.listaPacienteAutocomplete = data.lista
                    })
                    .catch((error) => {
                        this.listaPacienteAutocomplete = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),
            carregarAgenda() {
                this.agendaCarregada = true;
                this.$http.get('api/Dashboard/AgendaHoje?unidadeId=' + this.unidadeId)
                    .then(res => {
                        this.agendaHoje = res.data
                    });
            },
            carregarColetas() {
                this.coletasCarregadas = true;
                this.$http.get('api/Dashboard/AguardandoColeta?unidadeId=' + this.unidadeId)
                    .then(res => {
                        this.aguardandoColeta = res.data
                    });
            },
            carregarUltimasGuias() {
                this.$http.get('api/Dashboard/UltimasGuias?unidadeId=' + this.unidadeId)
                    .then(res => {
                        this.ultimasGuias = res.data
                    });
            },
            abrirSenhasAtendimento() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalFilaAtendimento,
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            criarGuiaAgendamento(item){
                if(item.guiaId){
                    this.$router.push({ name: 'guia-list', query: { pacienteId: item.pacienteId } })
                }
                else {
                    this.$router.push({
                        name: 'novaguia', params: {
                            agendamento: item.id,
                            pacienteId: item.pacienteId
                        }
                    });
                }
            }
        },
        mounted() {
            if (!this.config.naoCarregarAutomaticoPendencias) {
                this.carregarAgenda();
                this.carregarColetas();
                this.carregarUltimasGuias();
            }
            this.$refs.guiaProcurar.focus();
        }
    }
</script>