<template>
    <section>
        <div class='box' v-if="model != null && model.guia != null">
            <div class="level">
                <div class="level-item">
                    <router-link :to="{ name: 'portalconvenioguia', params: { id: model.guia.id }}" class="button is-primary">
                        {{ model.guia.id }}
                    </router-link>
                </div>
                <div class="level-item">
                    <small>Paciente:</small>
                    <b>{{ model.guia.pacienteNome }}</b>
                </div>
                <div class="level-item">
                    <small>Data de retorno:</small>
                    <b>{{ $moment(model.guia.dataDeRetorno).format("DD/MM/YYYY HH:mm") }}</b>
                </div>
            </div>
        </div>

        <div class="box" v-if="model != null && model.guia != null">
            <div class="columns">
                <div class="column">
                    <div class="panel">
                        <div class="panel-heading background-2">
                            <h3 class="panel-title">Etiqueta</h3>
                        </div>
                        <div class="panel-block">
                            <b-button type="is-default" title="Imprimir etiqueta" @click="imprimirEtiquetaAmostraGuia()" :loading="loadingImpressaoEtiqueta" expanded>
                                Imprimir etiqueta
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="panel">
                        <div class="panel-heading background-2">
                            <h3 class="panel-title">Comprovante</h3>
                        </div>
                        <div class="panel-block">
                            <b-button type="is-default"  title="Imprimir comprovante"
                                      expanded
                                      :loading="loadingImpressaoComprovante"
                                      @click="imprimirComprovanteGuia(model.guia.id)">
                                Imprimir comprovante
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
                model: null,
                id: this.$route.params.id,
                salvandoDados: false,
                loadingImpressaoEtiqueta: false,
                loadingImpressaoComprovante: false
            }
        },
        computed: {
            ...mapState([
                'config',
            ])
        },
        mounted() {
            this.salvandoDados = true
            this.$http.get(`/api/portal/Workflow?id=${this.$route.params.id}`)
                .then(({ data }) => {
                    this.model = data;
                })
                .catch((error) => {
                    this.model = null
                    throw error
                })
                .finally(() => {
                    this.salvandoDados = false
                })
        },
        methods: {
            imprimirEtiquetaAmostraGuia() {
                this.loadingImpressaoEtiqueta = true
                this.$notificacaoHub.invoke("EtiquetaAmostraGuia",
                    [this.model.guia.id],
                    null)
                    .then(() => {
                        this.loadingImpressaoEtiqueta = false
                    })
                    .catch(e => {
                        this.loadingImpressaoEtiqueta = false
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: 'Erro na impressão de etiquetas: ' + e.toString(),
                            type: 'is-danger',
                            position: 'is-top-right',
                            queue: false
                        })
                    })
            },
            imprimirComprovanteGuia(id) {
                this.loadingImpressaoComprovante = true
                this.$notificacaoHub.invoke("ComprovanteGuia",
                    this.model.guia.id,
                    'Externo', [])
                    .then(() => {
                        this.loadingImpressaoComprovante = false
                    })
                    .catch(e => {
                        this.loadingImpressaoComprovante = false
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: 'Erro na impressão de comprovante: ' + e.toString(),
                            type: 'is-danger',
                            position: 'is-top-right',
                            queue: false
                        })
                    })
            }
        }
    }
</script>