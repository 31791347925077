<template>
    <section>  
        <div class="columns box">
            <div class="column is-10">
                <b-field>
                    <b-input placeholder="Procurar por nome"
                             v-model="procurar"
                             icon="magnify"
                             @input="loadAsyncData"></b-input>
                </b-field>
            </div>
            <div class="column is-2">
                <div class="is-flex is-justified-between">
                    <router-link :to="{ name: 'portalconveniopaciente', params: { nome: procurar,id:0 }}" class="button is-primary"   >
                       <i class="mdi mdi-plus"></i> Incluir
                    </router-link>
                </div>
            </div>
        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="onPageChange"
                 aria-next-label="Próxima página"
                 aria-previous-label="Página anterior"
                 aria-page-label="Página"
                 aria-current-label="Página atual"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"
                 @sort="onSort">

            <template slot-scope="props" @click="abrirCadastro(props.row.id)">
                <b-table-column field="id" label="Id" sortable>
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column field="Nome" label="Nome" sortable>
                    {{ props.row.nome }}
                </b-table-column>

                <b-table-column field="Sexo" label="Sexo" sortable>
                    {{ props.row.sexo }}
                </b-table-column>

                <b-table-column field="DataDeNascimento" label="Nascimento" sortable centered>
                    {{ props.row.dataDeNascimentoIdade }}
                </b-table-column>

                <b-table-column field="Inscricao" label="Inscrição" sortable>
                    {{ props.row.inscricao }}
                </b-table-column>

                <b-table-column field="CPF" label="CPF">
                    {{ props.row.cpf }}
                </b-table-column>

                <b-table-column custom-key="actions" numeric>
                    <router-link :to="{ name: 'portalconveniopaciente', params: { id: props.row.id, criarGuia: criarGuia }}" class="button is-small is-primary" title="Editar">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<script>
    
    import debounce from 'lodash/debounce'

    export default {
        data() {
            return {
                model: [],
                total: 0,
                loading: false,
                procurar: this.$route.query.procurar ?? this.$route.params.procurar ?? '',
                ordenarPor: 'Nome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                perPage: 20,
                selecionado: null,
                id: null,
                criarGuia: Boolean(this.$route.params.criarGuia) ?? false
            }
        },
        methods: {
            loadAsyncData: debounce(function () {
                const params = [
                    `procurar=${this.procurar}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`                     
                ].join('&')

                this.loading = true
                this.$http.get(`/api/portal/ListarPacientes?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.loadAsyncData()
                        }
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                        if(this.procurar != ""){
                            this.$router.replace({query: {procurar: this.procurar, page: this.page }})
                        }
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            }, 500),
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
            abrirCadastro() {
                this.$router.push({
                    name: 'portalconveniopaciente', params: {
                        id: this.selecionado.id
                    }
                })
            },
            abrirCadastroInput() {
                this.$router.push({
                    name: 'portalconveniopaciente', params: {
                        id: this.id
                    }
                })
            },
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>