<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <div class="columns is-multiline">
                <div class="column is-3">
                    <b-field label="Número">
                        <b-input type="text" v-model="numeroBancada"></b-input>
                    </b-field>
                </div>
                <div class="column is-3">
                    <br>
                    <span class="tag is-warning is-large" v-if="numeroBancadaAlterado">Reimpressão</span>
                </div>
            </div>
            <div class="columns is-multiline">
                <div class="column is-6">
                    <searchIdName :id.sync="unidade.id" :nome.sync="unidade.nome" :item.sync="unidade" table="Unidade" label="Unidade"/>
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="local.id" :nome.sync="local.nome" :item.sync="local" table="Local" label="Local de Origem"/>
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="setor.id" :nome.sync="setor.nome" :item.sync="setor" table="Setor" label="Setor"/>
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="convenio.id" :nome.sync="convenio.nome" :item.sync="convenio" table="Convenio" label="Convênio"/>
                </div>
            </div>
            <div class="columns is-multiline">
                <div class="column is-6">                    
                    <label class="label">Exame</label>
                    <b-autocomplete field="nome"
                            ref="autocomplete"
                            v-model="exame"
                            placeholder="Procurar exame"
                            :data="listaExameAutocomplete"
                            :loading="isFetching"
                            @typing="procurarExameAutocomplete"
                            @select="selectItem">
                        <template slot-scope="props">
                            <div class="media">
                                <div class="media-left">
                                    <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                </div>
                                <div class="media-content">
                                    {{ props.option.nome }}
                                </div>
                            </div>
                        </template>
                    </b-autocomplete>
                    <div class="columns" v-if="exames.length > 0">
                        <div class="column">
                            <b-table :data="exames" striped hoverable>
                                <template slot-scope="props">                                            

                                    <b-table-column field="exameId" label="Código">
                                        {{ props.row.exameId }}
                                    </b-table-column>

                                    <b-table-column field="exameApelido" label="Apelido">
                                        {{ props.row.exameApelido }}
                                    </b-table-column>

                                    <b-table-column field="" label="Remover?">
                                        <b-button
                                            icon-left="close"
                                            type="is-danger"
                                            @click.prevent.stop="removerExame(props.row)"
                                        ></b-button>
                                    </b-table-column>

                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
                <div class="column is-6">
                    <label class="label">Enviar para Integrador</label>
                    <b-select placeholder="Integrador" v-model="integradorId">
                        <option value=""></option>
                        <option v-for="option in integradores"
                                :value="option.id"
                                :key="option.id">
                            {{ option.nome }}
                        </option>
                    </b-select>                    
                </div>
            </div>
            <hr> 
            <div class="columns is-multiline">
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-if="numeroBancadaAlterado" disabled v-model="gravaStatus">Grava o status do exame como enviado</b-checkbox>
                        <b-checkbox v-else v-model="gravaStatus">Grava o status do exame como enviado</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="imprimeRascunho">Imprime um rascunho com os campos de resultado</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="somenteUrgentes">Somente exames urgentes</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="somenteSemDigitacao">Somente exames sem resultado digitado</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeConvenio">Exibe o nome do convênio</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeMedico">Exibe o nome do médico</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeMaterial">Exibe material</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeConservante">Exibe conservante</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeLocal">Exibe o nome do local de origem</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeDataRetorno">Exibe a data de retorno</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeDataNascimento">Exibe a data de nascimento do Paciente</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeInscricao">Exibe a inscrição do Paciente</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeLeito">Exibe o leito</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeUltimosResultados">Exibe os últimos resultados</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="emitepapelRazao">Emite em papel razão (A5)</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="incluirEspacoAssinatura">Incluir espaço para assinaturas dos responsáveis</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeCodigoBarras">Exibir código de barras das amostras</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeObservacaoPaciente">Exibir observação do paciente</b-checkbox>
                    </b-field>
                </div>
            </div>  
            <hr> 
            <div class="columns is-multiline">
                <div class="column tile is-12">

                    <div class="column is-3">
                        <label class="label">Separar por</label>
                        <b-field>
                            <b-checkbox v-model="separaSetores">Setores por página</b-checkbox>
                        </b-field>
                    </div>
                    <div class="column is-3">
                        <label class="label" style="visibility: hidden;">Separar por</label>
                        <b-field>
                            <b-checkbox v-model="separaLocais">Locais por página</b-checkbox>
                        </b-field>
                    </div>
                    <div class="column is-3">
                        <label class="label" style="visibility: hidden;">Separar por</label>
                        <b-field>
                            <b-checkbox v-model="separaExames">Exames por página</b-checkbox>
                        </b-field>
                    </div>
                    <div class="column is-3">
                        <label class="label" style="visibility: hidden;">Separar por</label>
                        <b-field>
                            <b-checkbox v-model="separaPacientes">Pacientes por página</b-checkbox>
                        </b-field>
                    </div>
                </div>
            </div>  
            <div class="columns is-multiline">
                <div class="column tile is-12">
                    <div class="column is-3">
                        <label class="label">Ordenar por</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="codigo">
                            Código da guia
                        </b-radio>
                    </div>
                    <div class="column is-3">
                        <label class="label" style="visibility: hidden;">Ordenar por</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="nomePaciente">
                            Nome do paciente
                        </b-radio>
                    </div>
                    <div class="column is-3">
                        <label class="label" style="visibility: hidden;">Ordenar por</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="dataSolicitacao">
                            Data de solicitação
                        </b-radio>
                    </div>
                </div>
            </div>            
        </div>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                                native-type="button"
                                :loading="loading"
                                @click="enviar()"
                                icon-left="check-circle">
                        Enviar
                    </b-button>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                        Voltar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>


<script>
    import { mapState, mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import debounce from 'lodash/debounce'

    export default {
        data() {
            return {
                local: {
                    id: null,
                    nome: null
                },
                unidade: {
                    id: null,
                    nome: null
                },
                setor: {
                    id: null,
                    nome: null
                },
                convenio: {
                    id: null,
                    nome: null
                },
                exames: [],
                exame: null,
                numeroBancada: null,
                numeroBancadaInicial: null,
                ordena: 'codigo',
                gravaStatus: true,
                imprimeRascunho: false,
                somenteUrgentes: false,
                somenteSemDigitacao: false,
                exibeConvenio: false,
                exibeMedico: false,
                exibeMaterial: false,
                exibeConservante: false,
                exibeLocal: false,
                exibeDataRetorno: false,
                exibeDataNascimento: false,
                exibeInscricao: false,
                exibeLeito: false,
                exibeUltimosResultados: false,
                emitepapelRazao: false,
                incluirEspacoAssinatura: false,
                exibeCodigoBarras: false,
                exibeObservacaoPaciente: false,
                loading: false,
                integradorId: null,
                isFetching: false,
                listaExameAutocomplete: [],
                selected: null,
                numeroBancadaAlterado: false,
                separaSetores: true,
                separaLocais: false,
                separaExames: false,
                separaPacientes: false
            };
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'unidadeId',
                'integradores',
                'config'
            ]),
            titleStack() {
                return [
                    'Análise',
                    'Emissão de Bancada'
                ]
            },                 
        },
        components: {
            titlebar,
            searchIdName
        },      
        mounted() {     
            this.unidade.id = this.unidadeId;

            this.carregarOpcoesBancada();

            this.$http.get('/api/analitico/BancadaNumero')
                .then(res => {
                    this.numeroBancada = res.data
                    this.numeroBancadaInicial = res.data
                })
        },
        watch: {
            separaSetores: function(val){
                if(val){
                    this.separaLocais = false;
                    this.separaExames = false;
                }
            },
            separaExames: function(val){
                if(val){
                    this.separaSetores = false;
                    this.separaLocais = false;
                    this.separaPacientes = false;
                }
                else {
                    if(!this.separaPacientes){
                        this.separaSetores = true;
                    }
                }
            },
            separaPacientes: function(val){
                if(val){
                    this.separaExames = false;
                    this.separaLocais = false;
                    this.ordena = 'nomePaciente'
                }
            },
            numeroBancada: function(val){
                if(this.numeroBancadaInicial < val){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Número de bancada inválido !',
                        type: 'is-danger'
                    })
                    this.numeroBancada = this.numeroBancadaInicial;
                }

                if(this.numeroBancadaInicial > val){
                    this.gravaStatus = false;
                    this.numeroBancadaAlterado = true;
                }
                else {                  
                    this.gravaStatus = true;  
                    this.numeroBancadaAlterado = false;
                }
            }
        },
        methods:{
            enviar(){
                this.salvarOpcoesSelecionadas();

                const bancadaModel = {
                    'SeparaSetoresPorPagina' : this.separaSetores,
                    'SeparaLocaisPorPagina': this.separaLocais,
                    'SeparaExamesPorPagina': this.separaExames,
                    'SeparaPacientesPorPagina': this.separaPacientes,

                    'OrdenaPeloCodigoDaGuia': this.ordena == 'codigo',
                    'OrdenaPeloNomeDoPaciente': this.ordena == 'nomePaciente',
                    'OrdenaPelaDataDeSolicitacao': this.ordena == 'dataSolicitacao',

                    'GravaStatusComoEnviado': this.gravaStatus,
                    'SomenteExamesUrgentes': this.somenteUrgentes,
                    'ExibeRascunho': this.imprimeRascunho,
                    'SomenteExamesSemResultado': this.somenteSemDigitacao,
                    'ExibeConvenio': this.exibeConvenio,
                    'ExibeNomeDoMedico': this.exibeMedico,
                    'ExibeMaterial': this.exibeMaterial,
                    'ExibeConservante': this.exibeConservante,
                    'ExibeLocal': this.exibeLocal,
                    'ExibeDataDeRetorno': this.exibeDataRetorno,
                    'ExibeNascimento': this.exibeDataNascimento,
                    'ExibeInscricao': this.exibeInscricao,
                    'ExibeLeito': this.exibeLeito,
                    'ExibeUltimosResultados': this.exibeUltimosResultados,
                    'EmiteEmPapelRazao': this.emitepapelRazao,
                    'IncluiEspacoParaAssinaturas': this.incluirEspacoAssinatura,
                    'ExibeCodigoDeBarrasDaAmostra': this.exibeCodigoBarras,
                    'ExibeObservacaoPaciente': this.exibeObservacaoPaciente,
                    
                    'UnidadeId': this.unidade.id,
                    'UnidadeNome': this.unidade.nome,
                    'LocalDeOrigemId': this.local.id,
                    'LocalDeOrigemNome': this.local.nome,
                    'SetorId': this.setor.id,
                    'SetorNome': this.setor.nome,
                    'ConvenioId': this.convenio.id,
                    'ConvenioNome': this.convenio.nome,
                    'Numero': this.numeroBancada,
                    'NumeroNovaBancada': this.numeroBancadaInicial,
                    'exameId': this.exames.map(x => x.exameId)
                };

                console.log(bancadaModel);

                this.loading = true;
                this.$http.post('/api/analitico/BancadaRel', bancadaModel, { responseType: 'arraybuffer' })
                    .then(res => res.data)
                    .then(data => {
                        const file = new Blob([data], { type: 'application/pdf' });
                        const fileURL = window.URL.createObjectURL(file);
                        window.open(fileURL);
                    })
                    .catch((error) => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Não existem registros para os filtros selecionados.',
                            type: 'is-danger'
                        })
                    })
                    .finally(() => {
                        this.loading = false;
                    });

            },
            salvarOpcoesSelecionadas() {
                const pre = "bancada";

                localStorage.setItem(pre + "SeparaSetoresPorPagina", this.separaSetores);
                localStorage.setItem(pre + "SeparaLocaisPorPagina", this.separaLocais);
                localStorage.setItem(pre + "SeparaExamesPorPagina", this.separaExames);
                localStorage.setItem(pre + "SeparaPacientesPorPagina", this.separaPacientes);

                localStorage.setItem(pre + "OrdenaPeloCodigoDaGuia", this.ordena == 'codigo');
                localStorage.setItem(pre + "OrdenaPeloNomeDoPaciente", this.ordena == 'nomePaciente');
                localStorage.setItem(pre + "OrdenaPelaDataDeSolicitacao", this.ordena == 'dataSolicitacao');

                localStorage.setItem(pre + "SomenteExamesUrgentes", this.somenteUrgentes);
                localStorage.setItem(pre + "ExibeRascunho", this.imprimeRascunho);
                localStorage.setItem(pre + "SomenteExamesSemResultado", this.somenteSemDigitacao);
                localStorage.setItem(pre + "ExibeConvenio", this.exibeConvenio);
                localStorage.setItem(pre + "ExibeNomeDoMedico", this.exibeMedico);
                localStorage.setItem(pre + "ExibeMaterial", this.exibeMaterial);
                localStorage.setItem(pre + "ExibeConservante", this.exibeConservante);
                localStorage.setItem(pre + "ExibeLocal", this.exibeLocal);
                localStorage.setItem(pre + "ExibeDataDeRetorno", this.exibeDataRetorno);
                localStorage.setItem(pre + "ExibeNascimento", this.exibeDataNascimento);
                localStorage.setItem(pre + "ExibeInscricao", this.exibeInscricao);
                localStorage.setItem(pre + "ExibeLeito", this.exibeLeito);
                localStorage.setItem(pre + "ExibeUltimosResultados", this.exibeUltimosResultados);
                localStorage.setItem(pre + "EmiteEmPapelRazao", this.emitepapelRazao);
                localStorage.setItem(pre + "IncluiEspacoParaAssinaturas", this.incluirEspacoAssinatura);
                localStorage.setItem(pre + "ExibeCodigoDeBarrasDaAmostra", this.exibeCodigoBarras);
                localStorage.setItem(pre + "ExibeObservacaoPaciente", this.exibeObservacaoPaciente);
            },
            carregarOpcoesBancada() {
                this.opcoesBancada = '';

                this.carregarOpcao('SeparaSetoresPorPagina');
                this.carregarOpcao('SeparaLocaisPorPagina');
                this.carregarOpcao('SeparaExamesPorPagina');
                this.carregarOpcao('SeparaPacientesPorPagina');
                this.carregarOpcao('SomenteExamesUrgentes');
                this.carregarOpcao('SomenteExamesSemResultado');
                this.carregarOpcao('ExibeConvenio');
                this.carregarOpcao('ExibeNomeDoMedico');
                this.carregarOpcao('ExibeMaterial');
                this.carregarOpcao('ExibeConservante');
                this.carregarOpcao('ExibeLocal');
                this.carregarOpcao('ExibeDataDeRetorno');
                this.carregarOpcao('ExibeLeito');
                this.carregarOpcao('ExibeRascunho');
                this.carregarOpcao('ExibeUltimosResultados');
                this.carregarOpcao('ExibeNascimento');
                this.carregarOpcao('ExibeInscricao');
                this.carregarOpcao('OrdenaPeloCodigoDaGuia');
                this.carregarOpcao('OrdenaPeloNomeDoPaciente');
                this.carregarOpcao('OrdenaPelaDataDeSolicitacao');
                this.carregarOpcao('EmiteEmPapelRazao');
                this.carregarOpcao('IncluiEspacoParaAssinaturas');
                this.carregarOpcao('ExibeCodigoDeBarrasDaAmostra');
                this.carregarOpcao('ExibeObservacaoPaciente');
            },
            carregarOpcao(nome) {
                const pre = "bancada";

                if (localStorage.getItem(pre + nome) && localStorage.getItem(pre + nome) == 'true') {
                    this.opcoesBancada += this.opcoesBancada + nome + ",";
                }
            },
            procurarExameAutocomplete: debounce(function (name) {
                if (!name.length) {
                    this.listaExameAutocomplete = []
                    return
                }
                this.isFetching = true

                const params = [
                    `procurar=${name}`,
                    `page=1`                                      
                ].join('&')

                this.$http.get(`/api/manutencao/exames?${params}`)
                    .then(({ data }) => {
                        this.listaExameAutocomplete = data.lista
                    })
                    .catch((error) => {
                        this.listaExameAutocomplete = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),
            selectItem(option) {
                if(option != null){
                    this.exames.push({
                        exameId: option.id,
                        exameNome: option.nome,
                        exameApelido: option.apelido
                    });
                }
                
                setTimeout(() => this.exame = '', 10);
                
                this.$refs.autocomplete.focus()
            },
            removerExame(exame){
                this.exames = this.exames?.filter((i) => i.exameId != exame.exameId);
            }
        }
    }
</script>