<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Novo Agendamento</p>
        </header>
        <b-message v-for="(erro, index) in erros"
                   v-bind:key="index"
                   title="Erro"
                   type="is-danger"
                   aria-close-label="Fechar mensagem">
            {{ erro }}
        </b-message>
        <section class="modal-card-body">
            <div class="columns">
                <div class="column">
                    <ValidationProvider rules="required"
                                        name="valor"
                                        v-slot="{ errors, valid }">
                        <b-field label="Data e hora" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                            <b-datetimepicker ref="data"
                                              rounded
                                              placeholder="Selecione o horário"
                                              v-model="item.data"
                                              icon="calendar-today"
                                              :timepicker="{ enableSeconds: false, hourFormat: '24' }"
                                              horizontal-time-picker>
                            </b-datetimepicker>
                        </b-field>
                    </ValidationProvider>
                </div>
            </div>
            <div class="columns">
                <searchIdName class="column" table="paciente" label="Paciente" :id.sync="idPaciente" @item="atualizaPaciente">
                    <slot>
                        <b-button icon-left="plus-box"
                                  tag="router-link"
                                  :to="{ name: 'paciente', query: { retorno: true }}"
                                  target="_blank"
                                  title="Incluir um novo paciente"></b-button>
                    </slot>
                </searchIdName>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Observação">
                        <b-input type="textarea"
                                 v-model="item.observacao"
                                 @input="$emit('update:observacao', $event)">
                        </b-input>
                    </b-field>
                </div>
            </div>
        </section>

        <footer class="modal-card-foot">
            <button class="button is-pulled-right is-success"
                    @click="confirmar">
                Confirmar
            </button>

            <button class="button" type="button" @click="$parent.close()">Fechar</button>

        </footer>

    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'   
    import moment from "moment";
    import searchIdName from '@/components/searchIdName.vue'

    export default {
        props: {
            data: null,
            idPaciente: null,
            observacao: null,
            id: null
        },
        components: {
            searchIdName
        },
        computed: {
            ...mapState([
                'config'
            ])
        },
        data() {
            return {
                item: {
                    observacao: null,
                    paciente: null,
                    data: null
                },
                erros: [],
            };
        },
        watch: {
            data(newValue) {
                if (newValue) {
                    this.item.data = newValue;
                }
            },
            observacao(newValue) {
                if (newValue) {
                    this.item.observacao = newValue;
                }
            }
        },
        filters: {
            data: (valor) => {
                if (!valor) return null;

                return moment(valor).format();
            },
        },
        mounted() {
            this.item.data = this.data;
            this.item.observacao = this.observacao;
            if (!this.id) {
                this.$refs.data.toggle();
            }

        },
        methods: {
            atualizaPaciente(dados) {
                this.item.paciente = dados;
                this.$emit('update:paciente', dados);
            },
            confirmar() {
                this.erros = [];
                if (!this.item.paciente?.id && !this.item.paciente?.data?.id) {
                    this.erros.push("O Paciente precisa ser informado");
                }


                if (!this.id && moment(this.item.data).isSameOrBefore(moment())) {
                    this.erros.push("A data/hora não pode ser inferior a data/hora atual");
                }

                if (this.erros.length) return;

                const item = {
                    paciente: this.item.paciente,
                    observacao: this.item.observacao,
                    data: this.item.data
                };

                if(!item.paciente.id){
                    item.paciente = this.item.paciente.data;
                }

                if (this.id) {
                    item.id = this.id;
                }

                //verifica verifica se tem a config e se ela ta sendo respeitada
                if(this.config.agendamentoMinutosAlerta != null && this.config.agendamentoMinutosAlerta != 0){
                    this.$http.get('/api/atendimento/GuiaAgendaVerificaMesmoHorario?datahora=' + moment(item.data).format('YYYY-MM-DDTHH:mm:ss'))
                        .then(res => {
                            if(!res.data){
                                this.$emit('novo', item)
                                this.$parent.close()
                            }
                            else {
                                this.confirmarInclusaoMesmoDiaHora(item)
                            }
                        })
                }
                else {
                    this.$emit('novo', item)
                    this.$parent.close()
                }
            },
            confirmarInclusaoMesmoDiaHora(item){
                this.$buefy.dialog.confirm({
                    title: 'Horário preenchido',
                    message: 'Confirma a inclusão do agendamento no mesmo horario de outro paciente?',
                    confirmText: 'Confirmar',
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: "Cancelar",                                               
                    onConfirm: () => {
                        this.$emit('novo', item)
                        this.$parent.close()
                    }
                });
            }
        },
    };
</script>
