<template>
    <div>                   
        <div class="box is-hero-bar is-main-hero column is-12" v-if="valoresCriticosReportados != null && valoresCriticosReportados.length > 0">
            <div class="panel">
                <div v-for="(valorCritico, index) in valoresCriticosReportados" :key="index">
                    <div class="panel-block columns is-multiline">                        
                        <div class="column is-1">
                            <span>
                                <small>Pedido:</small>
                                <br>
                                <router-link :to="{ name: 'portalconvenioguia', params: { id: valorCritico.guiaId }}">
                                    <b>{{valorCritico.guiaId}}</b>
                                </router-link>
                            </span>
                        </div>               
                        <div class="column is-5">
                            <small>Exames:</small>  
                            <br>                          
                            <b-tag v-for="(exameApelido, j) in valorCritico.examesApelidos" v-bind:key="j"
                                :type="'default'">
                                {{ exameApelido }}
                            </b-tag>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapActions, mapState } from 'vuex'

    export default {

        data() {
            return {
                valoresCriticosReportados:[]
            };
        },               
        computed: {
            ...mapState([
                'config',
                'usuario'
            ])            
        },
        methods:{
            carregarValorCriticoReportado(){
                if(this.valorCriticoReportadoCount != 0){
                    this.$http.get('/api/portal/ValorCriticoReportado')
                        .then(res => {
                            this.valoresCriticosReportados = res.data;
                        });
                }
            },        
        },

        created(){
            this.carregarValorCriticoReportado();
        },         
    }

</script>