<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">Bloquear exame</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">Atenção !</h5>
                <p>Este procedimento irá bloquear os exames selecionados.</p>
            </b-notification>
            <b-notification v-if="erroBloqueioExame"
                            type="is-danger">
                {{ erroBloqueioExame }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field label="Exames">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-tag type="is-info" v-for="(exame,index) in selecionado" :key="index">{{ exame.exameApelido }}</b-tag>
                </p>
                <p class="panel-block">
                    <b-field label="Motivos">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-input v-model="procurarMotivosBloqueioExame"
                             icon="magnify"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="procurarMotivosBloqueioExame = ''"></b-input>
                </div>
                <div class="panel-block">
                    <b-select multiple
                              native-size="10"
                              expanded
                              v-model="motivoBloqueioExameSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosBloqueioExame" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                      @click="confirmarBloqueioExame">Confirmar bloqueio</b-button>
            <b-button @click="$emit('close')">Cancelar</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            guia: Object,
            selecionado: Array
        },
        data() {
            return {
                procurarMotivosBloqueioExame: '',
                listaMotivosBloqueioExame: [],
                motivoBloqueioExameSelecionado: [],
                erroBloqueioExame: null,
            }
        },
        computed: {
            filteredMotivosBloqueioExame() {
                return this.procurarMotivosBloqueioExame
                    ? this.listaMotivosBloqueioExame
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosBloqueioExame.toUpperCase()) > -1)
                    : this.listaMotivosBloqueioExame
            },
        },
        mounted() {
            this.loadListaMotivosBloqueioExame()
        },
        methods: {
            loadListaMotivosBloqueioExame() {
                this.$http.get('/api/search/MotivosBloqueioExame')
                    .then(m => {
                        this.listaMotivosBloqueioExame = m.data
                    })
            },
            confirmarBloqueioExame() {
                this.$http.post('/api/atendimento/bloquearExame', {
                    id: this.guia.id,
                    item: this.selecionado.map(x => x.item),
                    motivoIds: this.motivoBloqueioExameSelecionado
                })
                    .then((res) => {
                        if (res.body.erro) {
                            this.erroBloqueioExame = res.body.erro
                        } else {
                            this.$emit('loadGuia')
                            this.$emit('close')
                        }
                    })
            },

        }
    }
</script>