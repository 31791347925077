<template>
    <section>              
        <div v-if="isLoading">
            <b-loading active></b-loading>
        </div>
        <div v-else>
            <div class="box is-hero-bar is-main-hero">
                <div class="panel">
                    <div class="columns">
                        <div class="column is-2">                                
                            <b-field label="Id">
                                <b-input type="text" :readonly="true" v-model="model.exame.id"></b-input>
                            </b-field>                      
                        </div>
                        <div class="column is-2">                                
                            <b-field label="Apelido">
                                <b-input type="text" :readonly="true" v-model="model.exame.apelido"></b-input>
                            </b-field>                           
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-12">                        
                            <b-field label="Nome">
                                <b-input type="text"  :readonly="true" v-model="model.exame.nome" maxlength="150" ></b-input>
                            </b-field>                        
                        </div>                               
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Qtde. amostras">
                                <b-input type="number"  :readonly="true" v-model="model.exame.quantidadeDeAmostras" max="99999"></b-input>
                            </b-field>
                        </div>                            
                        <div class="column">
                            <b-field label="Prazo dias">
                                <b-input type="number"  :readonly="true" v-model="model.exame.prazoDeEntrega" max="99999"></b-input>
                            </b-field>
                        </div>  
                        <div class="column">
                            <b-field label="Prazo horas">
                                <b-input type="number"  :readonly="true" v-model="model.exame.prazoDeEntregaHoras" max="99999"></b-input>
                            </b-field>
                        </div>                                                           
                        <div class="column">
                            <b-field label="Prazo minutos">
                                <b-input type="number"  :readonly="true" v-model="model.exame.prazoDeEntregaMinutos" max="99999"></b-input>
                            </b-field>
                        </div>                                                                       
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Tempo mínimo de jejum">
                                <b-input type="number"  :readonly="true" v-model="model.exame.minimoJejum" max="99999"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Volume desejado">
                                <b-input v-model="model.exame.volumeDesejado"  :readonly="true"  maxlength="150" :has-counter="false"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Volume mínimo">
                                <b-input v-model="model.exame.volumeMinimo"  :readonly="true"  maxlength="150" :has-counter="false"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-2">
                            <label class="label">Medicamento</label>
                            <b-switch v-model="model.exame.informarMedicamento" disabled>
                            </b-switch>
                        </div>
                        <div class="column is-2">
                            <label class="label">Data da última menstruação</label>
                            <b-switch v-model="model.exame.informarDUM" disabled>
                            </b-switch>
                        </div>
                        <div class="column is-2">
                            <label class="label">Peso</label>
                            <b-switch v-model="model.exame.informarPeso" disabled>
                            </b-switch>
                        </div>
                        <div class="column is-2">
                            <label class="label">Altura</label>
                            <b-switch v-model="model.exame.informarAltura" disabled>                                    
                            </b-switch>
                        </div>     
                        <div class="column is-4">
                            <br>
                            <article class="tile is-child ">
                                <b-collapse class="panel" 
                                    aria-id="contentIdForA11y3"
                                    animation="slide"
                                    :open="isOpenVisualizar"
                                    @open="isOpenVisualizar = !isOpenVisualizar">
                                    <template #trigger>
                                        <div
                                            class="panel-heading has-text-centered"
                                            role="button"
                                            aria-controls="contentId">
                                            <strong>Visualizar máscaras</strong>
                                        </div>
                                    </template>
                                    <div class="panel-block" style="display: block;">
                                        <b-table :data="model.exame.mascaras" striped hoverable>
                                            <template slot-scope="props">
                                                <b-table-column field="versao" label="Versão">
                                                    {{ props.row.versao }}
                                                </b-table-column>

                                                <b-table-column field="nome" label="Nome">
                                                    {{ props.row.nome }}
                                                </b-table-column>

                                                <b-table-column custom-key="actions" numeric>
                                                    <b-button icon-left="eye" type="is-primary" @click.prevent.stop="downloadPdf(props.row.versao)"></b-button>
                                                </b-table-column>

                                            </template>
                                        </b-table>
                                    </div>
                                </b-collapse>
                            </article>
                        </div>                          
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Sinonímia">
                                <b-input v-model="model.exame.sinonimia"  :readonly="true"  maxlength="1000" :has-counter="false"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <br>
                    <article class="tile is-child ">
                        <cadastro-generico :exclusao="false" :inclusao="false" :colunas="colunasRecipiente" label="Recipientes" :items.sync="model.exame.recipientes"></cadastro-generico>
                    </article>
                    <br>
                    <article class="tile is-child ">
                        <article class="panel" style="margin-bottom: 0px;">
                        <div class="panel-heading has-text-centered">
                            Obtenção de amostra
                        </div>                       
                        </article>
                        <div class="panel-block" style="display: block;">
                            
                                <b-field label="Informações ao laboratório">                                    
                                    <b-input class="is-fullwidth" v-model="model.exame.informacoesAoLaboratorio" type="textarea" expanded/>                            
                                </b-field>
                                <br>
                                <b-field label="Informações ao paciente">                                    
                                    <b-input class="is-fullwidth" v-model="model.exame.informacoesAoPaciente" type="textarea" expanded/>                            
                                </b-field>                                
                        </div>
                    </article>
                
                    <br>

                    <article class="tile is-child ">
                        <article class="panel"  style="margin-bottom: 0px;">
                        <div class="panel-heading has-text-centered">
                            Aplicação clínica / interpretação
                        </div>                       
                        </article>
                        <div class="panel-block">                                                         
                                <b-input class="is-fullwidth" v-model="model.exame.aplicacaoClinica" type="textarea" expanded/>                            
                        </div>                        
                    </article>

                    <br>

                    <article class="tile is-child ">
                        <article class="panel"  style="margin-bottom: 0px;">
                        <div class="panel-heading has-text-centered">
                            Critérios de rejeição
                        </div>                       
                        </article>
                        <div class="panel-block">                            
                            <b-input class="is-fullwidth" v-model="model.exame.criteriosRejeicao" type="textarea" expanded/>                            
                        </div>                        
                    </article>    

                    <br>

                    <article class="tile is-child " v-if="!model.escondePreco">
                        <article class="panel"  style="margin-bottom: 0px;">
                        <div class="panel-heading has-text-centered">
                            Tabela de preço
                        </div>                       
                        </article>
                        <div class="panel-block tile is-12">
                            <div class="columns tile is-12">
                                <div class="column is-4">     
                                    <b-field label="Valor"></b-field>                           
                                    <b-input type="text"  :readonly="true" v-model="model.valor" ></b-input>                                
                                </div>
                                <div class="column is-4">
                                    <label class="label">Bloqueado</label>
                                    <b-switch v-model="bloqueado" disabled>
                                    </b-switch>                             
                                </div>
                                <div class="column is-4">
                                    <label class="label">Negociado</label>
                                    <b-switch v-model="negociado" disabled>
                                    </b-switch>                            
                                </div>
                            </div>
                        </div>                        
                    </article>                                                           

                    <br>
                    
                    <p class="buttons" >
                        <b-button style="margin: 0 auto;" type="is-default"
                                    native-type="button"
                                    icon-left="close-circle"
                                    @click="$router.back();">
                            Voltar
                        </b-button>
                    </p>                             
                                                                                                                                                                                                
                </div>

            </div>
        </div>
    </section> 
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import cadastroGenerico from '@/components/cadastroGenerico.vue';

    export default {
        data() {
            return {
                model: null,    
                isLoading: false,
                isOpenVisualizar: false,
                colunasRecipiente: [
                    {valor: 'id', label: 'Código', readonly: true,  },
                    {valor: 'material', label: 'Material', readonly: true},
                    {valor: 'conservante', label: 'Conservante',readonly: true, },
                    {valor: 'estabilidadeAmbiente', label: 'Estabilidade ambiente (h)',readonly: true,},
                    {valor: 'estabilidadeRefrigerado', label: 'Estabilidade refrigerado (h)', readonly: true,},
                    {valor: 'estabilidadeCongelado', label: 'Estabilidade congelado (h)', readonly: true,}
                ],                
                bloqueado: null,
                negociado: null

            }
        },
        computed: {          
            ...mapState([
                'config',
                'usuario'
            ])
        },
        components: {            
            cadastroGenerico
        },
        methods: {            
            carregarExame(id){
                this.isLoading = true;

                this.$http.get('/api/portal/Exame?id=' + id)
                    .then(res => {
                                                        
                        this.model = res.data;
                        
                        this.model.exame.recipientes.forEach(x => {
                            x.material = x.material?.nome;
                            x.conservante = x.conservante?.nome;
                        })  

                        this.bloqueado = this.model.exame.precos[0].bloqueado ? "SIM" : "NÃO";
                        this.negociado = this.model.exame.precos[0].valorNegociado ? "SIM" : "NÃO"; 

                        if(this.model.valor > 0 ){
                            this.model.valor = this.model.valor?.toFixed(2).replace('.', ',');
                        }else{
                            this.model.valor = '0,0';
                        }
                        
                        
                        this.isLoading = false;                        
                    });

            },
            downloadPdf(versao){
                window.open('/api/portal/MascaraExamePdf?id='+this.model.exame.id+'&versao='+versao,'_blank');
            }            
        
        },
        created() {
            this.carregarExame(this.$route.params.id);   
                        
        }        

    }
</script>