<template>
    <section>
        <div class="columns">
            <div class="column">
                <div v-if="config.naoCarregarAutomaticoPendencias && !recebimentosAtrasadosCarregado && recebimentosAtrasados.length == 0" class="card is-warning">
                    <button class="button is-warning is-fullwidth" @click="carregarParcelasAtrasadas">Listar recebimentos atrasados</button>
                </div>
                <b-collapse v-if="recebimentosAtrasados.length > 0" 
                            class="card"
                            animation="slide"
                            :open="isRecebimentosAtrasadosOpen"
                            @open="isRecebimentosAtrasadosOpen = !isRecebimentosAtrasadosOpen">
                    <div slot="trigger"
                         slot-scope="props"
                         class="card-header has-background-warning"
                         role="button">
                        <p class="card-header-title">
                            Recebimentos atrasados
                        </p>
                        <a class="card-header-icon">
                            <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                            <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                        </a>
                    </div>
                    <div class="panel">
                        <div v-for="(item, index) in recebimentosAtrasados" v-bind:key="index">
                            <router-link v-if="isInRole('receber-alterar')" :to="{ name: 'contas-receber', params: { id : item.id }}" class="panel-block is-justified-between">
                                <div>
                                    <span class="tag is-rounded is-light">{{item.dataDeVencimento | moment('DD/MM/YYYY')}}</span>
                                    <span>{{item.nome}}</span>
                                </div>
                                <span>{{item.valor | currency}}</span>
                            </router-link>
                        </div>
                    </div>
                </b-collapse>
            </div>
            <div class="column">
                <div v-if="config.naoCarregarAutomaticoPendencias && !pagamentosProximosCarregado && pagamentosProximos.length == 0" class="card is-warning">
                    <button class="button is-warning is-fullwidth" @click="carregarParcelasFuturas">Listar parcelas futuras</button>
                </div>
                <b-collapse v-if="pagamentosProximos.length > 0"
                            class="card"
                            animation="slide"
                            :open="isPagamentosProximosOpen"
                            @open="isPagamentosProximosOpen = !isPagamentosProximosOpen">
                    <div slot="trigger"
                         slot-scope="props"
                         class="card-header has-background-warning"
                         role="button">
                        <p class="card-header-title">
                            Pagamentos próximos
                        </p>
                        <a class="card-header-icon">
                            <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                            <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                        </a>
                    </div>
                    <div class="panel">
                        <div v-for="(item, index) in pagamentosProximos" v-bind:key="index">
                            <router-link v-if="isInRole('pagar-alterar')" :to="{ name: 'contaspagar', params: { id : item.id }}" class="panel-block is-justified-between">
                                <div>
                                    <span class="tag is-rounded is-light">{{item.dataDeVencimento | moment('DD/MM/YYYY')}}</span>
                                    <span>{{item.descricao}}</span>
                                    <p>
                                        <small title="Fornecedor">{{item.fornecedor}}</small>
                                    </p>
                                </div>
                                <span>{{item.valor | currency}}</span>
                            </router-link>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('receber-alterar') || isInRole('receber-consultar') || isInRole('receber-alterar-recepcao')" class="panel">
                    <p class="panel-heading background-2">
                        Contas a receber
                    </p>
                    <form @submit.stop.prevent="$router.push({ name: 'lista-contas-receber', query: { procurar: procurar }})" method="get" class="panel-block">
                        <b-input placeholder="Procurar"
                                 v-model="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'lista-contas-receber' }" class="button is-light is-fullwidth">
                            Listar todos
                        </router-link>
                        <router-link v-if="isInRole('receber-alterar')" :to="{ name: 'contas-receber' }" class="button is-link is-fullwidth">
                            Incluir
                        </router-link>
                    </div>
                    <div class="panel-block is-justified-between">
                        <router-link v-if="isInRole('receber-faturar')" :to="{ name: 'receber-faturar' }" class="button is-info is-fullwidth">
                            Faturar
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('receber-parcela-lote-alterar') || isInRole('receber-parcela-lote-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        Lotes de contas a receber
                    </p>
                    <form action="/financeiro/lista-parcela-lote" method="get" class="panel-block">
                        <b-input placeholder="Procurar"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                         <router-link :to="{ name: 'lista-parcela-lote' }" class="button is-light is-fullwidth">
                            Listar todos
                        </router-link>
                        <router-link v-if="isInRole('receber-parcela-lote-alterar')" :to="{ name: 'receber-parcela-lote' }" class="button is-link is-fullwidth">
                            Incluir
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('pagar-alterar') || isInRole('pagar-consultar')" class="panel">
                    <p class="panel-heading background-2">
                        Contas a pagar
                    </p>
                    <form @submit.stop.prevent="$router.push({ name: 'listarpagar', query: { procurar: procurar }})" method="get" class="panel-block">
                        <b-input placeholder="Procurar"
                                    name="procurar" 
                                    icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'listarpagar' }" class="button is-light is-fullwidth">
                            Listar todos
                        </router-link>
                        <router-link v-if="isInRole('pagar-alterar')" :to="{ name: 'contaspagar' }" class="button is-link is-fullwidth">
                            Incluir
                        </router-link>
                    </div>
                    <p class="panel-heading">
                        <small>Baixa de pagamentos por período</small>
                    </p>
                    <div class="panel-block">
                        <b-datepicker placeholder="Selecione o período"
                                      v-model="baixaPagamentosDatas"
                                      position="is-bottom-right"
                                      range>
                        </b-datepicker>
                    </div>
                    <router-link :to="{ name: 'baixapagamentos', 
                        params: { 
                            dataInicial: baixaPagamentosDatas[0],
                            dataFinal: baixaPagamentosDatas[1]
                        }}" class="panel-block button is-link">
                        <button class="button is-link is-fullwidth" type="button">Baixar</button>
                    </router-link>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('pagar-parcela-lote-alterar') || isInRole('pagar-parcela-lote-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        Lotes de contas a pagar
                    </p>
                    <form action="/financeiro/lista-parcela-lote-pagar" method="get" class="panel-block">
                        <b-input placeholder="Procurar"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                         <router-link :to="{ name: 'lista-parcela-lote-pagar' }" class="button is-light is-fullwidth">
                            Listar todos
                        </router-link>
                        <router-link v-if="isInRole('pagar-parcela-lote-alterar')" :to="{ name: 'pagar-parcela-lote' }" class="button is-link is-fullwidth">
                            Incluir
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('contaCorrente-alterar') || isInRole('contaCorrente-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        Contas correntes / Cartões
                    </p>
                    <form action="/financeiro/lista-conta-corrente" method="get" class="panel-block">
                        <b-input placeholder="Procurar"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'lista-conta-corrente' }" class="button is-light is-fullwidth">
                            Listar todos
                        </router-link>
                        <router-link v-if="isInRole('contaCorrente-alterar')" :to="{ name: 'conta-corrente' }" class="button is-link is-fullwidth">
                            Incluir
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('centroDeCusto-alterar') || isInRole('centroDeCusto-consultar')" class="panel">
                    <p class="panel-heading background-2">
                        Centros de custo
                    </p>
                    <form action="/financeiro/lista-centro-de-custo" method="get" class="panel-block">
                        <b-input placeholder="Procurar"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'lista-centro-de-custo' }" class="button is-light is-fullwidth">
                            Listar todos
                        </router-link>
                        <router-link v-if="isInRole('centroDeCusto-alterar')" :to="{ name: 'centro-de-custo' }" class="button is-link is-fullwidth">
                            Incluir
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('bandeira-alterar') || isInRole('bandeira-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        Bandeiras
                    </p>
                    <form @submit.stop.prevent="$router.push({ name: 'bandeiras', query: { procurar: procurar }})" method="get" class="panel-block">
                        <b-input placeholder="Procurar"
                                    name="procurar" 
                                    icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'bandeiras' }" class="button is-light is-fullwidth">
                            Listar todos
                        </router-link>
                        <router-link v-if="isInRole('bandeira-alterar')" :to="{ name: 'bandeira' }" class="button is-link is-fullwidth">
                            Incluir
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('fluxodecaixa') || isInRole('receber-relacaoDeDebito') || isInRole('fechamentodecaixa')"  class="panel">
                    <p class="panel-heading background-2">
                        Relatórios rápidos
                    </p>
                    <div class="panel-block">
                        <periodoHora :dataInicial.sync="relatorioDatas[0]"
                                 :dataFinal.sync="relatorioDatas[1]" />
                    </div>
                    <div class="panel-block is-justified-between">
                        <b-checkbox v-if="isInRole('fluxodecaixa')"
                                    v-model="filtrarPorProcessamento"
                                    size="is-small">
                            Filtrar por data de processamento
                        </b-checkbox>
                        <b-checkbox v-if="isInRole('receber-relacaoDeDebito')" 
                                    v-model="sintetizado"
                                    size="is-small">
                            Somar valores das parcelas
                        </b-checkbox>
                    </div>
                    <div class="panel-block is-justified-between">
                        <form v-if="isInRole('fluxodecaixa')" action="/Rel/FluxoDeCaixa" method="post">
                            <input type="hidden" name="dataInicial" :value="relatorioDatas[0] | moment('DD/MM/YYYY HH:mm')" />
                            <input type="hidden" name="dataFinal" :value="relatorioDatas[1] | moment('DD/MM/YYYY HH:mm')" />
                            <input type="hidden" name="filtrarPorProcessamento" v-model="filtrarPorProcessamento" />
                            <button class="button is-dark" type="submit">
                                <span class="is-hidden-mobile">Fluxo de caixa</span>
                                <span class="is-hidden-tablet">Fluxo</span>
                            </button>
                        </form>
                        <router-link v-if="isInRole('fechamentodecaixa')" :to="{ name: 'fechamentodecaixa', 
                            params: {
                                dataInicial: this.$moment(relatorioDatas[0]).format('YYYY-MM-DDTHH:mm:ss'),
                                dataFinal: this.$moment(relatorioDatas[1]).format('YYYY-MM-DDTHH:mm:ss')                                
                            }}" class="button is-primary">
                            <button class="button is-primary" type="button">
                                <span class="is-hidden-mobile">Fechamento de caixa</span>
                                <span class="is-hidden-tablet">Fechamento</span>
                            </button>
                        </router-link>
                        <router-link v-if="isInRole('receber-relacaoDeDebito')" :to="{ name: 'relacaodedebito', 
                            params: { 
                                sintetizado: sintetizado
                            }}" class="button is-link">
                            <button class="button is-link" type="button">
                                <span class="is-hidden-mobile">Relação de débitos</span>
                                <span class="is-hidden-tablet">Débitos</span>                                
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('estatisticasFinanceiro')" class="panel">
                    <p class="panel-heading background-1">
                        Estatísticas
                    </p>
                    <div class="panel-block">
                        <periodoHora :dataInicial.sync="estatisticasDatas[0]"
                                 :dataFinal.sync="estatisticasDatas[1]" />
                    </div>
                    <div class="panel-block is-justified-between">
                        <b-checkbox v-model="somenteParticulares"
                                    size="is-small">
                            Somente particulares
                        </b-checkbox>
                    </div>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'estatisticas', 
                            params: { 
                                dataInicial: this.$moment(estatisticasDatas[0]).format('YYYY-MM-DDTHH:mm:ss'),
                                dataFinal: this.$moment(estatisticasDatas[1]).format('YYYY-MM-DDTHH:mm:ss'),
                                somenteParticulares: somenteParticulares
                            }}" class="button is-primary">
                            <button class="button is-primary" type="button">Estatísticas</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'
    import periodoHora from '@/components/periodoHora.vue'

    export default {
        data() {
            return {
                procurar: null,
                recebimentosAtrasados: [],
                pagamentosProximos: [],
                baixaPagamentosDatas: [
                    this.$moment().subtract(3, 'days').toDate(),
                    this.$moment().toDate()
                ],
                relatorioDatas: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ],
                estatisticasDatas: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ],

                isRecebimentosAtrasadosOpen: false,
                isPagamentosProximosOpen: false,
                filtrarPorProcessamento: false,
                sintetizado: false,                
                somenteParticulares: false,
                pagamentosProximosCarregado: false,
                recebimentosAtrasadosCarregado: false
            }
        },
        components: {
            periodoHora
        },
        computed: {
            ...mapState([
                'config'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            ...mapActions([
                'get'
            ]),
            carregarParcelasAtrasadas(){
                this.recebimentosAtrasadosCarregado = true;
                
                this.$http.get('api/Dashboard/receberParcelasAtrasadas')
                    .then(res => {
                        this.recebimentosAtrasados = res.data
                    });
            },
            carregarParcelasFuturas(){    
                this.pagamentosProximosCarregado = true;

                this.$http.get('api/Dashboard/pagarParcelasFuturas')
                    .then(res => {
                        this.pagamentosProximos = res.data
                    });
            }
        },
        mounted() {
            if (!this.config.naoCarregarAutomaticoPendencias) {
                this.carregarParcelasAtrasadas();
                this.carregarParcelasFuturas();
            }
        }
    }
</script>