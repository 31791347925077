<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>

        <b-message
            v-for="(erro, index) in erros"
            v-bind:key="index"
            title="Erro"
            type="is-danger"
            aria-close-label="Fechar mensagem"
            >
            {{ erro }}
        </b-message>

        <b-table v-if="amostras.length > 0" :data="amostras"
                 :loading="loading"
                 striped
                 hoverable
                :checked-rows.sync="checkedRows"
                checkable
                :checkbox-position="checkboxPosition">

            <template slot-scope="props">
                <b-table-column field="Contador" label="">
                    <span class="tag is-pulled-right is-dark">{{ (props.index + 1) }}</span>
                </b-table-column>

                <b-table-column field="Amostra" label="Amostra">
                    {{ props.row.amostraCodigoDeBarras }}
                </b-table-column>

                <b-table-column field="Paciente" label="Paciente">
                    {{ props.row.pacienteNome }}
                </b-table-column>

                <b-table-column field="Material" label="Material">
                    {{ props.row.materialNome }}
                </b-table-column>

                <b-table-column field="Conservante" label="Conservante">
                    {{ props.row.conservanteNome }}
                </b-table-column>

                <b-table-column field="Setor" label="Setor">
                    {{ props.row.setorNome }}
                    <span class="tag is-pulled-right" :style="'background-color: #' + props.row.setorCor + '; font-size: 10pt;'">&nbsp;</span>
                </b-table-column>

                <b-table-column field="Exames" label="Exames">
                    <span v-for="(item, index) in props.row.examesApelidos" v-bind:key="index" class="tag is-default">
                        {{item}}<span> </span>
                    </span>
                </b-table-column>

                <b-table-column field="Acondicionamento" label="">
                    <span v-if="props.row.amostraAcondicionamento == 3" class="tag is-pulled-right is-light" title="Acondicionamento: Congelada">C</span>
                    <span v-if="props.row.amostraAcondicionamento == 2" class="tag is-pulled-right is-link" title="Acondicionamento: Refrigerada">R</span>
                    <span v-if="props.row.amostraAcondicionamento == 1" class="tag is-pulled-right is-primary" title="Acondicionamento: Ambiente">A</span>
                </b-table-column>
            </template>
        </b-table>
        <br>
        <div class='box'>
            <div class="columns">
                <div class="column is-half">
                    <label><strong>Código de Barras</strong></label>
                    <div class="field has-addons">
                        <form @submit.prevent="incluirAmostra" method="get">
                            <div class="control">
                                <input type="text" v-model="amostraCodigoDeBarras" class="input" ref="amostraCodigoDeBarras"  id="amostraCodigoDeBarras"/>
                            </div>
                        </form>
                        <div class="control">
                            <a class="button" @click="incluirAmostra();">
                                Incluir
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <label><strong>Temperatura (C°)</strong></label>
                    <input type="number" v-model="temperatura" class="input" />
                </div>
                <div class="column">
                    <label><strong>Latitude</strong></label>
                    <input type="text" v-model="latitude" class="input" readonly/>
                </div>
                <div class="column">
                    <label><strong>Longitude</strong></label>
                    <input type="text" v-model="longitude" class="input" readonly/>
                </div>
            </div>
        </div>

        <nav class="notification is-light level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success" icon-left="check-circle" @click="enviarLote();">
                        Enviar
                    </b-button>
                    <b-button type="is-danger" icon-left="close-circle" @click="$router.back();">
                        Cancelar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>


<script>
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                amostras: [],
                amostraCodigoDeBarras: "",
                temperatura: "",
                latitude: "",
                longitude: "",
                erros: [],
                checkedRows: [],
                loading: false,
                checkboxPosition: 'left'
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    'Atendimento',
                    'Lote de Amostras',
                    'Enviar'
                ]
            }
        },
        methods:{
            incluirAmostra(){
                this.erros = [];
                this.loading = true;

                this.$http.get('/api/atendimento/AmostraLoteEnviarIncluir?codigoDeBarras=' + this.amostraCodigoDeBarras)
                .then(res => res.data)
                .then(data => {
                    if (data != null && data.length != 0) {
                        for (const i in data) {
                            this.amostras.push(data[i]);
                        }
                    }

                    this.$refs.amostraCodigoDeBarras.focus();
                    this.loading = false;
                })
                .catch((error) => {
                    this.erros.push(error);
                    this.loading = false;
                }); 

                this.amostraCodigoDeBarras = "";
            },
            enviarLote(){
                this.erros = [];
                this.loading = true;

                const amostraLoteEnviar = {
                    amostras: this.amostras, 
                    temperatura: this.temperatura, 
                    latitude: this.latitude, 
                    longitude: this.longitude
                };

                this.$http.post(`/api/atendimento/AmostraLoteEnviar`, amostraLoteEnviar )
                    .then(({ data }) => {
                        this.loading = false;
                        this.$router.push({ name: 'atendimento' })
                    })
                    .catch((error) => {
                        this.erros.push(error);
                        this.loading = false;
                    }); 

                this.amostras = [];
            }
        }
    }
</script>